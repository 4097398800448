import React, {Component} from "react";
import "../../asset/css/asset-survey-step-geo.css";
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';

export default class ComLibInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    renderStep1 = () => {
        const hub = this.props.getAllInfo();
        let ls = [];

        ls.push(
            {
                key: "Project", val: hub.Project
            },
            {
                key: "Asset Category", val: hub.AssetCategory
            },
            {
                key: "Survey Name", val: hub.Project
            },
        )

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"infoKeyT"+index}>
                            <td>{row.key}</td>
                            <td>{row.val}</td>
                        </tr>
                        )

                })}
                </tbody>
            </table>
        )
    }
    renderStep2 = () => {
        const hub = this.props.getAllInfo();
        let ls = [];

        for(let i in hub.DataAsset){
            const key = i;
            const val = hub.DataAsset[i];
            ls.push({
                    key: key.toLocaleUpperCase(),
                    val: val
                })
        }
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"infoKeyT"+index}>
                            <td>{row.key}</td>
                            <td>{row.val}</td>
                        </tr>
                    )

                })}
                </tbody>
            </table>
        )

    }
    renderStep3 = () => {
        const hub = this.props.getAllInfo();
        let ls = [];

        for(let i in hub.DataQuestion){
            const key = i;
            const val = hub.DataQuestion[i];
            ls.push({
                key: key.toLocaleUpperCase(),
                val: val
            })
        }
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"infoKeyT"+index}>
                            <td>{row.key}</td>
                            <td>{row.val}</td>
                        </tr>
                    )

                })}
                </tbody>
            </table>
        )
    }
    renderStep4 = () => {
        const hub = this.props.getAllInfo();
        let ls = [];

        for(let i in hub.Attachs){
            const key = i;
            const val = hub.DataQuestion[i];
            ls.push({
                key: key.toLocaleUpperCase(),
                val: key.toLocaleUpperCase()
            })
        }
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"infoKeyT"+index}>
                            <td>{row.key}</td>
                            <td>{row.val}</td>
                        </tr>
                    )

                })}
                </tbody>
            </table>
        )
    }
    renderStep5 = () => {
        const hub = this.props.getAllInfo();
        let ls = [];

        for(let i in hub.Geo){
            const key = i;
            const val = hub.Geo[i];
            ls.push({
                key: key.toLocaleUpperCase(),
                val: val
            })
        }
        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Key</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"infoKeyT"+index}>
                            <td>{row.key}</td>
                            <td>{row.val}</td>
                        </tr>
                    )

                })}
                </tbody>
            </table>
        )
    }

    render() {
        return (
            <MuiThemeProvider>
                <div className={""}>
                    <div className={"row"}>
                        <div className={"col col-lg-12"}>
                            <Tabs>
                                <Tab label="Project">
                                    <h3>Step 1: Project </h3>
                                    {this.renderStep1()}
                                </Tab>
                                <Tab label="Asset">
                                    <h3>Step 2: Asset </h3>
                                    {this.renderStep2()}
                                </Tab>
                                <Tab label="Capture">
                                    <h3>Step 3: Capture survey question </h3>
                                    {this.renderStep3()}
                                </Tab>
                                <Tab label="Attach">
                                    <h3>Step 4: Attach Support Document </h3>
                                    {this.renderStep4()}
                                </Tab>
                                <Tab label="GeoLocation">
                                    <h3>Step 4: Geo Location Coordinate </h3>
                                    {this.renderStep5()}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>

                </div>
            </MuiThemeProvider>
        )
    }
}