

import React, {Component} from "react";

import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCalendarCheck,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
import {Button, Modal} from "react-bootstrap";

export default class ComSurveyChoosen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            Assets: [],
            Surveys:[],
            Documents:[],
            Categories:[],
            selectedSurvey:"",
            newUpload:{
                org:"",
                ref:"",
                name:"",
                category:"",
                filename:"",
                base64string:"",
                username:"",
            },
            showUpload:false,
        }
    }
    async componentDidMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
        await this.fetchAssets();
        await this.fetchSurveys();
        await this.fetchDocuments();
    }
    fetchDocuments = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.state.organisation,
                Type: "string"
            }
        ];
        await this.fetchingAll("documents", conds, (data) => {
            console.log("fetchDocuments :> ", data);

            _this.setState({
                Documents: data
            });
        });
    }
    fetchSurveys = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.props.org,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchAssets :> ", data);
            _this.setState({
                Surveys: data
            });
        });
    }
    fetchAssets = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.props.org,
                Type: "string"
            }
        ];
        await this.fetchingAll("Asset", conds, (data) => {
            console.log("process taken fetchAssets :> ", data);
            _this.setState({
                Assets: data
            });
        });
    }
    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    handleInputSurvey=()=>{
        this.props.setParentState("survey",this.state.selectedAsset)
    }
    handleStartSurvey=(assetRef,participant)=>{
        if(this.props.getParentState("survey")===""){
            return alert("Please select your survey before processed!")
        }
        if(!window.confirm("Are you sure that you want to start process?\n"+
            "Survey: "+this.props.getParentState("survey")+
        "\nAsset: "+assetRef+"\nParticipant: "+participant)){
            return
        }
        this.props.setParentState("assetRef",assetRef);
        this.props.setParentState("participant",participant);
        this.props.setParentState("inProcess",true);
    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
            handleFunc();
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    openDocumentUpload=(ref)=>{
        const user = GetUserToken();
        let tmp = this.state;
        tmp.newUpload = {
            ref:ref,
                name:"",
                category:"",
                filename:"",
                base64String:"",
            username:user.Username,
        };
        tmp.showUpload=true;
        this.setState(tmp);
    }
    closeDocumentUpload=()=>{

        let tmp = this.state;
        tmp.newUpload = {
            ref:"",
            name:"",
            category:"",
            filename:"",
            base64String:"",
            username:"",
        };
        tmp.showUpload=false;
        this.setState(tmp);
    }

    submitUpload=async (e)=>{
        e.preventDefault();
        if(!window.confirm("Are you sure that you want to save this File?")){
            return
        }
        let _this = this;
        const state = this.state.newUpload;
        let hub={...state}
        hub.org = this.state.organisation;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/upload/new";


        console.log("submitOtp send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitOtp response > ", dataIn);

            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                 _this.fetchDocuments();
                 _this.closeDocumentUpload();
                alert("!File Submitted successful: "+data);
            }
        });
    }

    handleInput=(e,key)=>{
        let tmp = this.state;
        tmp.newUpload[key] = e.target.value;
        this.setState(tmp);
        console.log(":)--> HandleInput > ",key," > ",e.target.value)
    }
    getValue=(key)=>{
        if(typeof this.state.newUpload[key] ==="undefined"){
            return "";
        }
        return  this.state.newUpload[key];
    }
    handleInputFile=(e,id)=>{
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename =myfile.name;
            tmp.newUpload.base64string =reader.result;
            _this.setState(tmp);
            console.log("handleInputUpload ------>> ",myfile.name)
            console.log("handleInputUpload 2------>> ",reader.result)
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    openDocLink=(link)=>{
        console.log("Opening link pdf link request > ", link);
        document.title = "eSign Document";
        window.open(link, '_blank');
    }

    renderNewUploadForm=()=>{

        return(
            <form onSubmit={this.submitUpload}>
                <div className="form-group">
                    <label>Name oF your Document</label>
                    <input
                        type="text"
                        className="form-control"
                        required={true}
                        onChange={(e) => this.handleInput(e, "name")}
                        value={this.getValue("name")}
                    />
                </div>

                <div className="form-group">
                    <label>Category of your document</label>
                    <select
                        className="form-control"
                        required={true}
                        onChange={(e) => this.handleInput(e, "category")}
                        value={this.getValue("category")}
                    >
                        <option value={""}>--select--</option>
                        <option value={"statement"}>Statement</option>
                        <option value={"media"}>Media</option>
                        <option value={"document"}>Document</option>
                        <option value={"other"}>Other</option>
                    </select>
                </div>

                <div className="form-group">
                    <label>Choose your file</label>
                    <input
                        id={"myFile"}
                        type="file"
                        className="form-control"
                        required={true}
                        onChange={(e) => this.handleInputFile(e,"myFile")}
                    />
                </div>

                <div className="form-group">
                    <button
                        type="submit"
                        className="form-control"
                    >Submit</button>
                </div>
            </form>
        )
    }
    renderModalNewUpload = () => {
        return (
            <Modal show={this.state.showUpload} onHide={this.closeDocumentUpload}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Document &nbsp;
                        <small className={"pull-right"}>Asset Reference: <b>{this.state.newUpload.ref}</b> </small>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll"}}>

                    <div className="row">
                        <div className="col-lg-12">
                            {this.renderNewUploadForm()}
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeDocumentUpload}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    renderSelectSurvey=()=>{
        return(
            <div className="row">
                <div className="col-xs-12">
                    <h3 className="alert alert-info">Survey</h3>
                    {this.selectionBox("Survey", "Surveys", "name", "name", "selectedAsset", this.handleInputSurvey, [])}
                </div>
            </div>
        )
    }
    renderAssetList=()=>{
        const innertOwner=(row)=> {
            return(
                <div className={"container"}>

                    {row.owner.map((owner, y) => {
                        return (
                            <p style={{width:"100%"}}>
                                <button
                                    className="btn btn-default"
                                        onClick={()=>this.handleStartSurvey(row.ref,owner.idnumber)}
                                >Start</button>&nbsp;&nbsp;
                                <b>{owner.idnumber}</b> - {owner.name}
                            </p>
                        )
                    })}
            </div>
            )

        }
        const innertDocument=(row)=> {
            let ls =[];
            for(let x in this.state.Documents){
                const record = this.state.Documents[x];
                if(row.ref ===record.ref){
                    ls.push({
                        name:record.name,
                        link:record.link
                    })
                }
            }
            return(
                <div className={"container"}>

                    {ls.map((doc, y) => {
                        return (
                            <p style={{width:"100%"}}>
                                <button
                                    className="btn btn-default"
                                    onClick={()=>this.openDocLink(doc.link)}
                                >View</button>&nbsp;&nbsp;
                                {doc.name}
                            </p>
                        )
                    })}
                </div>
            )

        }

        return(
            <div>
                <h3 className="alert alert-info">Asset</h3>
                <table className={"table"}>
                    <thead>
                    <th>Ref & Address</th>
                    <th>Owner</th>
                    <th>Document</th>
                    </thead>
                    <tbody>
                    {this.state.Assets.map((row,index)=>{
                        console.log("$$$$$---> ",row);
                        return(
                            <tr>
                                <td valign={"top"}>{row.ref} & {row.address}</td>
                                <td>{innertOwner(row)}</td>
                                <td>
                                    {innertDocument(row)}
                                    <p>
                                        <a className={""} style={{cursor:"pointer"}}
                                           onClick={()=>this.openDocumentUpload(row.ref)}
                                        >
                                            <FontAwesomeIcon icon={faPlus}/>&nbsp;
                                             New Document

                                        </a>
                                    </p>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>

        )
    }
    render(){
        return(
            <div className="alert alert-default">
                {this.renderSelectSurvey()}
                {this.renderAssetList()}
                {this.renderModalNewUpload()}
            </div>
        )
    }

}
