import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

export default class ComAssetEnumeratorAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes:[],
            newEntry: {
                Project: "",
                AssetCategory: "",
                TargetSurveyPerEnumerators: 0,
                TargetSurveyPerDataCapture: 0,
                NumberOfEnumerator: 0,
                NumberOfDataCapture: 0,
                RateEnumeratorPerSurvey: 0,
                RateDataCapturePerSurvey: 0,
                AttributeLeft: "",
                AttributeRight: "",
            },
            showList: true,
        }

    }

    async componentDidMount() {

        await this.fetchProjectDashboard();
       // await this.fetchAssetAttribute();
    }


    fetchAssetAttribute = async () => {
        let _this = this;
        let info = this.props.info;
        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },{
                Key: "project",
                Val: info.Project,
                Type: "string"
            },{
                Key: "Asset",
                Val: info.Category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetAttribute", conds, (data) => {
            console.log("fetchAssetAttribute :> ", data);
            _this.setState({
                Attributes: data
            });

        });
    }
    fetchProjectDashboard = async () => {
        let _this = this;
        let info = this.props.info;
        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },{
                Key: "project",
                Val: info.Project,
                Type: "string"
            },{
                Key: "AssetCategory",
                Val: info.Category,
                Type: "string"
            },
        ];
        await this.fetchingAll("ProjectDashboard", conds, (data) => {
            console.log("fetchProjectDashboard :> ", data);
            _this.setState({
                Data: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: GetUserToken().OrgCode,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }
    handleInput = (e, key, val) => {
        e.preventDefault();
        let tmp = this.state;
        tmp[key] = val;
        this.setValue(tmp);
    }
    getValue = (key) => {
        return this.state.newEntry[key]
    }
    setValue = (e, key) => {
        let val = "";
        if (typeof e.target !== "undefined") {
            val = e.target.value;
        }
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;

        console.log("setValueBox ^^^--> ", key, " > ", val)
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    removeEntry = async (e, row) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to remove?")) {
            return
        }
        //Conditions
        let _this = this;
        let conds = [
            {Key: "org", Val: row.org, Type: "string"},
            {Key: "project", Val: row.project, Type: "string"},
            {Key: "assetcategory", Val: row.assetcategory, Type: "string"},
            {Key: "fieldname", Val: row.fieldname, Type: "string"},
        ]
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: "AssetEnumeratorAttribute",
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/remove";

        console.log("removeEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("removeEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                _this.setState({showList: true});
                _this.fetchAssetEnumeratorAttribute();
            }
        });
    }
    submitEntry = async (e) => {
        e.preventDefault();

        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let _this = this;
        let entry = {};
        entry.Org = GetUserToken().OrgCode;
        entry.Project = this.props.info.Project;
        entry.AssetCategory = this.props.info.Category;

        entry.TargetSurveyPerEnumerators = parseFloat(this.state.newEntry.TargetSurveyPerEnumerators);
        entry.TargetSurveyPerDataCapture = parseFloat(this.state.newEntry.TargetSurveyPerDataCapture);
        entry.NumberOfEnumerator = parseFloat(this.state.newEntry.NumberOfEnumerator);
        entry.NumberOfDataCapture = parseFloat(this.state.newEntry.NumberOfDataCapture);
        entry.RateEnumeratorPerSurvey = parseFloat(this.state.newEntry.RateEnumeratorPerSurvey);
        entry.RateDataCapturePerSurvey = parseFloat(this.state.newEntry.RateDataCapturePerSurvey);
        entry.AttributeLeft = this.state.newEntry.AttributeLeft;
        entry.AttributeRight = this.state.newEntry.AttributeRight;


        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: "ProjectDashboard",
            In: entry,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                let tmp = _this.state;
                tmp.showList = true;
                tmp.newEntry = {
                    Project: "",
                        AssetCategory: "",
                        TargetSurveyPerEnumerators: 0,
                        TargetSurveyPerDataCapture: 0,
                        NumberOfEnumerator: 0,
                        NumberOfDataCapture: 0,
                        RateEnumeratorPerSurvey: 0,
                        RateDataCapturePerSurvey: 0,
                        AttributeLeft: "",
                        AttributeRight: "",
                }
                _this.setState(tmp);
                _this.fetchProjectDashboard();
            }
        });
    }

    renderList = () => {
        let state = this.state;
        let data = state.Data;
        let entry = state.newEntry;
        if (!state.showList) {
            return null
        }

        let ls = data;


        if (ls.length === 0) {
            return (
                <div className={"alert alert-danger"}>Empty list!</div>
            )
        }

        return (
            <div className={"container"}>
                <p className={"alert alert-primary"}>List available Setting</p>
                <table
                    className={"table"}
                    style={{
                        border: '3px solid gray',
                        borderRightColor: 'gray',
                    }}
                >
                    <thead>

                    <tr>
                        <th>Survey/day Enumerator</th>
                        <th>Survey/day DataCapture</th>
                        <th>NumberOfEnumerator</th>
                        <th>NumberOfDataCapture</th>
                        <th>Rate Enumerator</th>
                        <th>Rate Capture</th>
                        <th>AttributeLeft</th>
                        <th>AttributeRight</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {

                        return (
                            <tr key={"xkdkd" + index}>
                                <td>{row.targetsurveyperenumerators}</td>
                                <td>{row.targetsurveyperdatacapture || "--"}</td>
                                <td>{row.numberofenumerator}</td>
                                <td>{row.numberofdatacapture || "--"}</td>
                                <td>{row.rateenumeratorpersurvey}</td>
                                <td>{row.ratedatacapturepersurvey}</td>
                                <td>{row.attributeleft}</td>
                                <td>{row.attributeright}</td>
                                <th style={{width: 100}}>
                                    <a
                                        onClick={(e) => this.removeEntry(e, row)}
                                        className={"btn btn-default"}
                                    >Remove</a>
                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderForm = () => {
        const _this = this;
        let state = this.state;
        if (state.showList) {
            return null
        }

        let attributes=[];
        for(let  i in this.props.info.Headers){
            const item = this.props.info.Headers[i];
            attributes.push(item);
        }
        console.log("&&&&&---> ",this.props.info.Headers);

        const options = ["yes", "no"];
        let ls = [
            {
                field: "TargetSurveyPerEnumerators",
                label: "Target Survey Per Enumerators",
                options: options,
                type: "text"
            },
            {
                field: "TargetSurveyPerDataCapture",
                label: "PTarget Survey Per Data Capture",
                options: options,
                type: "text"
            },
            {field: "NumberOfEnumerator", label: "Number Of Enumerator", options: options, type: "text"},
            {field: "NumberOfDataCapture", label: "Number Of Data Capture", options: options, type: "text"},
            {field: "RateEnumeratorPerSurvey", label: "Rate Enumerator Per Survey", options: options, type: "text"},
            {field: "RateDataCapturePerSurvey", label: "Rate Data Capture Per Survey", options: options, type: "text"},
            {field: "AttributeLeft", label: "Attribute Left", options: attributes, type: "option"},
            {field: "AttributeRight", label: "Attribute Right", options: attributes, type: "option"},
        ];

        const styleHere={
            minWidth:"50%"
        }

        return (
            <div className={"container"}>
                <p className={"alert alert-success"}>New Project Dashboard Setup view</p>
                <form onSubmit={this.submitEntry}>
                    {ls.map((row, index) => {
                        if (row.type === "option") {
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-16 col-lg-6">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <select
                                            name={row.field}
                                            className="form-control"
                                            onChange={(e) => _this.setValueBox(e)}
                                            value={_this.getValue(row.field)}
                                            required={true}
                                            style={styleHere}

                                        >
                                            <option value="" selected>Choose...</option>
                                            {row.options.map((item, index2) => {
                                                let key = item;
                                                let desc = item.toLocaleUpperCase();
                                                return (
                                                    <option key={index2} value={key}>{desc}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-16 col-lg-6">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => _this.setValue(e, row.field)}
                                        value={_this.getValue(row.field)}
                                        required={true}
                                        style={styleHere}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Submit
                    </button>
                </form>
            </div>
        )
    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Enumerator Attribute Setup
                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", false)}>Add New Setting</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", true)}>Cancel</a>
                                }
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">

                        </div>
                    </div>
                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )


    }

}