import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";
import ComLibProcessQuestion from "./lib-process-question";
import ComLibAttachDocument from "./lib-attach-document";
import ComLibGeo from "./lib-geo";
import ComLibInfo from "./lib-info-step";
import ComLibEnumerator from "./lib-enumerator-step";

export default class ComAssetSurvey extends Component {
    constructor(props) {
        super(props);
        this.innitialState={
            Projects: [],
            StoreProjects: [],
            StoreAssets: [],
            Surveys: [],
            Questions: [],
            DataLinks:[],
            Enumerators:[],
            organisation: "",
            currentStep: 1,
            numberStep: 6,
            stepHeader: ["Project", "Asset", "Capture", "Attach", "Geo","Enumerator", "Info"],
            newStepProject: {
                Id: "",
                Project: "",
                Category: "",
                Survey: "",
                Headers: {},
            },
            newStepAsset: {
                CurrentAttribute: "",
                CurrentSearch: "",
                Filters: {"street name":"JESSICA"},
                CurrentAsset: null,
            },
            newStepCapture:{
                Takens:[],
            },
            newStepAttach:{
                Data:{},
            },
            newStepGeo:{
                lon:0,
                lat:0,
                address:"",
            },
            newStepDatetime:{
                Date:"",
                Time:""
            },
            newStepEnumerator:null,
            showSelectAssetView: false,
            selectAssetView: null
        };
        this.state = this.innitialState;

    }

    async componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchSurvey();
        await this.loadFilterProject();
        await this.fetchDataLink();
    }


    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    loadFilterAsset = async (e) => {
        e.preventDefault();
        let _this = this;
        let hub = {
            Org: this.state.organisation,
            Project: this.state.newStepProject.Project,
            Category: this.state.newStepProject.Category,
            Filter: this.state.newStepAsset.Filters
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/asset";

        console.log("loadFilterAsset send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterAsset response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                let tmp = _this.state;
                tmp.StoreAssets = data;
                tmp.newStepAsset.Filters = {};
                _this.setState({
                    StoreAssets: data,
                })

                console.log("%%%%%%%%%%%%---> saveStepProject");
                _this.fetchQuestion();

            }
        });
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: this.state.organisation,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }
    fetchQuestion = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.state.organisation,
                Type: "string"
            }, {
                Key: "survey",
                Val: this.state.newStepProject.Survey,
                Type: "string"
            },
        ];
        await this.fetchingAll("Question", conds, (data) => {
            console.log("fetchQuestion :> ", data);
            _this.setState({
                Questions: data
            });

        });
    }
    fetchSurvey = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchSurvey :> ", data);
            _this.setState({
                Surveys: data
            });

        });
    }
    fetchDataLink = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("AssetDataLink", conds, (data) => {
            console.log("fetchDataLink :> ", data);
            _this.setState({
                DataLinks: data
            });

        });
    }
    fetchEnumerator = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: user.OrgCode,
                Type: "string"
            },
            {
                Key: "Project",
                Val: this.state.newStepProject.Project,
                Type: "string"
            },
            {
                Key: "AssetCategory",
                Val: this.state.newStepProject.Category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetEnumerator", conds, (data) => {
            console.log("fetchEnumerator :> ", data);
            _this.setState({
                Enumerators: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    getSelectedClass = (step) => {
        if (this.state.currentStep === step) {
            return "btn  btn-arrow-down"
        }
        return "btn btn-default btn-circle"
    }
    goNextStep = () => {
        let step = parseInt(this.state.currentStep);
        if (step >= this.state.numberStep) {
            //out of range number of step
            return null
        }
        step++;
        let tmp = this.state;
        tmp.currentStep = step;
        this.setState(tmp);
    }
    goBackStep = () => {
        let step = parseInt(this.state.currentStep);
        if (step <= 1) {
            //out of range number of step
            return null
        }
        step--;
        let tmp = this.state;
        tmp.currentStep = step;
        this.setState(tmp);
    }
    clickAddFilter = (e) => {
        e.preventDefault();
        const state = this.state;
        let newStepAsset = state.newStepAsset;
        const row = newStepAsset.Filters;
        row[newStepAsset.CurrentAttribute] = newStepAsset.CurrentSearch;
        newStepAsset.Filters = row;
        newStepAsset.CurrentSearch = "";
        newStepAsset.CurrentAttribute = "";
        state.newStepAsset = newStepAsset;
        state.StoreAssets = [];
        this.setState(state);
    }
    ClickToRemoveFilter = (e, innerKey) => {
        e.preventDefault();
        const state = this.state;
        let newStepAsset = state.newStepAsset;
        const row = newStepAsset.Filters;
        delete row[innerKey];
        newStepAsset.Filters = row;
        state.newStepAsset = newStepAsset;
        this.setState(state);
    }
    changeStep = (elname) => {
        let arr = elname.split("-");
        console.log("Change Step> ", arr, (arr.length > 1));
        if (arr.length > 1) {
            let myStep = arr[1];
            let step = parseInt(myStep);
            let tmp = this.state;
            tmp.currentStep = step;
            this.setState(tmp);
            console.log("Change Step state is now > ", this.state.currentStep, myStep);
        }
    }
    openSelectedAsset = (rowIn) => {
        let tmp = this.state;
        tmp.selectAssetView = rowIn;
        tmp.showSelectAssetView = true;
        this.setState(tmp);
    }
    closeSelectedAsset = (rowIn) => {
        let tmp = this.state;
        tmp.selectAssetView = null;
        tmp.showSelectAssetView = false;
        this.setState(tmp);
    }
    chooseAsset = (e, row) => {
        e.preventDefault();
        console.log("########---> ", row);
        if (!window.confirm("Are you sure to start survey on asset number: " + row.Data["parcel key"])) {
            return
        }
        //todo store chosen asset
        const state = this.state;
        let newStepAsset = state.newStepAsset;
        newStepAsset.CurrentAsset = row;
        state.newStepAsset = newStepAsset;
        this.setState(state);

        //todo go to next step
        this.goNextStep();

        //todo fetch old survey of this asset

    }
    setParentState = (key, val) => {
        let tmp =this.state;
        tmp.newStepCapture.Takens = val;
        this.setState(tmp);
        console.log("setParentState @@@@---> ",val);
    }
    saveStepProject = async (e) => {
        e.preventDefault();
        //TODO save detail to db tempory


        this.goNextStep();
    }
    saveStepAsset = (e) => {
        e.preventDefault();
        //TODO save detail to db tempory

        this.goNextStep();
    }
    setStepAttachFile=(category,base64string)=>{
        let state = this.state;
        state.newStepAttach.Data[category]=base64string;
        this.setState(state);
    }
    setEnumerator=(row)=>{
        let tmp = this.state;
        tmp.newStepEnumerator = row;
        this.setState(tmp);
    }
    removeStepAttachFile=(category)=>{
        let state = this.state;
        delete state.newStepAttach.Data[category];
        this.setState(state);
    }
    saveGeoToParent=(inData)=>{
        let state = this.state;
        state.newStepGeo = inData;
        this.setState(state);
    }

    saveStepCapture = (e) => {
        e.preventDefault();
        //TODO save detail to db tempory

        this.goNextStep();
    }
    saveStepAttach = (e) => {
        e.preventDefault();
        //TODO save detail to db tempory

        this.goNextStep();
    }
    saveStepGoe = (e) => {
        e.preventDefault();
        //TODO save detail to db tempory

        this.goNextStep();
    }
    saveStepInfo = (e) => {
        e.preventDefault();
        //TODO save detail to db tempory

        this.goNextStep();
    }

    getAllInfo=()=>{
        const user = GetUserToken();
        let hub = {};
        hub.Org = user.OrgCode;
        hub.Project = this.state.newStepProject.Project;
        hub.AssetCategory = this.state.newStepProject.Category;
        hub.SurveyName = this.state.newStepProject.Survey;

        hub.AssetRef  = "none";
        hub.HeaderAsset = {};
        hub.DataAsset={};
        if(this.state.newStepAsset.CurrentAsset !==null){
            hub.AssetRef  = this.state.newStepAsset.CurrentAsset.Ref;
            hub.HeaderAsset = this.buildHeaderAsset();
            hub.DataAsset=this.buildDataAsset();
        }


        hub.HeaderQuestion = this.buildHeaderQuestion();
        hub.DataQuestion=this.buildDataQuestion();
        hub.InRef  = this.buildAssetRef(hub.DataAsset,hub.DataQuestion);
        hub.DataAsset = this.makeDataLink(hub.DataAsset,hub.DataQuestion);
        hub.Enumerator = user.Username;
        hub.Attachs = this.state.newStepAttach.Data;
        hub.Geo = this.buildGeo();
        hub.InterviewDate = this.state.newStepDatetime.Date;
        hub.InterviewTime = this.state.newStepDatetime.Time;
        return hub;
    }
    submitAssetSurvey=async (e)=>{
        e.preventDefault();
        if(!window.confirm("Are you sure that you want to submit this Asset Survey?")){
            return
        }
        let _this = this;
        const user = GetUserToken();

        let hub = {};
        hub.Org = user.OrgCode;
        hub.Project = this.state.newStepProject.Project;
        hub.AssetCategory = this.state.newStepProject.Category;
        hub.SurveyName = this.state.newStepProject.Survey;
        hub.AssetRef  = this.state.newStepAsset.CurrentAsset.Ref;

        hub.HeaderAsset = this.buildHeaderAsset();
        hub.HeaderQuestion = this.buildHeaderQuestion();
        hub.DataAsset=this.buildDataAsset();
        hub.DataQuestion=this.buildDataQuestion();
        hub.InRef  = this.buildAssetRef(hub.DataAsset,hub.DataQuestion);
        hub.DataAsset = this.makeDataLink(hub.DataAsset,hub.DataQuestion);
        hub.Username = user.Username;
        hub.Enumerator = this.state.newStepEnumerator.email;
        hub.Attachs = this.state.newStepAttach.Data;
        hub.Geo = this.buildGeo();
        hub.InterviewDate = this.state.newStepDatetime.Date;
        hub.InterviewTime = this.state.newStepDatetime.Time;


        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/survey/add";

        console.log("submitAssetSurvey send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitAssetSurvey response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset Survey submitted!");
                _this.setState(_this.innitialState)
            }
        });


    }
    makeDataLink=(assetData,qData)=>{
        let ls=[];
        const stateProject = this.state.newStepProject;
        for(let  i in this.state.DataLinks){
            const row = this.state.DataLinks[i];
            let boo =false;
            if(stateProject.Project ===row.project &&
            stateProject.Category===row.assetcategory&&
            stateProject.Survey===row.survey){
                ls.push(row);
                boo=true;
            }
        }
        for(let i in ls){
            const rowLink = ls[i];
            const attribute =rowLink.assetattribute;
            const question =rowLink.questionname;
            const questionData = qData[question];
            assetData[attribute] =questionData;
        }

        return assetData;
    }
    buildGeo=()=>{
        let lat = "0";
        let lng="0";
        if(this.state.newStepGeo.lat !==0){
            lat = this.state.newStepGeo.lat.toString();
            lng = this.state.newStepGeo.lng.toString();
        }
        return{
            lat:lat,
            lng:lng,
            address:this.state.newStepGeo.address.toString(),
        }
    }
    buildAssetRef=(assetData,qData)=>{
        let ref="";
        let storeData =this.makeDataLink(assetData,qData) ; //this.state.newStepAsset.CurrentAsset.Data;
        //todo please do data link here between Asset and Survey question


        //end of data link
        let x=0;
        for(let i in storeData){
            const val  = storeData[i];
            if(x===0){
                ref +=val;
            }else{
                ref +="--"+val;
            }
            x++
        }

        return ref;
    }
    buildDataAsset=()=>{
        let data = {};
        let storeData = this.state.newStepAsset.CurrentAsset.Data;

        for(let i in storeData){
            data[i] = storeData[i].toString();
        }

        //todo please do data link here between Asset and Survey question


        return data;
    }
    buildDataQuestion=()=>{

        let data = {};
        let storeData = this.state.newStepCapture.Takens;
        //todo please do data link here between Asset and Survey question

        for(let i in storeData){
            const row = storeData[i];
            let questionName=row.QuestionInfo.name;
            let questionAnswer="";
            if(row.Category ==="option"){
                questionAnswer = row.RadioAnswer.Name;
            }else{// it is a text question
                questionAnswer = row.SingleAnswer.Name;
            }

            data[questionName] = questionAnswer;
        }

        return data;
    }
    buildHeaderAsset=()=>{
        let data = {};
        let storeData = this.state.newStepAsset.CurrentAsset.Data;
        for(let i in storeData){
            data[i] = "";
        }
        return data;
    }
    buildHeaderQuestion=()=>{
        let data = {};
        let storeData = this.state.Questions;
        for(let i in storeData){
            const row = storeData[i];
            data[row.name] = "";
        }
        return data;
    }

    renderStepNavigation = () => {
        return (
            <div className="row container">
                <div className="col-xs-12">
                    <hr/>
                    <a
                        onClick={() => this.goBackStep()}
                        className={"btn btn-primary pull-left"}
                        style={{backgroundColor:" #00b3ee"}}
                    > <span className={"fa fa-angle-double-left"}>&nbsp;</span> Back Step</a>
                    <button
                        type={"submit"}
                        onClick={() => this.goNextStep()}
                        className={"btn btn-primary pull-right "}
                        style={{backgroundColor:" #00b3ee"}}
                    > Next Step<span className={"fa fa-angle-double-right"}>&nbsp;</span>
                    </button>
                </div>
            </div>
        )
    }
    renderStepController = () => {
        let arr =this.state.stepHeader; // ["Project", "Asset", "Capture", "Attach", "Geo", "Info"];
        let x = 0;
        return arr.map((row, index) => {
            x++;
            let myPosition = (index + 1);
            return (
                <div className="stepwizard-step">
                    <a name={"linkstep-" + x} onClick={(e) => this.changeStep("linkstep-" + myPosition)} type="button"
                       className={this.getSelectedClass(myPosition)}><b>{myPosition}</b></a>
                    <p><b>{row}</b></p>
                </div>
            )
        })
    }
    renderStepProject = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 1) {
            return
        }

        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newStepProject = state.newStepProject;
            newStepProject.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newStepProject.Id === index) {
                    newStepProject.Project = row.Project;
                    newStepProject.Category = row.Category;
                    newStepProject.Headers = row.Data;
                }
                index++;
            }
            state.newStepProject = newStepProject;
            _this.setState(state);
            _this.fetchEnumerator();
        }
        const setValueSurvey = (e) => {
            const val = e.target.value;
            const state = _this.state;
            let newStepProject = state.newStepProject;
            newStepProject.Survey = val;
            state.newStepProject = newStepProject;
            _this.setState(state);

        }
        const getValueProject = () => {
            return _this.state.newStepProject.Id
        }
        const getValueSurvey = () => {
            return _this.state.newStepProject.Survey
        }


        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Select Project & Survey
                </p>

                <form onSubmit={this.saveStepProject}>

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <label htmlFor="inputState">Project & Category</label>
                            <select
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setValueProject(e)}
                                value={getValueProject()}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {this.state.StoreProjects.map((row, index) => {
                                    let key = row.Id;
                                    let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                                    return (
                                        <option key={index} value={index}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <label htmlFor="inputState">Survey Question</label>
                            <select
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setValueSurvey(e)}
                                value={getValueSurvey()}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {this.state.Surveys.map((row, index) => {
                                    let key = row.name;
                                    let desc = row.name.toLocaleUpperCase();
                                    return (
                                        <option key={index} value={key}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    {this.renderStepNavigation()}

                </form>

            </div>
        )
    }
    renderStepAsset = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 2) {
            return
        }

        let _this = this;
        const setValueAttribute = (e) => {
            const val = e.target.value;
            const state = _this.state;
            let newStepAsset = state.newStepAsset;
            newStepAsset.CurrentAttribute = val;
            state.newStepAsset = newStepAsset;
            _this.setState(state);

        }
        const setValueSearch = (e) => {
            const val = e.target.value;
            const state = _this.state;
            let newStepAsset = state.newStepAsset;
            newStepAsset.CurrentSearch = val;
            state.newStepAsset = newStepAsset;
            _this.setState(state);

        }
        const getValueAttribute = () => {
            return _this.state.newStepAsset.CurrentAttribute
        }
        const getValueSearch = () => {
            //console.log("getValueSearch: ",_this.state.newStepAsset.CurrentSearch);
            return _this.state.newStepAsset.CurrentSearch;
        }

        let lsAttribute = [];
        for (let item in this.state.newStepProject.Headers) {
            lsAttribute.push(item);
        }
        console.log("^^--> ", this.state.newStepAsset.Filters.length);

        let lsFiltering = [];
        for (let item in this.state.newStepAsset.Filters) {
            const key = item;
            const val = this.state.newStepAsset.Filters[item];
            lsFiltering.push({
                key: key,
                val: val
            });
        }


        return (
            <div className={"container"}>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Find Asset
                </p>


                <div className="row">
                    <div className="form-group col-md-5">
                        <label htmlFor="inputState">Asset Attribute</label>
                        <select
                            className="form-control"
                            onChange={(e) => setValueAttribute(e)}
                            value={getValueAttribute()}

                        >
                            <option value="" selected>Choose...</option>
                            {lsAttribute.map((item, index) => {
                                let key = item;
                                let desc = item;
                                return (
                                    <option key={index} value={key}>{desc}</option>
                                )
                            })}

                        </select>
                    </div>

                    <div className="form-group col-md-5">
                        <label htmlFor="inputState">Search Attribute</label>
                        <input
                            className="form-control"
                            onChange={(e) => setValueSearch(e)}
                            value={getValueSearch()}

                        />
                    </div>

                    <div className="form-group col-md-2">
                        <label htmlFor="inputState">&nbsp;</label>
                        <button
                            className="form-control"
                            onClick={(e) => this.clickAddFilter(e)}
                        >Add Filter
                        </button>
                    </div>

                </div>


                {lsFiltering.length > 0 ?
                    <div className="row alert alert-info">
                        <div className="col col-md-12 ">
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Value</th>
                                    <th>#</th>
                                </tr>
                                </thead>
                                <tbody>
                                {lsFiltering.map((row, index) => {
                                    return (
                                        <tr key={"innerLoop" + index}>
                                            <td>{row.key}</td>
                                            <td>{row.val}</td>
                                            <td style={{width: 100}}>
                                                <a
                                                    onClick={(e) => {
                                                        this.ClickToRemoveFilter(e, row.key)
                                                    }}
                                                    style={{color: "blue", cursor: "pointer"}}
                                                >Remove</a>
                                            </td>
                                        </tr>
                                    )
                                })}
                                </tbody>

                            </table>
                            <hr/>
                            <button
                                onClick={(e) => {
                                    this.loadFilterAsset(e)
                                }}
                                className={"btn btn-default pull-right"}
                            >Search Data
                            </button>

                        </div>
                    </div> : null}

                {this.state.StoreAssets.length > 0 ?
                    <div className="row alert alert-info">
                        <div className="col col-md-12 ">
                            <h4 style={{color: "red", textAlign: "center"}}>Result
                                ({this.state.StoreAssets.length})</h4>
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Parcel Code</th>
                                    <th>Suburb</th>
                                    <th>Street Name</th>
                                    <th>Street Number</th>
                                    <th>Street Type</th>
                                    <th>Number Of Units</th>
                                </tr>
                                </thead>

                                <tbody>
                                {this.state.StoreAssets.map((row, index) => {
                                    const parcelKey = row.Data["parcel key"];
                                    const suburbName = row.Data["suburb name"];
                                    const streetName = row.Data["street name"];
                                    const streetNumber = row.Data["address number"];
                                    const numberOfUnits = row.Data["number of units"];
                                    const streetType = row.Data["street type"];
                                    return (
                                        <tr key={"dsAssetData" + index}>
                                            <td>
                                                <a
                                                    onClick={(e) => this.chooseAsset(e, row)}
                                                    style={{cursor: "pointer"}}
                                                    className={"btn btn-default"}
                                                >Choose</a>
                                                &nbsp;|&nbsp;
                                                <a
                                                    style={{cursor: "pointer"}}
                                                    className={"btn btn-default"}
                                                    onClick={() => this.openSelectedAsset(row)}
                                                >View</a>
                                            </td>
                                            <td>{parcelKey}</td>
                                            <td>{suburbName}</td>
                                            <td>{streetName}</td>
                                            <td>{streetNumber}</td>
                                            <td>{streetType}</td>
                                            <td>{numberOfUnits}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>

                            </table>
                        </div>
                    </div> : null
                }


                {this.renderStepNavigation()}

            </div>
        )
    }
    renderStepCapture = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 3) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Capture Survey
                </p>

                <form onSubmit={this.saveStepCapture}>

                    <ComLibProcessQuestion
                        Questions={this.state.Questions}
                        Takens={this.state.newStepCapture.Takens}
                        setParentState={this.setParentState}
                    />
                    {this.renderStepNavigation()}
                </form>
            </div>
        )
    }
    renderStepAttach = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 4) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Attach Supported Document
                </p>

                <ComLibAttachDocument
                    attachs={this.state.newStepAttach.Data}
                    setStepAttachFile={this.setStepAttachFile}
                    removeStepAttachFile={this.removeStepAttachFile}
                />
                {this.renderStepNavigation()}


                {/*<form onSubmit={this.saveStepAttach}>
                </form>*/}


            </div>
        )
    }
    renderStepGeo = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 5) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Geo Location Locator
                </p>

                <ComLibGeo
                    Geos={this.state.newStepGeo}
                    saveGeoToParent={this.saveGeoToParent}
                    goToNextStep={this.goNextStep}
                />

               {/* <form onSubmit={this.saveStepGoe}>
                    {this.renderStepNavigation()}
                </form>*/}
            </div>
        )
    }
    renderStepEnumerator = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 6) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                   Enumerator
                </p>

                <ComLibEnumerator
                    setEnumerator={this.setEnumerator}
                    Enumerators={this.state.Enumerators}
                    CurrentEnumerator={this.state.newStepEnumerator}
                />

                {/* <form onSubmit={this.saveStepGoe}>
                    {this.renderStepNavigation()}
                </form>*/}
            </div>
        )
    }

    renderStepInfo = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 7) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Survey info summary

                    <button
                        className=" pull-right btn btn-success"
                        onClick={(e) => this.submitAssetSurvey(e)}
                    >Finish & Submit
                    </button>
                </p>



                <ComLibInfo
                    state={this.state}
                    getAllInfo={this.getAllInfo}
                />

                {/*<form onSubmit={this.saveStepInfo}>

                    <div className="form-group col-md-2">
                        <label htmlFor="inputState">&nbsp;</label>
                        <button
                            className="form-control"
                            onClick={(e) => this.submitAssetSurvey(e)}
                        >simulate Submit
                        </button>
                    </div>


                    {this.renderStepNavigation()}
                </form>*/}
            </div>
        )
    }

    renderModalSelectedAssetView = () => {
        if (!this.state.showSelectAssetView) {
            return null
        }
        if (this.state.selectAssetView === null) {
            return null
        }

        const obj = this.state.selectAssetView;
        let ls = [];
        for (let key in obj.Data) {
            const val = obj.Data[key];
            ls.push({
                key: key,
                val: val
            });
        }

        return (
            <Modal show={this.state.showSelectAssetView} onHide={this.closeSelectedAsset}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Parcel Key : {obj.Data["parcel key"]}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll"}}>

                    <div className="row">
                        <div className="col-lg-12">
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ls.map((row, index) => {
                                    return (
                                        <tr>
                                            <td>{row.key}</td>
                                            <td>{row.val}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeSelectedAsset}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Asset Survey


                            </h2>
                        </div>

                    </div>

                    <div className="row container">
                        <div className="col col-lg-12 col-xs-12">
                            <div className="stepwizard col-md-offset-3">
                                <div className="stepwizard-row setup-panel">
                                    {this.renderStepController()}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col col-lg-12 col-xs-12">

                            {this.renderStepProject()}
                            {this.renderStepAsset()}
                            {this.renderStepCapture()}
                            {this.renderStepAttach()}
                            {this.renderStepGeo()}
                            {this.renderStepInfo()}
                            {this.renderStepEnumerator()}
                            {this.renderModalSelectedAssetView()}

                        </div>
                    </div>


                </div>

            </PageContentWallpaper>
        )
    }

}