import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

export default class ComAssetEnumeratorAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            StoreProjects: [],
            newEntry: {
                Project: "",
                Category: "",
                FieldName: "",
                Unique: "",
                Mandatory: "",
                Generated: "",
                Username: "",
                Password: "",
                Position:"",
                Name:"",
                Surname:"",

            },
            showList: true,
            showViewDetail: false,
        }

    }

    async componentDidMount() {
        await this.loadFilterProject();
        await this.fetchAssetEnumeratorAttribute();
    }

    fetchAssetEnumeratorAttribute = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("AssetEnumeratorAttribute", conds, (data) => {
            console.log("fetchAssetEnumeratorAttribute :> ", data);
            const keyOne = "position";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
            _this.setState({
                Data: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: GetUserToken().OrgCode,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }
    handleInput = (e, key, val) => {
        e.preventDefault();
        let tmp = this.state;
        tmp[key] = val;
        this.setValue(tmp);
    }
    getValue = (key) => {
        return this.state.newEntry[key]
    }
    setValue = (e, key) => {
        let val = "";
        if (typeof e.target !== "undefined") {
            val = e.target.value;
        }
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;

        console.log("setValueBox ^^^--> ", key, " > ", val)
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    removeEntry = async (e, row) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to remove?")) {
            return
        }
        //Conditions
        let _this = this;
        let conds=[
            {Key: "org", Val: row.org, Type: "string"},
            {Key: "project", Val: row.project, Type: "string"},
            {Key: "assetcategory", Val: row.assetcategory, Type: "string"},
            {Key: "fieldname", Val: row.fieldname, Type: "string"},
        ]
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: "AssetEnumeratorAttribute",
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/remove";

        console.log("removeEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("removeEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                _this.setState({showList: true});
                _this.fetchAssetEnumeratorAttribute();
            }
        });
    }
    submitEntry = async (e) => {
        e.preventDefault();
        if(this.state.newEntry.Project===""){
            return alert("Plase select your Project & Category before submit");
        }
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let _this = this;
        let entry = {};
        entry.Org = GetUserToken().OrgCode;
        entry.Project = this.state.newEntry.Project;
        entry.AssetCategory = this.state.newEntry.Category;
        entry.Fieldname = this.state.newEntry.FieldName;
        entry.Unique = this.state.newEntry.Unique;
        entry.Mandatory = this.state.newEntry.Mandatory;
        entry.Generated = this.state.newEntry.Generated;
        entry.Username = this.state.newEntry.Username;
        entry.Position = parseInt( this.state.newEntry.Position);
        entry.Password = this.state.newEntry.Password;
        entry.Name = this.state.newEntry.Name;
        entry.Surname = this.state.newEntry.Surname;

        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: "AssetEnumeratorAttribute",
            In: entry,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                let tmp =_this.state;
                tmp.showList =true;
                tmp.newEntry.Generated = "";
                tmp.newEntry.Mandatory = "";
                tmp.newEntry.Unique = "";
                tmp.newEntry.Username = "";
                tmp.newEntry.Password = "";
                tmp.newEntry.FieldName = "";
                tmp.newEntry.Name = "";
                tmp.newEntry.Surname = "";
                tmp.newEntry.Position = "";
                _this.setState(tmp);
                _this.fetchAssetEnumeratorAttribute();
            }
        });
    }

    validateFormInput=(key)=>{
        let boo=false;
        let entry = this.state.newEntry;
        let keys=["Unique","Generated","Username","Password","Name","Surname"];
        for(let a in keys){
            const item=keys[a];
            console.log("{:}-> ",key,"==",item," > ",(key===item));
            if(key===item){
                for(let i in this.state.Data){
                    const row =this.state.Data[i];
                    if (row.project === entry.Project && row.assetcategory === entry.Category) {
                        const innerKey = item.toLocaleLowerCase();
                        if(row[innerKey]==="yes"){
                            boo=true
                        }
                    }

                }
            }
        }

        return boo;
    }
    renderList = () => {
        let state = this.state;
        let data = state.Data;
        let entry = state.newEntry;
        if (!state.showList) {
            return null
        }

        let ls = [];
        for (let i in data) {
            const row = data[i];
            if (row.project === entry.Project && row.assetcategory === entry.Category) {
                ls.push(row)
            }
        }

        if (ls.length === 0) {
            return (
                <div className={"alert alert-danger"}>Empty list!</div>
            )
        }

        return (
            <div className={"container"}>
                <p className={"alert alert-primary"}>List Attributes</p>
                <table
                    className={"table"}
                    style={{
                        border: '3px solid gray',
                        borderRightColor: 'gray',
                    }}
                >
                    <thead>

                    <tr>
                        <th>Position</th>
                        <th>Name_Field</th>
                        <th>Unique</th>
                        <th>Mandatory</th>
                        <th>Generated</th>
                        <th>Username</th>
                        <th>Password</th>
                        <th>#</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {

                        return (
                            <tr key={"xkdkd" + index}>
                                <td style={{width: 30}}>
                                    {row.position}
                                </td>
                                <td>{row.fieldname}</td>
                                <td>{row.unique ||"--"}</td>
                                <td>{row.mandatory}</td>
                                <td>{row.generated ||"--"}</td>
                                <td>{row.username}</td>
                                <td>{row.password}</td>
                                <th style={{width: 100}}>
                                    <a
                                        onClick={(e) => this.removeEntry(e, row)}
                                        className={"btn btn-default"}
                                    >Remove</a>
                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }

    renderForm = () => {
        const _this = this;
        let state = this.state;
        if (state.showList) {
            return null
        }

        const options = ["yes", "no"];
        let ls = [
            {field: "FieldName", label: "Name of attribute", options: options, type: "text"},
            {field: "Position", label: "Position of Attribute", options: options, type: "text"},
            {field: "Unique", label: "Unique", options: options, type: "option"},
            {field: "Mandatory", label: "Mandatory", options: options, type: "option"},
            {field: "Generated", label: "Generated", options: options, type: "option"},
            {field: "Username", label: "Login Username", options: options, type: "option"},
            {field: "Password", label: "Login Password", options: options, type: "option"},
            {field: "Name", label: "Display as Name", options: options, type: "option"},
            {field: "Surname", label: "Display as Surname", options: options, type: "option"},
        ]

        return (
            <div className={"container"}>
                <p className={"alert alert-success"}>New Attribute</p>
                <form onSubmit={this.submitEntry}>
                    {ls.map((row, index) => {
                        if (row.type === "option") {
                            return (
                                <div className="row" key={"zty" + index}>
                                    <div className="form-group col-md-16">
                                        <label htmlFor="inputState">{row.label}</label>
                                        <select
                                            name={row.field}
                                            className="form-control"
                                            onChange={(e) => _this.setValueBox(e)}
                                            value={_this.getValue(row.field)}
                                            required={true}
                                            disabled={_this.validateFormInput(row.field)}
                                        >
                                            <option value="" selected>Choose...</option>
                                            {row.options.map((item, index2) => {
                                                let key = item ;
                                                let desc = item.toLocaleUpperCase();
                                                return (
                                                    <option key={index2} value={key}>{desc}</option>
                                                )
                                            })}

                                        </select>
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">{row.label}</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => _this.setValue(e, row.field)}
                                        value={_this.getValue(row.field)}
                                        required={true}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Submit
                    </button>
                </form>
            </div>
        )
    }

    renderProjectBox = () => {
        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newEntry = state.newEntry;
            newEntry.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newEntry.Id === index) {
                    newEntry.Project = row.Project;
                    newEntry.Category = row.Category;
                    newEntry.Headers = row.Data;
                }
                index++;
            }
            state.newEntry = newEntry;
            _this.setState(state);


        }
        const getValueProject = () => {
            return _this.state.newEntry.Id
        }



        return (
            <div className="row container">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">Project</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValueProject(e)}
                        value={getValueProject()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {this.state.StoreProjects.map((row, index) => {
                            let key = row.Id;
                            let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                            return (
                                <option key={index} value={index}>{desc}</option>
                            )
                        })}

                    </select>
                </div>

            </div>
        )
    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Enumerator Attribute Setup
                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", false)}>Add New Attribute</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", true)}>Cancel</a>
                                }
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            {this.renderProjectBox()}
                        </div>
                    </div>
                    {this.state.showList ? this.renderList() : this.renderForm()}
                </div>
            </PageContentWallpaper>
        )


    }
}