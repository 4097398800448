import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';
import {PostToUser} from '../../api/services';
import {connect} from 'react-redux'
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import moment from "moment";

class ComSaleBulk extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ref: "",
            email: "",
            Histories: [],
            Projects: [],
            Categories: [],
            newImport: {
                Ref: "",
                Category: "",
                Project: "",
            },
            newUpload: {
                name: "",
                base64string: "",
                filename: "",
            },
            showForm:false,
        }

    }

    async componentDidMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
        await this.isSupperUser();
        await this.fetchDataAssetUploadHistory();
        await this.fetchProjects();
        await this.fetchAssetCategories();

    }

    async componentWillMount() {
        await this.isSupperUser();

    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    handleInputSale = (e, key) => {
        let tmp = this.state;
        tmp.newImport[key] = e.target.value;
        this.setState(tmp);
    }
    isSupperUser = async () => {
        let user = GetUserToken();
        let tmp = this.state;
        tmp.user = user;
        tmp.email = user.Username;
        this.setState(tmp);
    }
    fetchAssetCategories = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("AssetCategory", conds, (data) => {
            _this.setState({
                Categories: data,
            });
        })
    }

    fetchProjects = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Project", conds, (data) => {
            _this.setState({
                Projects: data,
            });
        })
    }
    fetchDataAssetUploadHistory = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("DataAssetUploadHistory", conds, (data) => {
            _this.setState({
                Histories: data,
            });
        })
    }

    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }

    submitImportCsv = async (e) => {

        /*
        hub := ServiceAssetDataImportCsv{}
	hub.Org = "easipath"
	hub.Project = "Local Housing Needs Survey"
	hub.Asset = "Housing"
	hub.Username = "imbani16@gmail.com"
	hub.UploadRef = "TH-OP123"

         */
        e.preventDefault();
        const user =  GetUserToken();
        const _this = this;
        const state = this.state;
        const username =user.Username;





        let hub = {
            Org: user.OrgCode,
            Project: state.newImport.Project,
            Asset: state.newImport.Category,
            UploadRef: state.newImport.Ref,
            Username: username,
            Base64String: state.newUpload.base64string,
            RequestType: "",

        }

        console.log("submitImportCsv send > ", hub);


        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/import-csv";

        //   ALPHA-OMOCA-01
        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("convertBase64CsvIntoString response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you  for your asset csv import , an email will be send to you once  done!")
                let tmp = _this.state;
                tmp.newImport = {
                    Ref: "",
                    Category: "",
                    Project: "",
                };
                tmp.newUpload = {
                    name: "",
                    base64string: "",
                    filename: "",
                };
                _this.setState(tmp);
                await _this.fetchDataAssetUploadHistory();
                window.location.reload();

            } else {
                alert(":) Sorry error occurred during processing please try again")
            }
        })


    }

    renderAssetUploadHistory = () => {

        if(this.state.showForm){
            return null
        }

        let histories = this.state.Histories;

        if(histories.length ===0){
            return(
                <p className={"alert alert-danger"}>
                    Empty upload history
                </p>
            )
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>UploadRef</th>
                    <th>TotalData</th>
                    <th>TotalSuccess</th>
                    <th>TotalErrorUnique</th>
                    <th>TotalErrorMandatory</th>
                    <th>View Error Data</th>
                </tr>
                </thead>
                <tbody>
                { histories.map((row,index)=>{
                    let totSuccess=parseFloat(row.totaldata) - parseFloat(row.totalerrorunique) - parseFloat(row.totalerrormandatory);

                    return(
                        <tr>
                            <td>{row.uploadref}</td>
                            <td>{row.totaldata}</td>
                            <td>{totSuccess}</td>
                            <td>{row.totalerrorunique}</td>
                            <th>{row.totalerrormandatory}</th>
                            <td>
                                <a >Unique Attribute</a>&nbsp;|&nbsp;
                                <a >Mandatory</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )


    }

    renderAssetNewForm=()=>{
        if(!this.state.showForm){
            return null
        }
        return(
            <form onSubmit={this.submitImportCsv}>
                <div className="row">
                    <div className="col-xs-12">

                        <div className="form-group">
                            <label>Bulk Reference</label>
                            <input
                                type="text"
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInputSale(e, "Ref")}
                            />
                        </div>

                        <div className="form-group">
                            <label>Choose Project</label>
                            <select
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInputSale(e, "Project")}
                                value={this.state.newImport.Project}
                            >
                                <option value={"airtime"}>--Select Project--</option>
                                {this.state.Projects.map((row,index)=>{
                                    return(
                                        <option key={"PROJECT-"+index} value={row.name}>{row.name}</option>
                                    )
                                })}
                            </select>
                        </div>

                        <div className="form-group">
                            <label>Choose Asset Category</label>
                            <select
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInputSale(e, "Category")}
                                value={this.state.newImport.Category}
                            >
                                <option value={"airtime"}>--Select Asset Category--</option>
                                {this.state.Categories.map((row,index)=>{
                                    return(
                                        <option key={"PROJECT-"+index} value={row.name}>{row.name}</option>
                                    )
                                })}
                            </select>
                        </div>


                        <div className="form-group">
                            <label>Choose your file</label>
                            <input
                                id={"myFile"}
                                type="file"
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInputFile(e, "myFile")}
                            />
                        </div>


                        <div className="form-group">
                            <button
                                type="submit"
                                className="btn-primary form-control"
                            >Submit
                            </button>
                        </div>


                    </div>

                </div>

            </form>
        )
    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Asset Import Csv

                                {!this.state.showForm?
                                    <a
                                        className={"btn btn-primary pull-right"}
                                        onClick={()=>this.setState({showForm:true})}
                                    >New</a>:
                                    <a
                                        className={"btn btn-danger pull-right"}
                                        onClick={()=>this.setState({showForm:false})}
                                    >Cancel</a>
                                }


                            </h2>
                        </div>

                    </div>

                    <div className="row container">
                        <div className="col-xs-12">


                            {this.renderAssetUploadHistory()}

                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            {this.renderAssetNewForm()}
                        </div>
                    </div>


                </div>


            </PageContentWallpaper>
    )
    }

    }


    const mapDispatchToProps = dispatch => ({
        dispatch: dispatch
    })

    const mapStateToProps = state => ({
        addingModel: state.addingModel,
        login: state.login
    });

    export default connect(
    mapStateToProps,
    mapDispatchToProps
    )(ComSaleBulk)