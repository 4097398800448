import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

export default class ComAssetExtractionWizard extends Component {
    constructor(props) {
        super(props);
        this.innitialState = {
            Projects: [],
            StoreProjects: [],
            Questions: [],
            Surveys: [],
            DataExtractions: [],
            newStepProject: {
                Id: "",
                Project: "",
                Category: "",
                Survey: "",
                Headers: {},
            },
            dataStructure: {},
            stepHeader: ["Project", "Attribute & Question", "Review & Submit"],
            currentStep: 1,

        };
        this.state = this.innitialState;

    }

    async componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.loadFilterProject();
        await this.fetchSurvey();
        await this.fetchDataStructure();
    }

    fetchQuestion = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.state.organisation,
                Type: "string"
            }, {
                Key: "survey",
                Val: this.state.newStepProject.Survey,
                Type: "string"
            },
        ];
        await this.fetchingAll("Question", conds, (data) => {
            console.log("fetchQuestion :> ", data);
            _this.setState({
                Questions: data
            });

        });
    }
    fetchSurvey = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [{
            Key: "org",
            Val: user.OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchSurvey :> ", data);
            _this.setState({
                Surveys: data
            });

        });
    }
    fetchDataStructure = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }, {
            Key: "Project",
            Val: this.state.newStepProject.Project,
            Type: "string"
        }, {
            Key: "AssetCategory",
            Val: this.state.newStepProject.Category,
            Type: "string"
        }, {
            Key: "SurveyName",
            Val: this.state.newStepProject.Survey,
            Type: "string"
        },];
        console.log("fetchDataStructure send :> ", conds);
        await this.fetchingAll("AssetExtraction", conds, (data) => {
            console.log("fetchDataStructure response :> ", conds," > ",data);
            let dataStructure={};
            /*
            org: user.OrgCode,
                Project: this.state.newStepProject.Project,
                AssetCategory: this.state.newStepProject.Category,
                SurveyName: this.state.newStepProject.Survey,
                Attribute: i,
                Source: row.from,
                Checked: row.checked,
                Position: row.position,
                Alias: row.alias
             */
            for(let i in data){
                const row = data[i];
                const item = row.attribute;
                dataStructure[item] = {
                    item: item,
                    from: row.source,
                    checked: true,
                    position: row.position,
                    alias: row.alias
                };
            }
            _this.setState({
                dataStructure: dataStructure
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: this.state.organisation,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }

    submitAddDataStructure = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this Data wizard?")) {
            return
        }
        let _this = this;
        const user = GetUserToken();

        let hub = {};
        let data = [];
        for (let i in this.state.dataStructure) {
            const row = this.state.dataStructure[i];
            //checked:checked,position:"",alias:""
            const o = {
                org: user.OrgCode,
                Project: this.state.newStepProject.Project,
                AssetCategory: this.state.newStepProject.Category,
                SurveyName: this.state.newStepProject.Survey,
                Attribute: i,
                Source: row.from,
                Checked: row.checked,
                Position: row.position,
                Alias: row.alias
            };

            data.push(o);
        }
        hub.Org = user.OrgCode;
        hub.Project = this.state.newStepProject.Project;
        hub.AssetCategory = this.state.newStepProject.Category;
        hub.SurveyName = this.state.newStepProject.Survey;
        hub.Data = data;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/extraction/add-bulk";

        console.log("submitReport send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitReport response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset data structure submitted!");
                _this.fetchDataStructure();
                _this.setState({currentStep:2});
                //_this.setState(_this.innitialState)
            }
        });

    }

    getSelectedClass = (step) => {
        if (this.state.currentStep === step) {
            return "btn  btn-arrow-down"
        }
        return "btn btn-default btn-circle"
    }
    goNextStep = () => {
        let step = parseInt(this.state.currentStep);
        if (step >= this.state.numberStep) {
            //out of range number of step
            return null
        }
        step++;
        let tmp = this.state;
        tmp.currentStep = step;
        this.setState(tmp);
    }
    goBackStep = () => {
        let step = parseInt(this.state.currentStep);
        if (step <= 1) {
            //out of range number of step
            return null
        }
        step--;
        let tmp = this.state;
        tmp.currentStep = step;
        this.setState(tmp);
    }
    changeStep = (elname) => {
        let arr = elname.split("-");
        console.log("Change Step> ", arr, (arr.length > 1));
        if (arr.length > 1) {
            let myStep = arr[1];
            let step = parseInt(myStep);
            let tmp = this.state;
            tmp.currentStep = step;
            this.setState(tmp);
            console.log("Change Step state is now > ", this.state.currentStep, myStep);
        }
    }

    saveStepProject = async (e) => {
        e.preventDefault();
        await this.fetchDataStructure();
    }
    saveStepAttribute=(e)=>{
        e.preventDefault();
        //todo do nothing for now
    }

    renderStepController = () => {
        let arr = this.state.stepHeader; // ["Project", "Asset", "Capture", "Attach", "Geo", "Info"];
        let x = 0;
        return arr.map((row, index) => {
            x++;
            let myPosition = (index + 1);
            return (
                <div className="stepwizard-step">
                    <a name={"linkstep-" + x} onClick={(e) => this.changeStep("linkstep-" + myPosition)} type="button"
                       className={this.getSelectedClass(myPosition)}><b>{myPosition}</b></a>
                    <p><b>{row}</b></p>
                </div>
            )
        })
    }
    renderStepNavigation = () => {
        return (
            <div className="row container">
                <div className="col-xs-12">
                    <hr/>
                    <a
                        onClick={() => this.goBackStep()}
                        className={"btn btn-primary pull-left"}
                        style={{backgroundColor: " #00b3ee"}}
                    > <span className={"fa fa-angle-double-left"}>&nbsp;</span> Back Step</a>
                    <button
                        type={"submit"}
                        onClick={() => this.goNextStep()}
                        className={"btn btn-primary pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Next Step<span className={"fa fa-angle-double-right"}>&nbsp;</span>
                    </button>
                </div>
            </div>
        )
    }
    renderAssetAttribute = () => {
        let _this = this;
        const data = this.state.newStepProject.Headers;
        let ls = [];
        for (let i in data) {
            const key = i;
            ls.push(key);
        }
        const onCheched = (e, item) => {
            const checked = e.target.checked;
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                state.dataStructure[item] = {item: item, from: "asset", checked: checked, position: "", alias: ""};
            }
            state.dataStructure[item] = {item: item, from: "asset", checked: checked, position: "", alias: ""};

            if(!checked){
                delete state.dataStructure[item];
            }
            _this.setState(state);
        }
        const isCheched = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return false;
            }
            return state.dataStructure[item].checked;
        }
        const getValuePosition = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return "";
            }
            return state.dataStructure[item].position;
        }
        const onChangePosition = (e, item) => {
            let state = _this.state;
            const val = e.target.value;
            if (typeof state.dataStructure[item] === "undefined") {
                return alert("Please check first before supply Position!")
            }
            let row = state.dataStructure[item];
            row.position = val;
            state.dataStructure[item] = row;
            _this.setState(state);
        }
        const getValueAlias = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return "";
            }
            return state.dataStructure[item].alias;
        }
        const onChangeAlias = (e, item) => {
            let state = _this.state;
            const val = e.target.value;
            if (typeof state.dataStructure[item] === "undefined") {
                return alert("Please check first before supply Alias!")
            }
            let row = state.dataStructure[item];
            row.alias = val;
            state.dataStructure[item] = row;
            _this.setState(state);
        }

        return (
            <table
                className={"table"}
                style={{
                    border: '10px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>
                <tr>
                    <th colSpan={4}>
                        <h1 className={"alert alert-info"}>Asset Attribute</h1>
                    </th>
                </tr>
                <tr>
                    <th>#</th>
                    <th>Attribute</th>
                    <th>Position</th>
                    <th>Alias</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((item, index) => {
                    return (
                        <tr key={"xkdkd" + index}>
                            <td style={{width: 30}}>
                                <input
                                    type={"checkbox"}
                                    className={"checkbox"}
                                    checked={isCheched(item)}
                                    onClick={(e) => onCheched(e, item)}
                                />
                            </td>
                            <td>{item}</td>
                            <td style={{width: 50}}>
                                <input
                                    className={"form-control"}
                                    onChange={(e) => onChangePosition(e, item)}
                                    value={getValuePosition(item)}
                                />
                            </td>
                            <td>
                                <input
                                    className={"form-control"}
                                    onChange={(e) => onChangeAlias(e, item)}
                                    value={getValueAlias(item)}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    renderQuestionAttribute = () => {
        let _this = this;
        const data = {}; // this.state.newStepProject.Headers;
        //todo let buitl our data from questions for the selected survery
        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            data[row.name] = "";
        }

        let ls = [];
        for (let i in data) {
            const key = i;
            ls.push(key);
        }
        const onCheched = (e, item) => {
            const checked = e.target.checked;
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                state.dataStructure[item] = {item: item, from: "survey", checked: checked, position: "", alias: ""};
            }
            state.dataStructure[item] = {item: item, from: "survey", checked: checked, position: "", alias: ""};

            if(!checked){
                delete state.dataStructure[item];
            }

            _this.setState(state);
        }
        const isCheched = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return false;
            }
            return state.dataStructure[item].checked;
        }
        const getValuePosition = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return "";
            }
            return state.dataStructure[item].position;
        }
        const onChangePosition = (e, item) => {
            let state = _this.state;
            const val = e.target.value;
            if (typeof state.dataStructure[item] === "undefined") {
                return alert("Please check first before supply Position!")
            }
            let row = state.dataStructure[item];
            row.position = val;
            state.dataStructure[item] = row;
            _this.setState(state);
        }
        const getValueAlias = (item) => {
            let state = _this.state;
            if (typeof state.dataStructure[item] === "undefined") {
                return "";
            }
            return state.dataStructure[item].alias;
        }
        const onChangeAlias = (e, item) => {
            let state = _this.state;
            const val = e.target.value;
            if (typeof state.dataStructure[item] === "undefined") {
                return alert("Please check first before supply Alias!")
            }
            let row = state.dataStructure[item];
            row.alias = val;
            state.dataStructure[item] = row;
            _this.setState(state);
        }

        return (
            <table
                className={"table"}
                style={{
                    border: '10px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>
                <tr>
                    <th colSpan={4}>
                        <h1 className={"alert alert-info"}>Survey Question</h1>
                    </th>
                </tr>
                <tr>
                    <th>#</th>
                    <th>Attribute</th>
                    <th>Position</th>
                    <th>Alias</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((item, index) => {
                    return (
                        <tr key={"xkdkd" + index}>
                            <td style={{width: 30}}>
                                <input
                                    type={"checkbox"}
                                    className={"checkbox"}
                                    checked={isCheched(item)}
                                    onClick={(e) => onCheched(e, item)}
                                />
                            </td>
                            <td>{item}</td>
                            <td style={{width: 50}}>
                                <input
                                    className={"form-control"}
                                    onChange={(e) => onChangePosition(e, item)}
                                    value={getValuePosition(item)}
                                />
                            </td>
                            <td>
                                <input
                                    className={"form-control"}
                                    onChange={(e) => onChangeAlias(e, item)}
                                    value={getValueAlias(item)}
                                />
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    renderProjectBox = () => {
        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newStepProject = state.newStepProject;
            newStepProject.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newStepProject.Id === index) {
                    newStepProject.Project = row.Project;
                    newStepProject.Category = row.Category;
                    newStepProject.Headers = row.Data;
                }
                index++;
            }
            state.newStepProject = newStepProject;
            _this.setState(state);

            if (newStepProject.Project !== "" && newStepProject.Category !== "" && newStepProject.Survey!=="") {
                _this.fetchDataStructure();
            }

        }
        const getValueProject = () => {
            return _this.state.newStepProject.Id
        }

        const setValueSurvey = (e) => {
            const val = e.target.value;
            const state = _this.state;
            let newStepProject = state.newStepProject;
            newStepProject.Survey = val;
            state.newStepProject = newStepProject;
            _this.setState(state);
            _this.fetchQuestion();
            if (newStepProject.Project !== "" && newStepProject.Category !== "" && newStepProject.Survey!=="") {
                _this.fetchDataStructure();
            }

        }

        const getValueSurvey = () => {
            return _this.state.newStepProject.Survey
        }

        return (
            <div className="row container">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">Project & Category</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValueProject(e)}
                        value={getValueProject()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {this.state.StoreProjects.map((row, index) => {
                            let key = row.Id;
                            let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                            return (
                                <option key={index} value={index}>{desc}</option>
                            )
                        })}

                    </select>
                </div>

                <div className="form-row">
                    <div className="form-group col-md-16">
                        <label htmlFor="inputState">Survey Question</label>
                        <select
                            id="inputState"
                            className="form-control"
                            onChange={(e) => setValueSurvey(e)}
                            value={getValueSurvey()}
                            required={true}
                        >
                            <option value="" selected>Choose...</option>
                            {this.state.Surveys.map((row, index) => {
                                let key = row.name;
                                let desc = row.name.toLocaleUpperCase();
                                return (
                                    <option key={index} value={key}>{desc}</option>
                                )
                            })}

                        </select>
                    </div>
                </div>
            </div>
        )
    }

    renderStepProject = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 1) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Select Project & Survey
                </p>

                <form onSubmit={this.saveStepProject}>


                    {this.renderProjectBox()}

                    {this.renderStepNavigation()}

                </form>

            </div>
        )
    }

    reviewFilterDisplay=(source,myDisplay)=>{
        let ls = [];
        const state = this.state.dataStructure;
        for(let i in state){
            let row = state[i];
            row.item = i;
            if(row.from ===source){
                ls.push(row);
            }
        }

        return (
            <table
                className={"table"}
                style={{
                    border: '3px solid gray',
                    borderRightColor: 'gray',
                }}
            >
                <thead>
                <tr>
                    <th colSpan={4}>
                        <p className={"alert alert-info"}>{myDisplay}</p>
                    </th>
                </tr>
                <tr>
                    <th>#</th>
                    <th>Attribute</th>
                    <th>Position</th>
                    <th>Alias</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={"xkdkd" + index}>
                            <td style={{width: 30}}>
                                <input
                                    type={"checkbox"}
                                    className={"checkbox"}
                                    checked={true}
                                    readOnly={true}
                                />
                            </td>
                            <td>{row.item}</td>
                            <td style={{width: 50,textAlign:"center"}}>
                                <span>{row.position}</span>
                            </td>
                            <td >
                                <span>{row.alias}</span>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    renderStepAttribute = () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 2) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Choose Asset attribute & Survey question
                </p>

                <form onSubmit={this.saveStepAttribute}>


                    <div className="row container">
                        <div className="col-xs-6">
                            {this.renderAssetAttribute()}
                        </div>
                        <div className="col-xs-6">
                            {this.renderQuestionAttribute()}
                        </div>
                    </div>

                    {this.renderStepNavigation()}

                </form>

            </div>
        )
    }

    renderStepReview= () => {
        let step = parseInt(this.state.currentStep);
        if (step !== 3) {
            return
        }

        return (
            <div>
                <p
                    className="alert alert-danger"
                    style={{textAlign: 'center', fontSize: 24}}
                >
                    Review your selected Asset attribute & Survey question
                </p>


                    <h2>
                        &nbsp;
                        <a
                            style={{marginRight: 10}}
                            className={"btn btn-success pull-right"}
                            onClick={(e) => this.submitAddDataStructure(e)}
                        >Save Structure</a>

                    </h2>
                    <div className="row container">
                        <div className="col-xs-6">
                            {this.reviewFilterDisplay("asset","Selected Asset Attribute")}
                        </div>
                        <div className="col-xs-6">
                            {this.reviewFilterDisplay("survey","Selected Survey Question")}
                        </div>
                    </div>




            </div>
        )
    }

    render() {



        return (
            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Asset & Survey Data Extraction Wizard

                            </h2>
                        </div>
                    </div>
                </div>

                <div className="row container">
                    <div className="col col-lg-12 col-xs-12">
                        <div className="stepwizard col-md-offset-3">
                            <div className="stepwizard-row setup-panel">
                                {this.renderStepController()}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row container">
                    <div className="col-xs-12">
                        {this.renderStepProject()}
                        {this.renderStepAttribute()}
                        {this.renderStepReview()}
                    </div>
                </div>






            </PageContentWallpaper>
        )
    }
}
