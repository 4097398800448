import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHome,
    faArrowCircleLeft,
    faArrowCircleRight,
    faCalendarCheck,
    faCalendarTimes,
    faPlus
} from "@fortawesome/free-solid-svg-icons";

import {ProgressBar} from 'react-bootstrap';

export default class ComLibProcessQuestion extends Component {

    constructor(props) {
        super(props);
        this.state = {
            org: "easipath",
            survey: "Easicare Product",
            username: "biangacila@gmail.com",
            participant: "OB0647272",
            assetRef: "01 moltino road, claremont, cape town",
            /*Questions: [],*/
            Takens: [],
            currPosition: 0,
            singleAnswer: "",
            multipleAnswer: [],
            radioAnswer:{}
        }
    }

    saveQuestion = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to save this question?")) {
            return
        }

        //todo let find our current target
        let currentQuestion = null;
        for (let i in this.props.Questions) {

            if (parseInt(i) === parseInt(this.state.currPosition)) {
                currentQuestion = this.props.Questions[i];
            }
        }

        //todo let build answer
        let answered = {};


        //todo let build multiple answered
        let arrAnswered = [];
        let answeredRadio={};

        if(this.isQuestionHaveRadio()){

            const qText  = currentQuestion.name;
            let qAns = "";
            if(typeof this.state.radioAnswer[qText] !=="undefined"){
                qAns = this.state.radioAnswer[qText];
            }
            answeredRadio.QuestionCode = currentQuestion.code;
            answeredRadio.Name = qAns;
            answeredRadio.Score = currentQuestion.score;
            answeredRadio.Correct = "yes";
            answeredRadio.Position = 0;

        }else if (this.isQuestionHaveCheckList()) {
            for (let i in this.state.multipleAnswer) {
                const ans = this.state.multipleAnswer[i];
                arrAnswered.push({
                    QuestionCode: currentQuestion.code,
                    Name: ans,
                    Score: currentQuestion.score,
                    Correct: "yes",
                    Position: 0,
                });
            }
        } else {
            answered.QuestionCode = currentQuestion.code;
            answered.Name = this.state.singleAnswer;
            answered.Score = currentQuestion.score;
            answered.Correct = "yes";
            answered.Position = 0;
        }

        //todo let build taken
        const state = this.state;
        let taken = {};
        taken.Org = state.org;
        taken.Participant = this.props.participant;
        taken.Username = this.props.username;
        taken.IdentityRef = this.props.participant;
        taken.AssetRef = this.props.assetRef;
        taken.Survey = this.props.survey;
        taken.QuestionCode = currentQuestion.code;
        taken.QuestionInfo = currentQuestion;
        taken.Position = currentQuestion.position;
        taken.Category = currentQuestion.category;
        taken.SingleAnswer = answered;
        taken.MultipleAnswer = arrAnswered;
        taken.RadioAnswer = answeredRadio;
        taken.StartTime = "";
        taken.EndTime = "";
        taken.DurationMeasure = "";
        taken.DurationValue = 0;
        taken.Score = 0;
        taken.ScoreExpected = currentQuestion.score;

        let tmp = this.state;
        tmp.Takens.push(taken);
        this.setState(tmp);

        this.goToNextQuestion();
    }
    isQuestionHaveCheckList = () => {
        let question = null;
        let x = 0;
        for (let i in this.props.Questions) {
            const row = this.props.Questions[i];
            if (x === this.state.currPosition) {
                question = row;
            }
            x++;
        }
        if (question === null) {
            return false;
        }
        if (question.category === "option") {
            let num = 0;
            for (let i in question.multipleanswer) {
                const row = question.multipleanswer[i];
                if (row.correct === "yes") {
                    num++;
                }
            }
            if (num > 1) {
                return true;
            }
        }
        return false;
    }
    isQuestionHaveRadio = () => {
        let question = null;
        let x = 0;
        for (let i in this.props.Questions) {
            const row = this.props.Questions[i];
            if (x === this.state.currPosition) {
                question = row;
            }
            x++;
        }
        if (question === null) {
            return false;
        }
        if (question.category === "option") {
            let num = 0;
            for (let i in question.multipleanswer) {
                const row = question.multipleanswer[i];
                if (row.correct === "yes") {
                    num++;
                }
            }
            if (num > 1) {
                return true;
            }
        }
        return false;
    }
    getCurrentQuestionContent = () => {
        let question = null;
        let x = 0;
        for (let i in this.props.Questions) {
            const row = this.props.Questions[i];
            if (x === this.state.currPosition) {
                question = row;
            }
            x++;
        }
        if (question === null) {
            return (
                <div className={"alert alert-danger"}>
                    <p>Question not available!</p>
                </div>
            )
        }

        //let find pre answered
        let preAnswered = null;
        for (let i in this.state.Takens) {
            const taken = this.state.Takens[i];
            if (taken.questioncode === question.code) {
                preAnswered = taken.name;
            }
        }

        const qText = question.name;
        let qCategory = question.category || "text";
        let qLs = [];
        if (qCategory === "option") {
            let num = 0;

            for (let i in question.multipleanswer) {
                const row = question.multipleanswer[i];
                qLs.push({
                    key: row.name,
                    desc: row.name,
                    isCorrect: row.correct,
                });

                if (row.correct === "yes") {
                    num++;
                }
            }

            if (num > 1) {
                qCategory = "check";
            }
        }

        if (qCategory === "text") {
            return (
                <div className={"alert alert-info"}>
                    <div className="form-group">
                        <label>{qText}</label>

                        <input
                            className={"form-control"}
                            type={"text"}
                            value={this.getHandleAnswerSingle()}
                            onChange={(e) => this.handleAnswerSingle(e.target.value, "singleAnswer")}
                        />
                    </div>
                </div>
            )
        }

        if (qCategory === "check-") {
            return (
                <div className={"alert alert-info"}>
                    <p>{qText} </p>
                    <ul>
                        {qLs.map((row, index) => {
                            let checked = false;
                            if (row.key === this.state.singleAnswer) {
                                checked = true
                            }
                            return (
                                <li key={index}>
                                    <input
                                        name={row.key}
                                        type={"checkbox"}
                                        checked={this.getHandleAnswerMultiple(row.key)}
                                        onClick={(e) => this.handleAnswerMultiple(e, row.key)}
                                    />
                                    {row.desc}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
        return (
            <div className={"alert alert-info"}>
                <p>{qText}</p>
                <ul>
                    {qLs.map((row, index) => {
                        let checked = false;
                        if(typeof this.state.radioAnswer[qText] !=="undefined"){
                            const storeValue = this.state.radioAnswer[qText];
                            if(storeValue ===row.key ){
                                checked = true
                            }
                        }
                        return (
                            <li key={index}>
                                <input
                                    name="myOption"
                                    type={"radio"}
                                    checked={checked}
                                    onClick={() => this.handleAnswerSingleRadio(row.key, qText)}
                                />
                                {row.desc}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }

    canDisplayNext = () => {
        const curr = parseInt(this.state.currPosition) + 1;
        const total = this.props.Questions.length;
        if (curr >= total) {
            return false
        }

        if ((curr + 1) > total) {
            return false
        }
        return true;
    }
    canDisplayBack = () => {
        const curr = this.props.currPosition;
        if (curr === 0) {
            return false;
        }
        if ((curr - 1) < 0) {
            return false
        }
        return true;
    }
    getAnsweredQuestion = () => {
        return this.state.Takens.length;
    }
    getNoAnsweredQuestion = () => {
        return (this.props.Questions.length - this.state.Takens.length);
    }
    getPercentage=()=>{
        const ans =this.getAnsweredQuestion();
        const totalQuestion = this.props.Questions.length;
        if(ans ===0){
            return 0;
        }
        if(totalQuestion ===0){
            return 0;
        }

        let tot = (ans/totalQuestion)*100
        return tot.toFixed(0)
    }
    getHandleAnswerMultiple = (name) => {
        for (let i in this.state.multipleAnswer) {
            const o = this.state.multipleAnswer[i];
            if (o === name) {
                return true;
            }
        }
        return false;
    }
    getHandleAnswerSingle = () => {
        return this.state.singleAnswer;
    }
    handleAnswerSingle = (answer, key) => {
        let tmp = this.state;
        tmp[key] = answer;
        this.setState(tmp);
    }
    handleAnswerSingleRadio=(answer,qIn)=>{
        let tmp = this.state;
        tmp.radioAnswer[qIn] =answer;
        this.setState(tmp);
    }
    handleAnswerMultiple = (e, key) => {
        let tmp = this.state;
        const boo = e.target.checked;
        const o = key;
        let ls = [];
        for (let i in tmp.multipleAnswer) {
            const name = tmp.multipleAnswer[i];
            if (name !== o) {
                ls.push(name);
            }
        }
        if (boo) {
            ls.push(key);
        }
        tmp.multipleAnswer = ls;
        this.setState(tmp);
    }
    goToNextQuestion = () => {
        const currQ = parseInt(this.state.currPosition);
        let tmp = this.state;
        tmp.currPosition = currQ + 1;
        tmp.singleAnswer = this.syncAnswered();
        tmp.multipleAnswer = this.syncAnsweredMultiple();
        this.setState(tmp);
    }
    goToBackQuestion = () => {
        const currQ = parseInt(this.state.currPosition);

        let tmp = this.state;
        tmp.currPosition = currQ - 1;
        tmp.singleAnswer = this.syncAnswered();
        tmp.multipleAnswer = this.syncAnsweredMultiple();
        this.setState(tmp);
    }

    syncAnsweredMultiple=(inTakens)=>{

        if(!this.isQuestionHaveCheckList()){
            return [];
        }

        const currQ = parseInt(this.state.currPosition);
        let ans = [];
        let question = null;
        let taken = null;

        for (let i in this.props.Questions) {
            const row = this.props.Questions[i];
            if (parseInt(i) === parseInt(currQ)) {
                question = row;
            }
        }

        if (question === null) {
            return [];
        }

        let dataTaken = this.state.Takens;
        if(inTakens !==null && inTakens !==undefined){
            dataTaken = inTakens
        }


        for (let i in dataTaken) {
            let row = dataTaken[i];
            if (row.questioncode === question.code) {
                taken = row;
            }
        }

        if (taken === null) {
            return [];
        }

        for(let i in taken.multipleanswer){
            const row = taken.multipleanswer[i];
            ans.push(row.name)
        }

        return ans;
    }
    syncAnswered = (inTakens) => {
        const currQ = parseInt(this.state.currPosition);
        let ans = null;
        let question = null;
        let taken = null;

        for (let i in this.props.Questions) {
            const row = this.props.Questions[i];
            if (parseInt(i) === parseInt(currQ)) {
                question = row;
            }
        }

        if (question === null) {
            return "";
        }

        let dataTaken = this.state.Takens;
        if(inTakens !==null && inTakens !==undefined){
            dataTaken = inTakens
        }

        for (let i in dataTaken) {
            let row = dataTaken[i];
            if (row.questioncode === question.code) {
                taken = row;
            }
        }

        if (taken === null) {
            return "";
        }

        return taken.singleanswer.name;
    }

    canDisplayFinishButton=()=>{

        //todo make your logic of checking all the required question before return true

        let now = this.getPercentage();
        if(parseInt(now) !==100){
            return false;
        }

        return true
    }

    render() {

        let currentCount = parseInt(this.state.currPosition) + 1;
        let totalCounter = this.props.Questions.length;

        const now = this.getPercentage();
        return (
            <div className="">
                {/*question position bar e.g. 1/12 */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        <h3 style={{textAlign: "center", color: "gray"}}>

                            <span className="pull-left" style={styles.infoIconY}>
                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                :{this.getAnsweredQuestion()}
                            </span>
                            Question {currentCount} of {totalCounter}
                            <span className="pull-right" style={styles.infoIconX}>
                                 <FontAwesomeIcon icon={faCalendarTimes}/>
                                :{this.getNoAnsweredQuestion()}
                            </span>
                        </h3>
                    </div>
                </div>

                {/*question content */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        {this.getCurrentQuestionContent()}

                        {this.canDisplayFinishButton()?
                            <button
                                className={"btn btn-success pull-right"}
                                onClick={()=>{
                                    this.props.setParentState("newStepCapture",this.state.Takens);
                                }}
                                style={{marginLeft:10,paddingRight:10}}
                            >
                                Finish & Exit
                            </button>:
                            <button
                                className={"btn btn-default pull-right"}
                                onClick={(e) => {
                                    this.saveQuestion(e)
                                }}
                            >Save & Next
                            </button>
                        }

                    </div>
                </div>

                {/*question navigation bar */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>

                        {this.canDisplayBack() && (
                            <a onClick={() => this.goToBackQuestion()} className={"pull-left"}
                               style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} style={styles.navIcon}/>
                            </a>
                        )}


                        {this.canDisplayNext() && (
                            <a onClick={() => this.goToNextQuestion()} className={"pull-right"}
                               style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faArrowCircleRight} style={styles.navIcon}/>
                            </a>
                        )}


                    </div>
                </div>

                {/*question progress bar */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>


                        <ProgressBar striped variant="success" now={now}  label={`${now}%`}/>

                    </div>
                </div>

                {/*<div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        <button
                            className={"btn btn-default pull-right"}
                            onClick={()=>{
                                this.props.setParentState("newStepCapture",this.state.Takens);
                            }}
                        >
                            Finish & Exit
                        </button>
                    </div>
                </div>*/}
            </div>
        )
    }

}

const styles = {
    infoIconX: {
        fontWeight: "bold",
        fontSize: 16,
        color: "red"
    },
    infoIconY: {
        fontWeight: "bold",
        fontSize: 16,
        color: "green"
    },
    navIcon: {
        color: "rgb(212,83,191)",// "#AED6F1",
        fontWeight: "bold",
        fontSize: 18
    }
}