import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class SettingClientComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "name",
                desc: "Name",
                placeholder: "name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {
                field: "description",
                desc: "Description",
                placeholder: "Description",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {
                field:"StartDate",
                desc:"Start Date",
                placeholder:"Start Date",
                required:true,
                type:"text",
                readonly:false,
                staticValue:""
            },
            {
                field: "EndDate",
                desc: "EndDate",
                placeholder: "EndDate",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            },
            {
                field: "NumQuestion",
                desc: "NumQuestion",
                placeholder: "NumQuestion",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            {
                field: "TotalScore",
                desc: "TotalScore",
                placeholder: "TotalScore",
                required: true,
                type: "float",
                readonly: false,
                staticValue:""
            },
            {
                field: "Participant",
                desc: "Participant",
                placeholder: "Participant",
                required: false,
                type: "float",
                readonly: false,
                staticValue:""
            },



        ];
        /*
        {field:"department",desc:"Department",placeholder:"Department",required:true,type:"text",readonly:false,
                filter:true,filterTable:"department",filterKey:"name",filterValue:"description"},
         */
        this.ModelName = "survey";
        this.Crum = "/crum";
        this.PrimaryKey = "org"
    }

    /*



	NumberFailed float,
	NumberSuccess float,
     */



    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2> Name Survey</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name", "StartDate","EndDate","NumQuestion"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "name", type: "string"},
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}