import React from "react";
import addingModel from "../../reducers/reducer/reducer-adding-model";
import {connect} from "react-redux";
import {PostToBackend} from "../../api/services";
import eConfig from '../../config';

class AddingModelMap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            addingModel: {
                List: [],
                Current: null,
            },
            ListRequired: {},
            ListOthers: {},
            newEntry: null,
            QueryValue: null,
            mapInfo: null
        }

    }

    async componentDidMount() {
         this.loadRequiredMap();

        const nextProps = {...this.props};

         await this.loadRequireMapFromDb(nextProps);
        this.publishToParent();
        console.log("componentDidMount AddingModelMap > ",nextProps,this.props.mapInfoDb);
    }

    async componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps--> ", nextProps);
        this.loadRequiredMap();
        await this.loadRequireMapFromDb(nextProps);
        this.checkToLoadPrevDat()
    }

    loadRequireMapFromDb=async (nextProps)=>{
        const _this = this;
        console.log("loadRequireMapFromDb F > ", 1,this.props.mapInfoDb);
        if (this.props.mapInfoDb === null) {
            return
        }
        console.log("loadRequireMapFromDb F > ", 2);
        if (this.props.mapInfoDb === undefined) {
            return
        }
        console.log("loadRequireMapFromDb F > ", 3,nextProps);
        if (typeof this.props.mapInfoDb === "undefined") {
            return
        }

        const info = this.props.mapInfoDb;
        const state = this.state;
        const entryData = nextProps.state.EntryData;

        console.log("loadRequireMapFromDb F > ", 4,info);

        //todo let get our condition field //EntryData
        /*
         staticMapDb:{
                    dbName:"ams",
                    tableName:"AssetRequiredField",
                    targetField:"FieldName",
                    condintionDbField:"category",
                    condintionFieldFrom:"category",
                }
         */
        let conField,conValue="";
         conField =info.condintionFieldFrom;

        //TODO let end our process due to undefined field yet
        console.log("loadRequireMapFromDb ERROR 1 > ", nextProps.state.EntryData[conField],conField,nextProps.state.EntryData);

        if(typeof nextProps.state.EntryData[conField] ==="undefined" ){
                       return
         }


         conValue = nextProps.state.EntryData[conField] ;

        //TODO let end our process du to same value provided

        console.log("loadRequireMapFromDb F > 5 > ", (conValue ===state.QueryValue),conValue,state.QueryValue);

         if(conValue ===state.QueryValue){
             return
         }

        console.log("loadRequireMapFromDb F > ", 6);

         /*
         ==== New Request crum
          */

         let conds=[];
         conds.push({
             key:"org",
             val:nextProps.state.OrgCode,
             type:"string"
         });

        conds.push({
            key:conField,
            val:conValue,
            type:"string"
        });

         let hub={
             Fields:[],
             DbName: info.dbName ,
             TableName: info.tableName,
             Conditions: conds,
         }
         //  /backend-ecare/api/global-service

        let backend =eConfig.mainBackend ; // "backend-timeattendance";
        let  endpoint="/api/global-service/list";


        await   PostToBackend(hub,backend, endpoint, function (dataIn, err) {


            if(typeof dataIn.RESULT !=="undefined"){
                const data = dataIn.RESULT;

                //TODO sort asc one object
                if(typeof info.SortAsc !=="undefined"){
                    const o = info.SortAsc;
                    if(o.two ===""){//only one key supply
                        const keyOne = o.one;
                        data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
                    }else{// two properties supply
                        const keyOne = o.one;
                        const keyTwo = o.two;
                        data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : (a[keyOne] === b[keyOne]) ? ((a[keyTwo] > b[keyTwo]) ? 1 : -1) : -1 )
                    }
                }

                //TODO sort Desc one object
                if(typeof info.SortDesc !=="undefined"){
                    const o = info.SortDesc;
                    if(o.two ===""){//only one key supply
                        const keyOne = o.one;
                        data.sort((a, b) => (a[keyOne] < b[keyOne]) ? 1 : -1)
                    }else{// two properties supply
                        const keyOne = o.one;
                        const keyTwo = o.two;
                        data.sort((a, b) => (a[keyOne] < b[keyOne]) ? 1 : (a[keyOne] === b[keyOne]) ? ((a[keyTwo] < b[keyTwo]) ? 1 : -1) : -1 )
                    }
                }



                const targetField = info.targetField;

                let outData  ={};
                let outDataArray=[];
                for(let i in data){
                    const row = data[i];
                    const field = row[targetField];
                    let val =  "";
                    if (typeof _this.state.ListRequired[field] !== "undefined") {
                        val =_this.state.ListRequired[field]
                    }
                    if (typeof _this.state.ListOthers[field] !== "undefined") {
                        val =_this.state.ListOthers[field]
                    }
                    outData[field]=val
                    outDataArray.push({
                        key:field,
                        val:val
                    })
                }

                let mapInfo=null;
                if(outDataArray.length > 0){
                    mapInfo = outData
                }

             //todo let clean our Other list
                console.log("loadRequireMapFromDb response > ", outData, _this.state.ListOthers);

                let others=_this.state.ListOthers;
                for(let i in outData){
                    delete others[i];
                }

                _this.setState({
                    ListRequired:outData,
                    ListOthers:others,
                    mapInfo:mapInfo,
                    QueryValue:conValue,
                });

            }
        });

    }

    loadRequiredMap = () => {
        if (this.state.mapInfo !== null) {
            return
        }
        if (this.props.mapInfo === null) {
            return
        }
        if (this.props.mapInfo === undefined) {
            return
        }
        if (typeof this.props.mapInfo === "undefined") {
            return
        }

        let ls = this.props.mapInfo;
        if(this.state.mapInfo !==null){
            ls = this.state.mapInfo;
        }
        let out = {};
        for (let i in ls) {
            const row = ls[i];
            const key = row.key;
            const val = row.val;
            out[key] = val;
        }
        this.setState({ListRequired: out});
    }

    checkToLoadPrevDat = () => {

        //TODO let load our requered
        if (this.state.mapInfo !== null) {
            return
        }

        if (this.props.mapInfo === null) {
            return
        }
        if (this.props.mapInfo === undefined) {
            return
        }
        if (typeof this.props.mapInfo === "undefined") {
            return
        }
        const ls = this.props.mapInfo;
        let out = {};
        for (let i in ls) {
            const row = ls[i];
            const key = row.key;
            const val = row.val;
            out[key] = val;
        }


        //TODO let find prevouis value
        if (this.props.addingModel === undefined) {
            return null
        }
        if (this.props.addingModel.Current === null) {
            return null
        }
        const field = this.props.field;
        const inData = this.props.addingModel.Current;
        let tmp = this.state;
        tmp.ListRequired = out;

        for (let i in inData) {
            const rows = inData[i];
            if (i === field) {
                for (let e in rows) {
                    const key = e;
                    const val = rows[e];
                    console.log("CheckToLoadPRevData ",key,tmp.ListRequired);
                    if (typeof tmp.ListRequired[key] !== "undefined") {
                        tmp.ListRequired[key] = val;
                    } else {
                        tmp.ListOthers[key] = val;
                    }

                }
            }
        }

        this.setState(tmp);
        //this.publishToParent();
    }

    handleRequired = (key, e) => {
        const val = e.target.value;
        let tmp = this.state;
        const oldTmp = tmp;
        tmp.ListRequired[key] = val;




        this.setState(tmp);
        this.publishToParent();

        console.log("handleRequired > ",key,val,oldTmp.ListRequired,this.state.ListRequired);
    }
    handleOthers = (key, e) => {
        const val = e.target.value;
        let tmp = this.state;
        tmp.ListOthers[key] = val;
        this.setState(tmp);
        this.publishToParent();
    }

    publishToParent = () => {
        // handleInputInner
        // field
        const field = this.props.field;
        let m = {...this.state.ListRequired, ...this.state.ListOthers};
        this.props.handleInputInner(field, m)

    }

    onDeleteRequired = (key) => {
        let tmp = this.state;
        delete tmp.ListRequired[key];
        this.setState(tmp);
    }

    getRequiredListValue=(key)=>{

        let val = "";
        if(typeof this.state.ListRequired[key] !=="undefined"){
            val=this.state.ListRequired[key];
        }

        console.log("getRequiredListValue > ",key,"["+val+"]");

        return val;

    }

    renderListRequired = () => {
        let ls = [];
        for (let i in this.state.ListRequired) {
            const key = i;
            const val = this.state.ListRequired[i];
            ls.push({key: key, val: val})
        }

        console.log("renderListRequired > ",ls,this.state.ListRequired);
        return ls.map((row, index) => {

            return (
                <form key={index} className="form-inline" style={{marginBottom: 10}}>
                    <label
                        htmlFor={row.key}
                        style={{width: "30%", minWidth: "35%"}}
                    >{row.key}:</label>
                    <input
                        type="text"
                        className="form-control"
                        id={"myId-" + row.key}
                        style={{width: "40%", minWidth: "40%"}}
                        value={this.getRequiredListValue(row.key)}
                        onChange={(e) => this.handleRequired(row.key, e)}
                    />

                    <button
                        style={{marginLeft: 8}}
                        type="button"
                        className="btn btn-default"
                        onClick={() => this.onDeleteRequired(row.key)}
                    >Delete
                    </button>
                </form>
            )
        })
    }
    renderListOthers = () => {
        let ls = [];
        for (let i in this.state.ListOthers) {
            const key = i;
            const val = this.state.ListOthers[i];
            ls.push({key: key, val: val})
        }
        return ls.map((row, index) => {

            return (
                <form key={index} className="form-inline" style={{marginBottom: 10}}>
                    <label
                        htmlFor={row.key}
                        style={{width: "30%", minWidth: "35%"}}
                    >{row.key}:</label>
                    <input
                        type="text"
                        className="form-control"
                        id={"myId-" + row.key}
                        style={{width: "45%", minWidth: "45%"}}
                        value={this.state.ListOthers[row.key]}
                        onChange={(e) => this.handleOthers(row.key, e)}
                        placeholder={row.key}
                    />

                    <button
                        style={{marginLeft: 8}}
                        type="button"
                        className="btn btn-default"
                        onClick={() => this.onDeleteRequired(row.key)}
                    >Delete
                    </button>
                </form>
            )
        })
    }

    onNewField = () => {
        let tmp = this.state;
        tmp.newEntry = {key: "", val: ""};
        this.setState(tmp);
    }
    handleNewField = (e, key) => {
        let tmp = this.state;
        tmp.newEntry[key] = e.target.value;
        this.setState(tmp);

    }

    addNewEntry = () => {
        let tmp = this.state;
        let o = tmp.newEntry;
        tmp.newEntry = null;
        tmp.ListOthers[o.key] = o.val;
        this.setState(tmp);
        this.publishToParent();
    }

    renderNewField = () => {
        if (this.state.newEntry === null) {
            return null
        }

        return (
            <form className="form-inline" style={{marginBottom: 10}}>

                <input
                    type="text"
                    className="form-control"
                    id={"myId-newEntry"}
                    style={{width: "40%", minWidth: "40%"}}
                    value={this.state.newEntry.key}
                    onChange={(e) => this.handleNewField(e, "key")}
                    placeholder={"Enter your FIELD Name"}
                />

                <input
                    type="text"
                    className="form-control"
                    id={"myId-newEntry"}
                    style={{width: "40%", minWidth: "40%"}}
                    value={this.state.newEntry.val}
                    onChange={(e) => this.handleNewField(e, "val")}
                    placeholder={"Enter your FIELD Value"}
                />


                <button
                    style={{marginLeft: 8}}
                    type="button"
                    className="btn btn-default"
                    onClick={() => this.addNewEntry()}
                >ADD
                </button>
            </form>
        )
    }

    render() {
        return (
            <div style={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <br/>
                <p className="alert alert-danger">Required Info</p>
                {this.renderListRequired()}
                <br/>
                <p className="alert alert-info">Other Info</p>
                {this.renderListOthers()}
                <br/>

                {this.state.newEntry === null && (
                    <button
                        type="button"
                        className="btn btn-primary pull-left"
                        onClick={() => this.onNewField()}
                    >Add Field</button>
                )}

                {this.state.newEntry !== null && this.renderNewField()}

                <hr/>
            </div>
        )
    }

}


const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    addingModel: state.addingModel
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddingModelMap)

//export default  AddingModelMap;