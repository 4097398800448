
import  React from 'react';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "../component/common/redux-dispatch-to-props";

export default function(ComposedComponent){
    class Authenitcate extends React.Component{
        constructor(props) {
            super(props);
        }
        componentWillMount(){
            this.checkIfProjectSelected();
        }


        checkIfProjectSelected=()=>{
            const state = this.props.info;
            console.log("*****> checkIfProjectSelected > ",state,this.props);
            if(state.Project ==="" || state.Project===null){
                alert("Error please select project");
                window.location.href = "#/home-project";
            }
        }

        render(){
            return(
                <ComposedComponent {...this.props} />
            )
        }
    }

    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Authenitcate);

    return  connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Authenitcate);
}


