import   {LOGGED_IN} from '../constant';
const initial ={
    Project:null,
    Category:null,
    Headers:null,
    CounterAsset:0,
    Company:null,
    Role:null,
}


const reduceInfo = (state = initial, action) => {
    let newState = state;
    const entry = action.payload;
    switch (action.type) {
        case "SET-INFO-PROJECT":
            newState.Project = entry.Project;
            return Object.assign({}, newState);
        case "SET-INFO-CATEGORY":
            newState.Category = entry.Category;
            return Object.assign({}, newState);
        case "SET-INFO-COMPANY":
            newState.Company = entry.Company;
            return Object.assign({}, newState);
        case "SET-INFO-ROLE":
            newState.Role = entry.Role;
        case "SET-INFO-PROCATHEAD":
            newState.Project = entry.Project;
            newState.Category = entry.Category;
            newState.Headers = entry.Headers;
            newState.CounterAsset = entry.Total;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceInfo;
