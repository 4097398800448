import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import AddingModel from '../common/adding-model';

export default class ComSettingAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.MyModel = [
            {
                field: "project",
                desc: "Project",
                placeholder: "Project",
                required: false,
                type: "text",
                readonly: false,
                filter: true,
                filterTable: "project",
                filterKey: "name",
                filterValue: "name",
                filterConditionOrgCode:"org"
            },{
                field: "category",
                desc: "Category",
                placeholder: "Category",
                required: false,
                type: "text",
                readonly: false,
                filter: true,
                filterTable: "AssetCategory",
                filterKey: "name",
                filterValue: "name",
                filterConditionOrgCode:"org"
            },
            {
                field: "name",
                desc: "Name",
                placeholder: "name",
                required: true,
                type: "text",
                readonly: false,
                staticValue:""
            }
        ];

        this.ModelName = "AssetSetting";
        this.Crum = "/crum";
        this.PrimaryKey = "org"
    }


    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Asset</h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">
                        <AddingModel
                            MyModel={this.MyModel}
                            ModelName={this.ModelName}
                            ListDisplayKey={["Name","Project"]}
                            ListDeleteKey={[
                                {field: this.PrimaryKey, type: "string"},
                                {field: "project", type: "string"},
                                {field: "name", type: "string"},
                            ]}
                            Crum={this.Crum}
                            PrimaryKey={this.PrimaryKey}
                        />
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}