import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faHome,
    faArrowCircleLeft,
    faArrowCircleRight,
    faCalendarCheck,
    faCalendarTimes,
    faPlus
} from "@fortawesome/free-solid-svg-icons";
//https://scotch.io/tutorials/using-font-awesome-5-with-react

import {ProgressBar} from 'react-bootstrap';
import eConfig from "../../config";
import {PostToBackend} from "../../api/services";

export default class ComSurveyProces extends Component {

    constructor(props) {
        super(props);
        this.state = {
            org: "easipath",
            survey: "Easicare Product",
            username: "biangacila@gmail.com",
            participant: "OB0647272",
            assetRef: "01 moltino road, claremont, cape town",
            Questions: [],
            Takens: [],
            currPosition: 0,
            singleAnswer: "",
            multipleAnswer: [],
        }
    }

    async componentDidMount() {
        await this.fetchQuestion();
        await this.fetchTakens();
    }

    fetchTakens = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.props.org,
                Type: "string"
            }, {
                Key: "survey",
                Val: this.props.survey,
                Type: "string"
            }, {
                Key: "participant",
                Val: this.props.participant,
                Type: "string"
            },
        ];
        await this.fetchingAll("TakenSurvey", conds, (data) => {
            console.log("process taken fetchTakens :> ", data);
            _this.setState({
                Takens: data
            });

            let tmp = _this.state;
            tmp.currPosition = _this.state.currPosition;
            tmp.singleAnswer = _this.syncAnswered(data);
            tmp.multipleAnswer = _this.syncAnsweredMultiple(data);
            _this.setState(tmp);
        });
    }
    fetchQuestion = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.props.org,
                Type: "string"
            }, {
                Key: "survey",
                Val: this.props.survey,
                Type: "string"
            },
        ];
        await this.fetchingAll("Question", conds, (data) => {
            console.log("process taken fetchAssets :> ", data);

            //let sort the data based on position
            const keyOne = "position";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)

            _this.setState({
                Questions: data
            });

            let tmp = _this.state;
            tmp.currPosition = _this.state.currPosition;
            tmp.singleAnswer = _this.syncAnswered();
            tmp.multipleAnswer = _this.syncAnsweredMultiple();
            _this.setState(tmp);

        });
    }
    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    /*
    backquestion: "2"
category: "option"
code: ""
date: "2019-06-27"
id: "643cb477-9eea-437c-b372-b76379560d40"
multipleanswer: Array []
name: "Gizer working"
nextquestion: "4"
org: "easipath"
orgdatetime: ""
position: 3
profile: null
score: 20
singleanswer: Object { correct: "", id: "", score: 0, … }
status: "active"
survey: "Easicare Product"
time: "18:50:52"
timeline: "yes"
timelinemeasure: "minute
timelinevalue: 0
*/
    saveQuestion = async () => {
        if (!window.confirm("Are you sure that you want to save this question?")) {
            return
        }

        //todo let find our current target
        let currentQuestion = null;
        for (let i in this.state.Questions) {
            console.log("---> ", i, "==", this.state.currPosition);
            if (parseInt(i) === parseInt(this.state.currPosition)) {
                currentQuestion = this.state.Questions[i];
            }
        }

        //todo let build answer
        let answered = {};


        //todo let build multiple answered
        let arrAnswered = [];
        if (this.isQuestionHaveCheckList()) {
            for (let i in this.state.multipleAnswer) {
                const ans = this.state.multipleAnswer[i];
                arrAnswered.push({
                    QuestionCode: currentQuestion.code,
                    Name: ans,
                    Score: currentQuestion.score,
                    Correct: "yes",
                    Position: 0,
                });
            }
        } else {
            answered.QuestionCode = currentQuestion.code;
            answered.Name = this.state.singleAnswer;
            answered.Score = currentQuestion.score;
            answered.Correct = "yes";
            answered.Position = 0;
        }

        //todo let build taken
        const state = this.state;
        let taken = {};
        taken.Org = state.org;
        taken.Participant = this.props.participant;
        taken.Username = this.props.username;
        taken.IdentityRef = this.props.participant;
        taken.AssetRef = this.props.assetRef;
        taken.Survey = this.props.survey;
        taken.QuestionCode = currentQuestion.code;
        taken.Position = currentQuestion.position;
        taken.Category = currentQuestion.category;
        taken.SingleAnswer = answered;
        taken.MultipleAnswer = arrAnswered;
        taken.StartTime = "";
        taken.EndTime = "";
        taken.DurationMeasure = "";
        taken.DurationValue = 0;
        taken.Score = 0;
        taken.ScoreExpected = currentQuestion.score;

        const hub = {...taken};

        let _this = this;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/taken/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit saveQuestion response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Question saved!");
                await _this.fetchTakens();
                await _this.fetchQuestion();
            }
        });


    }
    syncAnsweredMultiple=(inTakens)=>{

        if(!this.isQuestionHaveCheckList()){
            return [];
        }

        const currQ = parseInt(this.state.currPosition);
        let ans = [];
        let question = null;
        let taken = null;

        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            if (parseInt(i) === parseInt(currQ)) {
                question = row;
            }
        }

        if (question === null) {
            console.log("&&&&&--> empty question",)
            return [];
        }

        let dataTaken = this.state.Takens;
        if(inTakens !==null && inTakens !==undefined){
            dataTaken = inTakens
        }


        for (let i in dataTaken) {
            let row = dataTaken[i];
            if (row.questioncode === question.code) {
                taken = row;
            }
        }

        if (taken === null) {
            console.log("&&&&&--> empty taken",)
            return [];
        }

        for(let i in taken.multipleanswer){
            const row = taken.multipleanswer[i];
            ans.push(row.name)
        }

        return ans;
    }
    syncAnswered = (inTakens) => {
        const currQ = parseInt(this.state.currPosition);
        let ans = null;
        let question = null;
        let taken = null;

        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            if (parseInt(i) === parseInt(currQ)) {
                question = row;
            }
        }

        if (question === null) {
            console.log("&&&&&--> empty question",)
            return "";
        }

        let dataTaken = this.state.Takens;
        if(inTakens !==null && inTakens !==undefined){
            dataTaken = inTakens
        }

        for (let i in dataTaken) {
            let row = dataTaken[i];
            if (row.questioncode === question.code) {
                taken = row;
            }
        }

        if (taken === null) {
            console.log("&&&&&--> empty taken",)
            return "";
        }

        return taken.singleanswer.name;
    }
    goToNextQuestion = () => {
        const currQ = parseInt(this.state.currPosition);
        let tmp = this.state;
        tmp.currPosition = currQ + 1;
        tmp.singleAnswer = this.syncAnswered();
        tmp.multipleAnswer = this.syncAnsweredMultiple();
        this.setState(tmp);
    }
    goToBackQuestion = () => {
        const currQ = parseInt(this.state.currPosition);

        let tmp = this.state;
        tmp.currPosition = currQ - 1;
        tmp.singleAnswer = this.syncAnswered();
        tmp.multipleAnswer = this.syncAnsweredMultiple();
        this.setState(tmp);
    }
    handleAnswerSingle = (answer, key) => {
        console.log(":)--> ", answer, "--", key);
        let tmp = this.state;
        tmp[key] = answer;
        this.setState(tmp);
    }
    handleAnswerMultiple = (e, key) => {
        console.log(":)--> ", e.target.checked, "--", key);
        let tmp = this.state;
        const boo = e.target.checked;
        const o = key;
        let ls = [];
        for (let i in tmp.multipleAnswer) {
            const name = tmp.multipleAnswer[i];
            if (name !== o) {
                ls.push(name);
            }
        }
        if (boo) {
            ls.push(key);
        }
        tmp.multipleAnswer = ls;
        this.setState(tmp);
    }
    getHandleAnswerMultiple = (name) => {
        for (let i in this.state.multipleAnswer) {
            const o = this.state.multipleAnswer[i];
            if (o === name) {
                return true;
            }
        }
        return false;
    }
    getHandleAnswerSingle = () => {
        return this.state.singleAnswer;
    }
    isQuestionHaveCheckList = () => {
        let question = null;
        let x = 0;
        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            if (x === this.state.currPosition) {
                question = row;
            }
            x++;
        }
        if (question === null) {
            return false;
        }
        if (question.category === "option") {
            let num = 0;
            for (let i in question.multipleanswer) {
                const row = question.multipleanswer[i];
                if (row.correct === "yes") {
                    num++;
                }
            }
            if (num > 1) {
                return true;
            }
        }
        return false;
    }
    getCurrentQuestionContent = () => {
        let question = null;
        let x = 0;
        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            if (x === this.state.currPosition) {
                question = row;
            }
            x++;
        }
        if (question === null) {
            return (
                <div className={"alert alert-danger"}>
                    <p>Question not available!</p>
                </div>
            )
        }

        //let find pre answered
        let preAnswered = null;
        for (let i in this.state.Takens) {
            const taken = this.state.Takens[i];
            if (taken.questioncode === question.code) {
                preAnswered = taken.name;
            }
        }

        const qText = question.name;
        let qCategory = question.category || "text";
        let qLs = [];
        if (qCategory === "option") {
            let num = 0;

            for (let i in question.multipleanswer) {
                const row = question.multipleanswer[i];
                qLs.push({
                    key: row.name,
                    desc: row.name,
                    isCorrect: row.correct,
                });

                if (row.correct === "yes") {
                    num++;
                }
            }

            if (num > 1) {
                qCategory = "check";
            }
        }
        /*const ls = [
            {key: "DRC", desc: "DRC"},
            {key: "France", desc: "France"},
            {key: "SA", desc: "SA"},
            {key: "ZA", desc: "ZA"},
        ]
        getHandleAnswerSingle
        */
        if (qCategory === "text") {
            return (
                <div className={"alert alert-info"}>
                    <div className="form-group">
                        <label>{qText}</label>

                        <input
                            className={"form-control"}
                            type={"text"}
                            value={this.getHandleAnswerSingle()}
                            onChange={(e) => this.handleAnswerSingle(e.target.value, "singleAnswer")}
                        />
                    </div>
                </div>
            )
        }

        if (qCategory === "check") {
            return (
                <div className={"alert alert-info"}>
                    <p>{qText} </p>
                    <ul>
                        {qLs.map((row, index) => {
                            let checked = false;
                            console.log("^^^^^--> ", row.key, " == ", this.state.singleAnswer);
                            if (row.key === this.state.singleAnswer) {
                                checked = true
                            }
                            return (
                                <li key={index}>
                                    <input
                                        name={row.key}
                                        type={"checkbox"}
                                        checked={this.getHandleAnswerMultiple(row.key)}
                                        onClick={(e) => this.handleAnswerMultiple(e, row.key)}
                                    />
                                    {row.desc}
                                </li>
                            )
                        })}
                    </ul>
                </div>
            )
        }
        return (
            <div className={"alert alert-info"}>
                <p>{qText}</p>
                <ul>
                    {qLs.map((row, index) => {
                        let checked = false;
                        //console.log("^^^^^--> ",row.key," == ",this.state.singleAnswer);
                        if (row.key === this.state.singleAnswer) {
                            checked = true
                        }
                        /*if(preAnswered !==null){
                            if(row.name ===preAnswered){
                                checked = true
                            }
                        }*/
                        return (
                            <li key={index}>
                                <input
                                    name="myOption"
                                    type={"radio"}
                                    checked={checked}
                                    onClick={() => this.handleAnswerSingle(row.key, "singleAnswer")}
                                />
                                {row.desc}
                            </li>
                        )
                    })}
                </ul>
            </div>
        )
    }
    canDisplayNext = () => {
        const curr = parseInt(this.state.currPosition) + 1;
        const total = this.state.Questions.length;
        if (curr >= total) {
            return false
        }

        if ((curr + 1) > total) {
            return false
        }
        return true;
    }
    canDisplayBack = () => {
        const curr = this.state.currPosition;
        if (curr === 0) {
            return false;
        }
        if ((curr - 1) < 0) {
            return false
        }
        return true;
    }
    getAnsweredQuestion = () => {
        return this.state.Takens.length;
    }
    getNoAnsweredQuestion = () => {
        return (this.state.Questions.length - this.state.Takens.length);
    }
    getPercentage=()=>{
        const ans =this.getAnsweredQuestion();
        const totalQuestion = this.state.Questions.length;
        if(ans ===0){
            return 0;
        }
        if(totalQuestion ===0){
            return 0;
        }

        let tot = (ans/totalQuestion)*100
        return tot.toFixed(0)
    }
    render() {

        let currentCount = parseInt(this.state.currPosition) + 1;
        let totalCounter = this.state.Questions.length;

        const now = this.getPercentage();
        return (
            <div className="">
                {/*question position bar e.g. 1/12 */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        <h3 style={{textAlign: "center", color: "gray"}}>

                            <span className="pull-left" style={styles.infoIconY}>
                                <FontAwesomeIcon icon={faCalendarCheck}/>
                                :{this.getAnsweredQuestion()}
                            </span>
                            Question {currentCount} of {totalCounter}
                            <span className="pull-right" style={styles.infoIconX}>
                                 <FontAwesomeIcon icon={faCalendarTimes}/>
                                :{this.getNoAnsweredQuestion()}
                            </span>
                        </h3>
                    </div>
                </div>

                {/*question content */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        {this.getCurrentQuestionContent()}
                        <button
                            className={"btn btn-success pull-right"}
                            onClick={() => {
                                this.saveQuestion()
                            }}
                        >Save
                        </button>
                    </div>
                </div>

                {/*question navigation bar */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>

                        {this.canDisplayBack() && (
                            <a onClick={() => this.goToBackQuestion()} className={"pull-left"}
                               style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faArrowCircleLeft} style={styles.navIcon}/>
                            </a>
                        )}


                        {this.canDisplayNext() && (
                            <a onClick={() => this.goToNextQuestion()} className={"pull-right"}
                               style={{cursor: "pointer"}}>
                                <FontAwesomeIcon icon={faArrowCircleRight} style={styles.navIcon}/>
                            </a>
                        )}


                    </div>
                </div>

                {/*question progress bar */}
                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>


                        <ProgressBar striped variant="success" now={now} label={`${now}%`}/>;

                    </div>
                </div>

                <div className={"row"}>
                    <div className="col col-lg-12">
                        <hr/>
                        <button
                            className={"btn btn-danger pull-right"}
                            onClick={()=>{
                                this.props.setParentState("inProcess",false);
                                this.props.setParentState("survey","");
                                this.props.setParentState("participant","");
                                this.props.setParentState("assetRef","");
                            }}
                        >
                            Finish & Exit
                        </button>
                    </div>
                </div>
            </div>
        )
    }
}

const styles = {
    infoIconX: {
        fontWeight: "bold",
        fontSize: 16,
        color: "red"
    },
    infoIconY: {
        fontWeight: "bold",
        fontSize: 16,
        color: "green"
    },
    navIcon: {
        color: "rgb(212,83,191)",// "#AED6F1",
        fontWeight: "bold",
        fontSize: 18
    }
}