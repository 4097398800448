import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";
import {Tabs, Tab} from 'material-ui/Tabs';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {ColumnChart, LineChart, PieChart} from 'react-chartkick';
import 'chart.js';
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class ComAssetEnumeratorAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            newEntry: {
                TargetSurveyPerEnumerators: 0,
                TargetSurveyPerDataCapture: 0,
                NumberOfEnumerator: 0,
                NumberOfDataCapture: 0,
                RateEnumeratorPerSurvey: 0,
                RateDataCapturePerSurvey: 0,
                AttributeLeft: "",
                AttributeRight: "",
                StartDate: "",
                startDate: new Date(),
            },
            dashboard: null,
            showFilter: true,
            isLoading: false,
        }

    }

    async componentDidMount() {

        await this.fetchProjectDashboard();
    }

    fetchProjectDashboard = async () => {
        let _this = this;
        let info = this.props.info;
        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            }, {
                Key: "project",
                Val: info.Project,
                Type: "string"
            }, {
                Key: "AssetCategory",
                Val: info.Category,
                Type: "string"
            },
        ];
        await this.fetchingAll("ProjectDashboard", conds, (data) => {
            console.log("fetchProjectDashboard :> ", data);
            _this.setState({
                Data: data
            });
            if (data.length > 0) {
                const getKeyValue = (key, row) => {
                    key = key.toLowerCase();
                    for (let i in row) {
                        const k = i.toLowerCase();
                        const val = row[i];
                        console.log("*****&---> ", key, "===", k, " > ", (key === k));
                        if (key === k) {
                            return val
                        }
                    }
                    return null
                }
                const row = data[0];
                let tmp = _this.state;
                tmp.newEntry = {
                    TargetSurveyPerEnumerators: getKeyValue("TargetSurveyPerEnumerators", row),
                    TargetSurveyPerDataCapture: getKeyValue("TargetSurveyPerDataCapture", row),
                    NumberOfEnumerator: getKeyValue("NumberOfEnumerator", row),
                    NumberOfDataCapture: getKeyValue("NumberOfDataCapture", row),
                    RateEnumeratorPerSurvey: getKeyValue("RateEnumeratorPerSurvey", row),
                    RateDataCapturePerSurvey: getKeyValue("RateDataCapturePerSurvey", row),
                    AttributeLeft: getKeyValue("AttributeLeft", row),
                    AttributeRight: getKeyValue("AttributeRight", row),
                    StartDate: "",
                    startDate: new Date(),
                };

                _this.setState(tmp);

            }

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    submitSearch = async (e) => {
        e.preventDefault();
        this.setState({isLoading: true, dashboard: null});
        let _this = this;
        let attributes = [];
        attributes.push(this.state.newEntry.AttributeLeft);
        attributes.push(this.state.newEntry.AttributeRight);

        let hub = {
            ...this.state.newEntry,
            Org: GetUserToken().OrgCode,
            Project: this.props.info.Project,
            AssetCategory: this.props.info.Category,
            Attributes: attributes
        }
        hub.StartDate = this.convertDateFormat_YYYYMMDD( hub.startDate);
        delete hub.startDate;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/dashboard/overview";

        console.log("submitSearch send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitSearch response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    dashboard: data,
                    isLoading: false,
                    showFilter: false,
                });
            }
        });
    }
    convertDateFormat_YYYYMMDD = (datesIn) => {
        let myDate = moment(datesIn).format("YYYY-MM-DD");
        return myDate;
    }
    getValue = (key) => {
        return this.state.newEntry[key]
    }
    setValue = (e, key) => {
        let val = "";
        if (typeof e.target !== "undefined") {
            val = e.target.value;
        }
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    handleChangeStartDate = (date) => {
        let tmp = this.state;
        tmp.newEntry.startDate = date
        this.setState(tmp);
    }

    renderFormFilter = () => {

        if (!this.state.showFilter) {
            return null
        }
        return (
            <form onSubmit={this.submitSearch}>
                <div className="row container ">
                    <div className="col-xs-12 col-md-6 col-lg-3 alert alert-info ">


                        <div className="form-group row container">
                            <label>Survey Per Enumerator</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("TargetSurveyPerEnumerators")}
                                onChange={(e) => this.setValue(e, "TargetSurveyPerEnumerators")}
                            />
                        </div>

                        <div className="form-group row container">
                            <label>Survey Per Data Capturer</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("TargetSurveyPerDataCapture")}
                                onChange={(e) => this.setValue(e, "TargetSurveyPerDataCapture")}
                            />
                        </div>

                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-3 alert alert-info">
                        <div className="form-group row container">
                            <label>NumberOfEnumerator</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("NumberOfEnumerator")}
                                onChange={(e) => this.setValue(e, "NumberOfEnumerator")}
                            />
                        </div>
                        <div className="form-group row container">
                            <label>NumberOfDataCapture</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("NumberOfDataCapture")}
                                onChange={(e) => this.setValue(e, "NumberOfDataCapture")}
                            />
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-6 col-lg-3 alert alert-info">
                        <div className="form-group row container">
                            <label>RateEnumeratorPerSurvey</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("RateEnumeratorPerSurvey")}
                                onChange={(e) => this.setValue(e, "RateEnumeratorPerSurvey")}
                            />
                        </div>
                        <div className="form-group row container">
                            <label>RateDataCapturePerSurvey</label>
                            <input
                                type="text"
                                className="form-control"
                                value={this.getValue("RateDataCapturePerSurvey")}
                                onChange={(e) => this.setValue(e, "RateDataCapturePerSurvey")}
                            />
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-6 col-lg-3 alert alert-info">
                        <div className="form-group row container">
                            <label>StartDate</label>
                            {/*<input
                                type="date"
                                className="form-control"
                                placeholder={"YYYY-MM-DD"}
                                value={this.getValue("StartDate")}
                                onChange={(e) => this.setValue(e, "StartDate")}
                            />*/}
                            <DatePicker
                                selected={this.state.newEntry.startDate}
                                onChange={this.handleChangeStartDate}
                                className="form-control"
                            />
                        </div>
                        <div className="form-group row container">
                            <label>&nbsp;</label>
                            <button
                                type="submit"
                                className="form-control btn btn-primary"
                            >Search
                            </button>
                        </div>
                    </div>

                </div>
            </form>
        )
    }
    renderCardPrimary = (headerText, bodyText, footerText) => {
        return (
            <div className="card">
                <div className="card-header" style={{background: "#808080", color: "white", textAlign: "center"}}>
                    {headerText}
                </div>
                <div className="card-body">
                    <h1 className="card-title" style={{textAlign: "center", color: "#808080"}}>
                        {bodyText}
                    </h1>
                </div>
                {footerText !== "" ?
                    <div className="card-footer" style={{background: "#808080", color: "white", textAlign: "center"}}>
                        {footerText}
                    </div> :
                    null
                }
            </div>
        )
    }
    renderCardPrimaryWithoutFooter = (headerText, bodyText, footerText) => {
        return (
            <div className="card">
                <div className="card-header" style={{background: "white", textAlign: "center", color: "#795548"}}>
                    {headerText}
                </div>
                <div className="card-body" style={{background: "#F48FB1"}}>
                    <h1 className="card-title" style={{textAlign: "center", color: "white"}}>
                        {bodyText}
                    </h1>
                </div>
            </div>
        )
    }
    renderCardSecondary = (headerText, bodyText, footerText) => {
        return (
            <div className="card">
                <div className="card-header" style={{background: "white", color: "#795548", textAlign: "center"}}>
                    {headerText}
                </div>
                <div className="card-body" style={{background: "#F48FB1"}}>
                    <h4 className="card-title" style={{color: "white", textAlign: "center"}}>
                        {bodyText}
                    </h4>
                </div>
                <div className="card-footer" style={{background: "white", color: "#808080", textAlign: "center"}}>
                    {footerText}
                </div>
            </div>
        )
    }
    renderDashboardBreakdownRight = (pos) => {
        if (this.state.dashboard === null) {
            return null
        }
        if (this.state.showFilter) {
            return null
        }
        const Breakdown = this.state.dashboard.Breakdown;
        const state = this.state;
        let keyRight = "";
        if (pos === "right") {
            keyRight = state.newEntry.AttributeRight.toLocaleLowerCase();
        } else {
            keyRight = state.newEntry.AttributeLeft.toLocaleLowerCase();
        }

        console.log("):(--> ", Breakdown, " > ", this.state.dashboard, " > ", keyRight, " > ", state.newEntry)
        let ls = [];
        if (typeof Breakdown[keyRight] !== "undefined") {
            const lines = Breakdown[keyRight].Lines;
            for (let i in lines) {
                const line = lines[i];
                ls.push({
                    field: line.Field,
                    asset: line.Asset,
                    survey: line.Survey,
                    costEnumerator: line.TotalCostEnumerator,
                    costCapture: line.TotalCostCapture,
                    daysEnumerator: line.TotalDaysEnumerator,
                    daysCapture: line.TotalDaysCapture,
                    totalCost: line.TotalCost,
                })
            }

        }

        const styleCenter = {textAlign: "right"};
        return (
            <div>
                <h3>Breakdown report by [{keyRight.toLocaleUpperCase()}]</h3>
                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th style={styleCenter}>Asset</th>
                        <th style={styleCenter}>Survey</th>
                        <th style={styleCenter}>Cost Enu.</th>
                        <th style={styleCenter}>Cost Capt.</th>
                        <th style={styleCenter}>Total Cost</th>
                        <th style={styleCenter}>Days Enu.</th>
                        <th style={styleCenter}>Days Capt.</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {
                        return (
                            <tr key={"kk" + index}>
                                <th>{row.field}</th>
                                <td style={styleCenter}>{row.asset}</td>
                                <td style={styleCenter}>{row.survey}</td>
                                <td style={styleCenter}>R{row.costEnumerator}</td>
                                <td style={styleCenter}>R{row.costCapture}</td>
                                <td style={styleCenter}>R{row.totalCost}</td>
                                <td style={styleCenter}>{row.daysEnumerator}</td>
                                <td style={styleCenter}>{row.daysCapture}</td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )


    }
    renderDashboardSummary = () => {

        if (this.state.dashboard === null) {
            return null
        }
        if (this.state.showFilter) {
            return null
        }
        const state = this.state;
        const result = this.state.dashboard;
        const Breakdown = this.state.dashboard.Breakdown;

        const keyRight = state.newEntry.AttributeRight.toLocaleLowerCase();
        const keyLeft = state.newEntry.AttributeLeft.toLocaleLowerCase();
        const linesRight = [];
        let linesLeft = [];
        for(let i in Breakdown[keyLeft].Lines){
            const row = Breakdown[keyLeft].Lines[i];
            linesLeft.push(row);
        }

        for(let i in Breakdown[keyRight].Lines){
            const row = Breakdown[keyLeft].Lines[i];
            linesRight.push(row);
        }



        return (
            <div>
                <div className="row container">
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Number of "+keyLeft,
                            linesLeft.length,
                            "City Wide"
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Number of Asset",
                            result.TotalDataAsset,
                            "City Wide"
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Number of Surveys",
                            result.TotalDataSurvey,
                            "City Wide"
                        )}
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12 col-lg-12">
                        <hr/>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12 col-lg-3">
                        {this.renderCardSecondary(
                            "Surveys Days",
                            result.TotalSurveyDaysEnumerator,
                            "Week Days Only"
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-3">
                        {this.renderCardSecondary(
                            "Survey Ends",
                            result.DateEndEnumerator,
                            "Excluding Weekends"
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-3">
                        {this.renderCardSecondary(
                            "Capture Days",
                            result.TotalSurveyDaysDataCapture,
                            "Week Days Only"
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-3">
                        {this.renderCardSecondary(
                            "Capture Ends",
                            result.DateEndCapture,
                            "Excluding Weekends"
                        )}
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12 col-lg-12">
                        <hr/>
                    </div>
                </div>

                <div className="row container">
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Cost Of Enumerators",
                            "R"+result.CostOfEnumerators,
                            ""
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Cost Of Capturers",
                            "R"+result.CostOfDataCapture,
                            ""
                        )}
                    </div>
                    <div className="col-xs-12 col-lg-4">
                        {this.renderCardPrimaryWithoutFooter(
                            "Total Cost",
                            "R"+result.TotalCost,
                            ""
                        )}
                    </div>
                </div>


            </div>
        )
    }
    renderChart1 = (pos) => {
        // https://chartkick.com/react

        /*
        <GeoChart data={[["United States", 44], ["Germany", 23], ["Brazil", 22], ...]} />

         */
        if (this.state.dashboard === null) {
            return null
        }
        if (this.state.showFilter) {
            return null
        }
        const Breakdown = this.state.dashboard.Breakdown;
        const state = this.state;
        let keyRight = "";
        if (pos === "right") {
            keyRight = state.newEntry.AttributeRight.toLocaleLowerCase();
        } else {
            keyRight = state.newEntry.AttributeLeft.toLocaleLowerCase();
        }

        let ls = [];
        if (typeof Breakdown[keyRight] !== "undefined") {
            const lines = Breakdown[keyRight].Lines;
            for (let i in lines) {
                const line = lines[i];
                ls.push({
                    field: line.Field,
                    asset: line.Asset,
                    survey: line.Survey,
                    costEnumerator: line.TotalCostEnumerator,
                    costCapture: line.TotalCostCapture,
                    daysEnumerator: line.TotalDaysEnumerator,
                    daysCapture: line.TotalDaysCapture,
                    totalCost: line.TotalCost,
                })
            }

        }

        const keyOne = "survey";
        ls.sort((a, b) => (a[keyOne] < b[keyOne]) ? 1 : -1)

        let arrParent = [];
        for (let i in ls) {
            const row = ls[i];
            arrParent.push([
                row.field,
                parseInt(row.survey)
            ])
        }

        // <ColumnChart data={[["Sun", 32], ["Mon", 46], ["Tue", 28]]} />
        return (
            <div>
                <h3>Number of [{keyRight}]</h3>
                <ColumnChart data={arrParent}/>
            </div>
        );
    }

    renderDashboardTabs = () => {

        if (this.state.dashboard === null) {
            return null
        }
        if (this.state.showFilter) {
            return null
        }

        const breakdownLabel1 = this.state.newEntry.AttributeRight.toLocaleUpperCase() || "Breakdown 1";
        const breakdownLabel2 = this.state.newEntry.AttributeLeft.toLocaleUpperCase() || "Breakdown 2";

        return (
            <div className={"row"}>
                <div className={"col col-lg-12 container"}>
                    <MuiThemeProvider>
                        <Tabs>
                            <Tab label="SUMMARY">
                                <br/>
                                {this.renderDashboardSummary()}
                            </Tab>
                            <Tab label={breakdownLabel1}>
                                {this.renderDashboardBreakdownRight("right")}
                            </Tab>
                            <Tab label={breakdownLabel2}>
                                {this.renderDashboardBreakdownRight("left")}
                            </Tab>
                            <Tab label={"Chart1"}>
                                <br/>
                                {this.renderChart1("left")}
                            </Tab>
                            <Tab label={"Chart2"}>
                                <br/>
                                {this.renderChart1("right")}
                            </Tab>
                            <Tab label="Chart 3">
                            </Tab>
                        </Tabs>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Project Overview Dashboard

                                {this.state.showFilter ?
                                    <a
                                        onClick={() => this.setState({showFilter: false})}
                                        className={"pull-right"}
                                        style={{color: "red", cursor: "pointer"}}
                                    >Hide Filter</a>
                                    :
                                    <a
                                        onClick={() => this.setState({showFilter: true})}
                                        className={"pull-right"}
                                        style={{color: "blue", cursor: "pointer"}}
                                    >Show Filter</a>

                                }

                            </h2>
                        </div>
                    </div>

                    {this.renderFormFilter()}

                    {this.state.isLoading ?
                        <div className="row container">
                            <div className="col-xs-12">
                                <p
                                    style={{color: "red", fontSize: 18, fontWeight: "bold"}}
                                >Busy processing your request please wait........</p>
                            </div>
                        </div>
                        :
                        null
                    }


                    {this.renderDashboardTabs()}


                </div>
            </PageContentWallpaper>
        )


    }

}