import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';

export default class ComDatabaseDataAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            DataBreakdown: [],
            Attributes: [],
            searchValue: "",
            filters: [],
            selectedAttribute: "",
            selectedValue:"",
            showBreakdown: false,
            showData: false,
        }

    }

    async componentDidMount() {
        await this.fetchAssetAttributes();
    }

    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchingAll response > ", table, dataIn);
            if (dataIn === null) {
                console.log("fetchingAll response is null :> ", dataIn, hub);
                dataIn = [];
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    fetchAssetAttributes = async () => {
        let _this = this;
        let project = this.props.info.Project;
        let category = this.props.info.Category;

        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },
            {
                Key: "project",
                Val: project,
                Type: "string"
            },
            {
                Key: "Asset",
                Val: category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetAttribute", conds, (data) => {
            console.log("fetchAssetAttributes :> ", conds, " > ", data);
            _this.setState({
                Attributes: data
            });

        });
    }
    fetchData = async () => {
        let _this = this;
        let project = this.props.info.Project;
        let category = this.props.info.Category;

        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },
            {
                Key: "project",
                Val: project,
                Type: "string"
            },
            {
                Key: "Category",
                Val: category,
                Type: "string"
            },
        ];
        await this.fetchingAll("DataAsset", conds, (data) => {
            console.log("fetchData :> ", conds, " > ", data);
            /*const keyOne = "ref";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)*/
            let ls = [];
            for (let i in data) {
                const row = data[i];
                let rec = row.data;
                rec.ref = row.ref;
                ls.push(rec);
            }
            _this.setState({
                Data: ls
            });

        });
    }
    searchProcess = (e) => {
        e.preventDefault();
        const keySearch = this.state.searchValue;
        if (keySearch === "") {
            return alert("Please provide your search key!");
        }

        const filterMe = (me) => {
            let myString = JSON.stringify(me);
            let searchString = myString.toLowerCase();
            let searchKey = keySearch.toString().toLowerCase();
            let index = searchString.indexOf(searchKey);
            let resultIndex = parseFloat(index);
            if (resultIndex === -1) {
                return false
            }
            return true
        }

        let filters = [];
        for (let i in this.state.Data) {
            const row = this.state.Data[i];
            if (filterMe(row)) {
                filters.push(row);
            }
        }
        this.setState({filters: filters});

    }
    handleChangeInput = (e) => {
        let tmp = this.state;
        tmp.searchValue = e.target.value;
        this.setState(tmp);
    }
    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }

    onClickFetchDataAssetBreakdown = async (e) => {
        e.preventDefault();
        let _this = this;
        let hub = {
            Org: GetUserToken().OrgCode,
            Project: this.props.info.Project,
            Category: this.props.info.Category,
            Field: this.state.selectedAttribute
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/asset-breakdown";

        console.log("onClickFetchDataAssetBreakdown send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("onClickFetchDataAssetBreakdown response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    DataBreakdown: data,
                    showBreakdown: true
                })
            }
        });

    }

    onClickFetchDataAssetFiltered = async (e, val) => {
        e.preventDefault();
        let _this = this;
        this.setState({selectedValue:val});
        let hub = {
            Org: GetUserToken().OrgCode,
            Project: this.props.info.Project,
            Category: this.props.info.Category,
            Filter: {[this.state.selectedAttribute]: val},
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/asset";

        console.log("loadFilterAsset send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterAsset response > ", dataIn);
            if (dataIn === null) {
                dataIn = {};
                dataIn.RESULT = []
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    Data: data,
                    showData: true,
                    showBreakdown: false,
                })

            }
        });
    }


    renderData = () => {
        const ls = this.state.Data;
        let headers = ["ref"];


        for (let i in ls) {
            const row = ls[i].Data;
            for (let item in row) {

                headers.push(item);


            }
            break;
        }

        let output = [];
        let counter = 0;

        for (let i in ls) {
            let row = ls[i];
            let o = row.Data;
            o.ref = row.Ref;
            output.push(o);
        }


        if (output.length === 0) {
            return null
        }

        console.log("headers ///////---> ", headers);
        console.log("Row ///////---> ", output[0]);
        //return null

        return (


            <div className="table-wrapper-scroll-y my-custom-scrollbar"
                style={{
                    minHeight:500
                }}
            >
                <table className="table table-bordered table-striped mb-0 table-hover ">
                    <thead>
                    {headers.map((item) => {
                        return (
                            <th scope="col">
                                {item}
                            </th>
                        )
                    })}
                    </thead>
                    <tbody>
                    {output.map((row) => {
                        return (
                            <tr>
                                {headers.map((item, index) => {
                                    let val = row[item];
                                    if (index === 0) {
                                        return (
                                            <th scope="row">
                                                {this.allTitleCase(val)}
                                            </th>
                                        )
                                    }
                                    return (
                                        <td>
                                            {val}
                                        </td>
                                    )
                                })}
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>


        );

        {/*<div className=" row">
                    <div className="col-sm-10 col-lg-12">

                        <form className="form-inline" onSubmit={this.handleChangeInput}>
                            <div className="form-group mb-2 col-lg-3">
                                <label htmlFor="staticEmail2 pull-right">Search Filter</label>
                            </div>
                            <div className="form-group mx-sm-3 mb-2 col-lg-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPassword2"
                                    placeholder="search for ..."
                                    value={this.state.searchValue}
                                    onChange={(e) => this.handleChangeInput(e)}
                                />
                            </div>
                            <div className="form-group mx-sm-3 mb-2 col-lg-2">
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-2 pull-left"
                                >
                                    Search
                                </button>
                            </div>
                            <hr/>
                        </form>

                    </div>

                </div>*/
        }
    }

     allTitleCase=(inStr)=>
    {
        return inStr.replace(/\w\S*/g, function(tStr)
        {
            return tStr.charAt(0).toUpperCase() + tStr.substr(1).toLowerCase();
        });
    }

    renderBoxAttribute = () => {
        const _this = this;
        let ls = this.state.Attributes;

        return (
            <select
                id="inputState"
                className="form-control"
                onChange={(e) => this.handleInput(e, "selectedAttribute")}
                value={this.state.selectedAttribute}
                required={true}
            >
                <option value="" selected>Choose...</option>
                {ls.map((row, index) => {
                    let key = row.name;
                    let desc = row.name;
                    return (
                        <option key={key} value={key}>{desc}</option>
                    )
                })}

            </select>
        )
    }

    renderBreakdown = () => {
        const ls = this.state.DataBreakdown;



        if (ls.length === 0) {
            return (
                <p className={"alert alert-info"}>
                    Empty!
                </p>
            )
        }

        const keyOne = "Name";
        ls.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)

        return (
            <div>
                <p className={"alert alert-info"}>
                    Summary Data of {this.state.selectedAttribute}
                    &nbsp;| Total : <span style={{color: "red"}}>{this.state.DataBreakdown.length}</span>

                    {this.state.showBreakdown ?
                        <a
                            className={" pull-right"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({showBreakdown: false})
                            }}
                        >

                            <li
                                className={"fa fa-chevron-down"}
                                style={styles.hideView}>
                            </li>
                        </a>
                        :
                        <a
                            className={" pull-right"}
                            onClick={(e) => {
                                e.preventDefault();
                                this.setState({showBreakdown: true})
                            }}
                        >

                            <li
                                className={"fa fa-chevron-right"}
                                style={styles.hideView}>
                            </li>
                        </a>
                    }
                </p>

                {this.state.showBreakdown ?
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>{this.state.selectedAttribute}</th>
                            <th>TOTAL ASSETS</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ls.map((row, index) => {
                            const nameCapitalized = row.Name.charAt(0).toUpperCase() + row.Name.slice(1)
                            return (
                                <tr>
                                    <th>{this.allTitleCase(row.Name)}</th>
                                    <td>{row.Counter}</td>
                                    <td>
                                        <a
                                            onClick={(e) => this.onClickFetchDataAssetFiltered(e, row.Name)}
                                            className={"pull-right"}
                                            style={{color: "blue", cursor: "pointer"}}
                                        >Fetch</a>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                    : null
                }
            </div>
        )

    }


    render() {


        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Asset Database
                                <span className={"pull-right"}
                                      style={{color: "red"}}
                                >Total Records: <strong>{this.props.info.CounterAsset}</strong></span>
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="form-group col-md-11">
                            <label htmlFor="inputState">Select your Asset Attribute to view Breakdown size</label>
                            {this.renderBoxAttribute()}
                        </div>
                        <div className="form-group col-md-1">
                            <label htmlFor="inputState">&nbsp;</label>
                            <button
                                type="button"
                                className=" btn btn-primary"
                                onClick={(e) => this.onClickFetchDataAssetBreakdown(e)}
                            >Fetch!
                            </button>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12 col-lg-12">
                            {this.renderBreakdown()}
                        </div>
                    </div>

                    <div className="row container" style={{}}>
                        <div className="col-xs-12 col-lg-12">

                            <p className={"alert alert-info"}>
                                Data of {this.state.selectedAttribute} : {this.state.selectedValue}

                                &nbsp;| total records: <span style={{color: "red"}}>{this.state.Data.length}</span>

                                {this.state.showData ?
                                    <a
                                        className={" pull-right"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({showData: false})
                                        }}
                                    >
                                        <li
                                            className={"fa fa-chevron-down"}
                                            style={styles.hideView}>
                                        </li>
                                    </a>
                                    :
                                    <a
                                        className={" pull-right"}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            this.setState({showData: true})
                                        }}
                                    >
                                        <li
                                            className={"fa fa-chevron-right"}
                                            style={styles.hideView}>
                                        </li>
                                    </a>
                                }

                            </p>

                            {this.state.showData ? this.renderData() :
                                <p style={{color: "red"}}>Waiting your data ..............</p>
                            }
                        </div>
                    </div>

                </div>
            </PageContentWallpaper>
        )


    }


}

const styles={
    hideView:{
        color:"red",
        fontWeight:"bold",
        fontSize:18
    }
}
