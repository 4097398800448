import { combineReducers } from 'redux';

import menus from './reducer/reducer-menus';
import login from './reducer/reducer-login';
import navigations from './reducer/reducer-navigations';
import navigations2 from './reducer/reducer-navigation2';
import addingModel from "./reducer/reducer-adding-model";
import info from "./reducer/reducer-selection-info";


export default combineReducers({
    menus,
    login,
    navigations,
    navigations2,
    addingModel,
    info
})