import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

export default class ComAssetEnumerator2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Enumerators: [],
            StoreProjects: [],
            selectedProject: "",
            selectedCategory: "",
            newSelection: {
                Project: "",
                Category: "",
            },
            newEntry: {},
            newUpload:{
                filename:"",
                base64string:"",
            },
            showList: true,
            showViewDetail: false,
            showImportCsv:false,
            showPopup: false,
            popupData: {},

        }

    }

    async componentDidMount() {
        await this.loadFilterProject();
        await this.fetchAssetEnumeratorAttribute();
        await this.fetchAssetEnumerator();
    }

    fetchAssetEnumeratorAttribute = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("AssetEnumeratorAttribute", conds, (data) => {
            console.log("fetchAssetEnumeratorAttribute :> ", data);
            const keyOne = "position";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
            _this.setState({
                Data: data
            });

        });
    }
    fetchAssetEnumerator = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: GetUserToken().OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("AssetEnumerator", conds, (data) => {
            console.log("fetchAssetEnumerator :> ", data);
            const keyOne = "code";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
            _this.setState({
                Enumerators: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: GetUserToken().OrgCode,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }
    handleInput = (e, key, val) => {
        e.preventDefault();
        let tmp = this.state;
        tmp[key] = val;
        this.setValue(tmp);
    }
    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.newUpload.filename = myfile.name;
            tmp.newUpload.base64string = reader.result;
            _this.setState(tmp);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    getValue = (key) => {
        return this.state.newEntry[key]
    }
    setValue = (e, key) => {
        let val = "";
        if (typeof e.target !== "undefined") {
            val = e.target.value;
        }
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;

        console.log("setValueBox ^^^--> ", key, " > ", val)
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    submitEntry = async (e) => {
        e.preventDefault();
        if (this.state.newSelection.Project === "") {
            return alert("Please select your Project & Category before submit");
        }
        if (!window.confirm("Are you sure that you want to submit?")) {
            return
        }

        let _this = this;
        let entry = this.builder();


        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: "AssetEnumerator",
            In: entry,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/add";

        console.log("submitEntry send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitEntry response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you Asset attribute submitted!");
                let tmp = _this.state;
                tmp.showList = true;

                _this.setState(tmp);
                _this.fetchAssetEnumeratorAttribute();
                _this.fetchAssetEnumerator();
            }
        });
    }
    submitEntryCsv=async (e)=>{
        e.preventDefault();
        const user =  GetUserToken();
        const _this = this;
        const state = this.state;
        const username =user.Username;

        if(state.newSelection.Project ===""){
            return alert("Error please select your Project before upload file");
        }
        if(!window.confirm("Are you sure that you want to upload?")){
            return
        }



        let hub = {
            Org: user.OrgCode,
            Project: state.newSelection.Project,
            AssetCategory: state.newSelection.Category,
            UploadRef: "",
            Username: username,
            Base64String: state.newUpload.base64string,
            RequestType: "",

        }

        console.log("submitImportCsv send > ", hub);


        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/enumerator/csv";

        //   ALPHA-OMOCA-01
        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("convertBase64CsvIntoString response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you  for your asset csv import , an email will be send to you once  done!")
                let tmp = _this.state;
                tmp.newUpload = {
                    name: "",
                    base64string: "",
                    filename: "",
                };
                tmp.showList = true;
                tmp.showImportCsv = false;
                tmp.showPopup = false;
                _this.setState(tmp);
                //await _this.fetchDataAssetUploadHistory();
                //window.location.reload();

            } else {
                alert(":) Sorry error occurred during processing please try again")
            }
        })
    }
    builder = () => {
        let state = this.state;
        let selection = this.state.newSelection;
        let entry = this.state.newEntry;
        let data = {};
        data.org = GetUserToken().OrgCode;
        data.project = selection.Project;
        data.assetcategory = selection.Category;
        data.data = entry;
        let unique, generated, password, username,code,name,surname;
        for (let i in state.Data) {
            const row = state.Data[i];
            if (row.project === selection.Project && row.assetcategory === selection.Category) {
                if (row.unique === "yes") {
                    unique = row.fieldname;
                }
                if (row.generated === "yes") {
                    generated = row.fieldname;
                }
                if (row.password === "yes") {
                    password = row.fieldname;
                }
                if (row.username === "yes") {
                    username = row.fieldname;
                }

                if (row.code === "yes") {
                    code = row.fieldname;
                }
                if (row.name === "yes") {
                    name = row.fieldname;
                }
                if (row.surname === "yes") {
                    surname = row.fieldname;
                }
            }
        }

        console.log("{:}--> unique:", unique);
        console.log("{:}--> generated :", generated);
        console.log("{:}--> password:", password);
        console.log("{:}--> username:", username);
        console.log("{:}--> code:", code);
        console.log("{:}--> name:", name);
        console.log("{:}--> surname:", surname);

        data.ref = "";
        data.username = "";
        data.password = "";
        data.code="";
        data.name="";
        data.surname="";
        if (typeof entry[unique] !== "undefined") {
            data.ref = entry[unique];
        }
        if (typeof entry[username] !== "undefined") {
            data.username = entry[username];
        }
        if (typeof entry[password] !== "undefined") {
            data.password = entry[password];
        }
        /*
                -----------
                 */
        if (typeof entry[code] !== "undefined") {
            data.code = entry[code];
        }
        if (typeof entry[name] !== "undefined") {
            data.name = entry[name];
        }
        if (typeof entry[surname] !== "undefined") {
            data.surname = entry[surname];
        }

        //todo check to generate the code if needed
        data.code = this.generateCode();

        //todo let remove undefined from data
        let data2={};
        for(let key in data.data){
            const val = data.data[key];
            if( key !=="undefined"){
                data2[key]=val.toString();
            }
        }
        data.data = data2;



        console.log("{:}--> ", data);
        return data

    }
    getRefFieldName=()=>{
        //find generated field name
        let state = this.state;
        let selection = this.state.newSelection;
        let codeField="";
        for (let i in state.Data) {
            const row = state.Data[i];
            if (row.project === selection.Project && row.assetcategory === selection.Category) {
                if (row.unique === "yes") {
                    codeField = row.fieldname;
                }
            }
        }
        return codeField
    }
    generateCode=()=>{

        //find generated field name
        let state = this.state;
        let selection = this.state.newSelection;
        let entry = this.state.newEntry;
        let codeField=this.getRefFieldName();

        //if not setup return zero or not supported
        if(codeField===""){
            state.newEntry[codeField] = 0;
            this.setState(state);
            return 0;
        }

        //loop to find all code generate
        let data = [];
        for (let i in state.Enumerators) {
            const row = state.Enumerators[i];
            if (row.project === selection.Project && row.assetcategory === selection.Category) {
                data.push(row.code);
            }
        }

        //if is empty use default
        if(data.length===0){
            state.newEntry[codeField] = 10000;
            this.setState(state);
            return 10000;
        }

        //todo sort it asc order
        data.sort();

        //todo increase by one
        const lastEntry = data[(data.length-1)];
        let newCode = parseInt(lastEntry)+1;



        //todo update state with new entry code
        state.newEntry[codeField] = newCode.toString();
        this.setState(state);


        //todo return
        return newCode;
    }
    openPopupData = (e, row) => {
        e.preventDefault();
        console.log("{:}--> row :", row);
        this.setState({showPopup: true, popupData: row})
    }
    closePopupData = () => {
        this.setState({showPopup: false, popupData: {}})
    }
    openImportCsv=()=>{
        this.setState({
            showList: false,
            showViewDetail: false,
            showImportCsv:true,
        });
    }
    closeImportCsv=()=>{
        this.setState({
            showList: true,
            showViewDetail: false,
            showImportCsv:false,
        });
    }
    renderProjectBox = () => {
        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newSelection = state.newSelection;
            newSelection.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newSelection.Id === index) {
                    newSelection.Project = row.Project;
                    newSelection.Category = row.Category;
                    newSelection.Headers = row.Data;
                }
                index++;
            }
            state.newSelection = newSelection;
            _this.setState(state);


        }
        const getValueProject = () => {
            return _this.state.newSelection.Id
        }


        return (
            <div className="row container">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">Project </label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValueProject(e)}
                        value={getValueProject()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {this.state.StoreProjects.map((row, index) => {
                            let key = row.Id;
                            let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                            return (
                                <option key={index} value={index}>{desc}</option>
                            )
                        })}

                    </select>
                </div>

            </div>
        )
    }
    renderList = () => {
        let state = this.state;
        let data = state.Enumerators;
        let entry = state.newSelection;
        if (!state.showList) {
            return null
        }

        let ls = [];
        for (let i in data) {
            const row = data[i];
            if (row.project === entry.Project && row.assetcategory === entry.Category) {
                ls.push(row)
            }
        }

        if (ls.length === 0) {
            return (
                <div className={"alert alert-danger"}>Empty list!</div>
            )
        }

        let codeField=this.getRefFieldName();

        return (
            <div className={"container"}>
                <p className={"alert alert-primary"}>List Attributes</p>
                <table
                    className={"table"}
                    style={{
                        border: '3px solid gray',
                        borderRightColor: 'gray',
                    }}
                >
                    <thead>

                    <tr>
                        <th>Code</th>
                        <th>{codeField || "Unique Key"}</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Detail</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {

                        return (
                            <tr key={"xkdkd" + index}>
                                <td style={{width: 100}}>
                                    {row.code}
                                </td>
                                <td>{row.ref}</td>
                                <td>{row.name || "--"}</td>
                                <td>{row.surname || "--"}</td>
                                <td>
                                    <a
                                        onClick={(e) => this.openPopupData(e, row)}
                                        style={{cursor: "pointer", color: "blue", fontSize: 18}}
                                    >
                                        View
                                    </a>
                                </td>
                                <th style={{width: 100}}>
                                    <a
                                        onClick={(e) => this.removeEntry(e, row)}
                                        className={"btn btn-default"}
                                    >Remove</a>
                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
    renderForm = () => {
        const _this = this;
        let state = this.state;
        const entry = state.newSelection;
        if (state.showList) {
            return null
        }

        const options = ["yes", "no"];
        let ls = [
        ];

        for (let i in state.Data) {
            const row = state.Data[i];
            if (row.project === entry.Project && row.assetcategory === entry.Category) {
                let required = false;
                if (row.mandatory === "yes") {
                    required = true;
                }
                ls.push({
                    field: row.fieldname,
                    label: row.fieldname.toLocaleUpperCase(),
                    options: options,
                    type: "text",
                    required: required,
                    mandatory: row.mandatory,
                    generated: row.generated,
                })
            }
        }

        if ( entry.Project==="" ) {
            return(
                <p className={"alert alert-danger"}>Please select your project</p>
            )
        }

        if(ls.length===0){
            return(
                <p className={"alert alert-danger"}>Please setup enumerator attribute first before adding a new enumerator ...
                click on Asset tab and enumerator attribute tab</p>
            )
        }


        return (
            <div className={"container"}>
                <p className={"alert alert-success"}>New Enumerator</p>
                <form onSubmit={this.submitEntry}>
                    {ls.map((row, index) => {
                        let disable = false;
                        let placeHold = row.fieldname;
                        if (row.generated === "yes") {
                            disable = true;
                            placeHold = "This field will be generated automatically"
                        }
                        return (
                            <div className="row" key={"zty" + index}>
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">
                                        {row.label}
                                        {row.mandatory === "yes" && (
                                            <span
                                                style={{color: "red", fontSize: 20}}
                                            >&nbsp;*&nbsp;</span>
                                        )}
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        onChange={(e) => _this.setValue(e, row.field)}
                                        value={_this.getValue(row.field)}
                                        required={row.required}
                                        disabled={disable}
                                        placeholder={placeHold}
                                    />
                                </div>
                            </div>
                        )
                    })}
                    <button
                        type={"submit"}
                        className={"btn btn-default pull-right "}
                        style={{backgroundColor: " #00b3ee"}}
                    > Submit
                    </button>
                </form>
            </div>
        )
    }
    renderModalSelectedEnumeratorDataView = () => {
        if (!this.state.showPopup) {
            return null
        }

        const obj = this.state.popupData;
        let ls = [];
        for (let key in obj.data) {
            const val = obj.data[key];
            ls.push({
                key: key,
                val: val
            });
        }

        return (
            <Modal show={this.state.showPopup} onHide={this.closePopupData}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Code : <span style={{color:"blue"}}>{obj.code}</span> &nbsp; | &nbsp;
                        {this.getRefFieldName()+": "}<span style={{color:"red"}}>{obj.ref}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll"}}>

                    <div className="row">
                        <div className="col-lg-12">
                            <table className={"table"}>
                                <thead>
                                <tr>
                                    <th>Attribute</th>
                                    <th>Value</th>
                                </tr>
                                </thead>
                                <tbody>
                                {ls.map((row, index) => {
                                    return (
                                        <tr>
                                            <td>{row.key}</td>
                                            <td>{row.val}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closePopupData}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }
    renderModalImportCsv=()=>{
        if (!this.state.showImportCsv) {
            return null
        }

        return (
            <Modal show={this.state.showImportCsv} onHide={this.closeImportCsv}>
                <Modal.Header closeButton>
                    <Modal.Title>
                         <span style={{color:"blue"}}>Enumerator Import CSV </span>

                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll"}}>

                    <div className="row">
                        <div className="col-lg-12">

                            <form onSubmit={this.submitEntryCsv}>
                                <div className="form-group">
                                    <label>Choose your file</label>
                                    <input
                                        id={"myFile"}
                                        type="file"
                                        className="form-control"
                                        required={true}
                                        onChange={(e) => this.handleInputFile(e, "myFile")}
                                    />
                                </div>


                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn-primary form-control"
                                    >Submit
                                    </button>
                                </div>
                            </form>

                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeImportCsv}>Close</Button>
                </Modal.Footer>
            </Modal>
        )

    }


    render() {


        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Enumerator Database
                                <a
                                    className={"btn btn-default pull-right" }
                                    style={{marginLeft:20}}
                                   onClick={() => this.openImportCsv()}>Import Csv</a>

                                {this.state.showList ?
                                    <a className={"btn btn-primary pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", false)}>Add New Enumerator</a>
                                    :
                                    <a className={"btn btn-danger pull-right"}
                                       onClick={(e) => this.handleInput(e, "showList", true)}>Cancel</a>
                                }
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            {this.renderProjectBox()}
                        </div>
                    </div>
                    {this.state.showList ? this.renderList() : this.renderForm()}

                    <div className="row container">
                        <div className="col-xs-12">
                            {this.renderModalSelectedEnumeratorDataView()}
                            {this.renderModalImportCsv()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )


    }

}