import React, {Component} from 'react';
/*import '../../asset/css/bootstrap-modal.css';*/
import 'bootstrap/dist/css/bootstrap.min.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {Button, Modal} from 'react-bootstrap';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";

export default class SettingClientComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            Surveys: [],
            Assets: [],
            currentAsset: null,
            newAsset: {
                Survey : "",
                Code : "",
                Name :"",
                Position :0,
                Category :"",
                TimelineMeasure :"",
                TimelineValue :0,
                Timeline :"",
                NextQuestion :"",
                BackQuestion :"",
                Score :0,
                SingleAnswer: {},
                MultipleAnswer:[],
            },
            newOwner: {
                QuestionCode: "",
                Name: "",
                Score: 0,
                Correct: "",
                Position: "",
                Orientation: "",
                NextQuestion: "",
            },
            isList: true,
            selectedAsset: null,
            isManageOwner: false,
            isNewOwner: false,
            isNewAsset: false,
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchSurveys();
        await this.fetchQuestions();

    }

    fetchQuestions = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Question", conds, (data) => {
            console.log("fetchOwner :> ", data);
            /*let ls = {};
            for (let i in data) {
                let row = data[i];
                ls[row.username] = row;
            }*/
            _this.setState({
                Assets: data
            });

        });
    }
    fetchSurveys = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchAssets :> ", data);
            /*let ls = {};
            for (let i in data) {
                let row = data[i];
                ls[row.username] = row;
            }*/
            _this.setState({
                Surveys: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    manageOption = (row) => {
        this.setState({
            selectedAsset: row,
            isManageOwner: true,
            isList: false,
        })
    }
    closeManageOption = () => {
        this.setState({
            selectedAsset: null,
            isManageOwner: false,
            isList: true,
        })
    }
    newQuestionRequest = () => {
        if(this.state.selectedAsset ===""){
            return alert("Please select your survey!")
        }
        let tmp = this.state;
        tmp.newAsset={
            Survey : this.state.selectedAsset,
            Code : "",
            Name :"",
            Position :0,
            Category :"",
            TimelineMeasure :"",
            TimelineValue :0,
            Timeline :"",
            NextQuestion :"",
            BackQuestion :"",
            Score :0,
        };
        tmp.isNewAsset = true;
        tmp.isList = false;
        this.setState(tmp);
    }
    cancelNewQuestion=()=>{
        let tmp = this.state;
        tmp.newAsset={
            Survey : this.state.selectedAsset,
            Code : "",
            Name :"",
            Position :0,
            Category :0,
            TimelineMeasure :"",
            TimelineValue :0,
            Timeline :"",
            NextQuestion :"",
            BackQuestion :"",
            Score :0,
            SingleAnswer: {},
            MultipleAnswer:[],
        };
        tmp.isNewAsset = false;
        tmp.isList = true;
        this.setState(tmp);
    }
    newOptionRequest = (e) => {
        e.preventDefault();
        let state = this.state;
        let newOption = this.state.newOwner;
        newOption.QuestionCode = this.state.selectedAsset.code;
        state.newOwner = newOption;
        state.isNewOwner = true;
        this.setState(state);
    }
    newOptionHandle = (e, key, mType) => {
        let tmp = this.state;
        tmp.newOwner[key] = e.target.value;
        this.setState(tmp);
    }
    newQuestionHandle = (e, key, mType) => {
        let tmp = this.state;
        tmp.newAsset[key] = e.target.value;
        this.setState(tmp);
    }
    removeQuestion=async (row)=>{
        if (!window.confirm("Are you sure that you want to delete this question ? " + row.name)) {
            return
        }
        const hub={
            Org : row.org,
            Survey : row.survey,
            QuestionCode : row.code
        }

        let _this = this;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/question/delete";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitSearchDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchQuestions();
            }
        });

    }
    removeOption = async (name) => {
        if (!window.confirm("Are you sure that you want to delete this option? " + name)) {
            return
        }
        let _this = this;
        let multipleanswer = [];
        for (let i in this.state.selectedAsset.multipleanswer) {
            const row = this.state.selectedAsset.multipleanswer[i];
            if (row.name !== name) {
                multipleanswer.push(row);
            }
        }
        let hub = this.state.selectedAsset;
        hub.multipleanswer = multipleanswer;
        console.log("Submit submitSearchDashboard > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/question/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitSearchDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchQuestions();
                await _this.afterAddOption();
            }
        });
    }
    submitNewOption = async (e) => {
        e.preventDefault();
        const state = this.state.newOwner;
        const newOption = {
            questioncode: state.QuestionCode,
            name: state.Name,
            score: parseInt(state.Score),
            correct: state.Correct,
            position: state.Position,
            orientation: state.Orientation,
            nextquestion: state.NextQuestion,
        };

        let _this = this;
        let hub = this.state.selectedAsset;
        hub.multipleanswer.push(newOption);
        console.log("Submit submitSearchDashboard > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/question/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitSearchDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchQuestions();
                await _this.afterAddOption();
            }
        });
    }
    submitNewQuestion = async (e)=>{
        e.preventDefault();
        if(!window.confirm("Are you sure that you want to submit this question?")){
           return
        }
        let _this = this;
        let hub = this.state.newAsset;
        hub.org = this.state.organisation;
        hub. Position  = parseInt(hub.Position);
        hub.Score = parseInt(hub.Score);

        console.log("Submit submitNewAsset > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/question/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitNewAsset response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchQuestions();
                await _this.cancelNewQuestion();
            }
        });
    }
    afterAddOption = async () => {
        let tmp = this.state;
        tmp.isNewOwner = false;
        tmp.newOwner = {
            Name: "",
            Score: 0,
            Correct: "",
            Position: "",
            Orientation: "",
            NextQuestion: ""
        }
        this.setState(tmp);

        let selectQuestion = null;
        const lsQuestions = this.state.Assets;
        const actual = this.state.selectedAsset;
        for (let i in lsQuestions) {
            const row = lsQuestions[i];
            if (row.code === actual.code && row.survey === actual.survey && row.org === actual.org) {
                selectQuestion = row;
            }
        }

        tmp.selectedAsset = selectQuestion;
        this.setState(tmp);
        //this.closeNewOption();
    }
    renderQuestionList = () => {
        if (!this.state.isList) {
            return null
        }

        let ls = [];
        for (let i in this.state.Assets) {
            const row = this.state.Assets[i];
            //console.log("******>>> ", (row.survey === this.state.selectedAsset), " > ", row.survey, " == ", this.state.selectedAsset);
            if (row.survey === this.state.selectedAsset) {
                ls.push(row);
            }
        }


        if (ls.length === 0) {
            return (
                <p className={"alert alert-dander"}>No question available -- please select your survey</p>
            )
        }

        /*
        Name text,
        Description text,
        StartDate text,
        EndDate text,
        NumQuestion float,
        TotalScore float,
        Participant float,
         */

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Text</th>
                    <th>Score</th>
                    <th>Category</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>{row.position}</td>
                            <td>{row.name}</td>
                            <td>{row.score}</td>
                            <td>{row.category}</td>

                            {row.category === "option" ?
                                <td><a
                                    className={"btn btn-default pull-right"}
                                    onClick={() => this.manageOption(row)}
                                >Options</a></td> :
                                <td>&nbsp;</td>
                            }

                            <td>
                                <a
                                    className={" pull-right"}
                                    style={{color:"blue",fontSize:18,cursor:"pointer"}}
                                    onClick={() => this.removeQuestion(row)}
                                >Remove</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    renderQuestionForm = () => {
        if (this.state.isList) {
            return null
        }
    }
    renderManageQuestionOption = () => {
        if (!this.state.isManageOwner) {
            return null;
        }
        if (this.state.selectedAsset === null) {
            return null;
        }

        return (
            <div className={""}>
                <hr/>
                <p className={"alert alert-info"}>
                    Manage question Options
                    <a className={" btn-danger pull-right"}
                       onClick={() => this.closeManageOption()}
                    >Cancel</a>
                </p>
                <p>
                    Survey: <b>{this.state.selectedAsset.survey}</b> ; &nbsp;&nbsp;
                    Question: <b>{this.state.selectedAsset.name}</b> ; &nbsp;&nbsp;
                </p>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Text</th>
                        <th>Score</th>
                        <th>isCorrect</th>
                        <th>Position</th>
                        <th>Orientation</th>
                        <th>Next Q.</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {typeof this.state.selectedAsset.multipleanswer ==="undefined"?
                        null
                   :
                    this.state.selectedAsset.multipleanswer.map((row, index) => {
                        return (
                            <tr key={index}>
                                <td>{row.name}</td>
                                <td>{row.score}</td>
                                <td>{row.correct}</td>
                                <td>{row.position}</td>
                                <td>{row.orientation}</td>
                                <td>{row.nextquestion}</td>
                                <th>
                                    <a
                                        className={"btn btn-default pull-right"}
                                        onClick={() => this.removeOption(row.name)}
                                    >Remove</a>
                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={6}>&nbsp;</td>
                        <td>
                            <a
                                className={"btn btn-info pull-right"}
                                onClick={(e) => this.newOptionRequest(e)}
                            >Add</a>
                        </td>
                    </tr>

                    </tfoot>
                </table>
            </div>
        )
    }
    renderNewOptionForm = () => {

        /*
        Name :"",
                Score :0,
                Correct :"",
                Position :"",
                Orientation :"",
                NextQuestion :"",
         */

        let fields = [
            {field: "Name", desc: "Display Text", type: "text", options: null, required: true},
            {field: "Score", desc: "Score for this option", type: "float", options: null, required: true},
            {
                field: "Position",
                desc: "What is the position of this option?",
                type: "float",
                options: null,
                required: false
            },
            {
                field: "Correct", desc: "Is this Correct answer?", type: "option", required: false,
                options: [
                    {key: "", val: "--Select--"},
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"}
                ]
            },
            {
                field: "Orientation", desc: "Is this dumping to specific question?", type: "option", required: false,
                options: [
                    {key: "", val: "--Select--"},
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"}
                ]
            },
            {
                field: "NextQuestion",
                desc: "What is the next question number by choosing this option?",
                type: "float",
                options: null,
                required: false
            },
        ]

        return (
            <div className={"container"}>
                <form onSubmit={this.submitNewOption}>
                    {fields.map((row, index) => {
                        if (row.type !== "option") {
                            return (
                                <div className="form-row">
                                    <div className="form-group col-md-16">
                                        <label htmlFor="inputState">{row.desc}</label>
                                        <input
                                            id="inputState"
                                            className="form-control"
                                            onChange={(e) => this.newOptionHandle(e, row.field)}
                                            value={this.state.newOwner[row.field]}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">{row.desc}</label>
                                    <select
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.newOptionHandle(e, row.field)}
                                        value={this.state.newOwner[row.field]}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((innerRow, x) => {
                                            return (
                                                <option key={x} value={innerRow.key}>{innerRow.val}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )

                    })}

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <button
                                type="submit"
                                className={"btn btn-info form-control"}
                            >Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
    renderNewQuestionFOrm = () => {
        if(!this.state.isNewAsset){
            return
        }
        /*
        Survey text,
        Code text,
        Name text,
        Position float,
        Category text,

        TimelineMeasure text,
        TimelineValue float,
        Timeline text,
        NextQuestion text,
        BackQuestion text,
        Score float,
         */
        let fields = [
            {field: "Name", desc: "Display Text", type: "text", options: null, required: true},
            {
                field: "Position",
                desc: "What is the position of this question?",
                type: "float",
                options: null,
                required: false
            },
            {field: "Score", desc: "Score for this option", type: "float", options: null, required: false},
            {
                field: "Category", desc: "Category", type: "option", required: true,
                options: [
                    {key: "text", val: "Text"},
                    {key: "option", val: "Option"},
                ]
            },
            {
                field: "Timeline", desc: "Timeline support?", type: "option", required: false,
                options: [
                    {key: "yes", val: "Yes"},
                    {key: "no", val: "No"},
                ]
            },{
                field: "TimelineMeasure", desc: "Timeline Measure", type: "option", required: false,
                options: [
                    {key: "second", val: "Second"},
                    {key: "minute", val: "Minute"},
                    {key: "hour", val: "Hour"},
                    {key: "day", val: "Day"},
                ]
            },
            {field: "TimelineValue", desc: "Timeline Value", type: "float", options: null, required: false},
            {field: "NextQuestion", desc: "Next Question", type: "float", options: null, required: false},
            {field: "BackQuestion", desc: "Back Question", type: "float", options: null, required: false},
        ];

        return (
            <div className={"container"}>
                <p className="alert alert-info">New Question
                    <a onClick={()=>this.cancelNewQuestion()}
                    className={"btn btn-danger pull-right"}>Cancel</a>
                </p>
                <form onSubmit={this.submitNewQuestion}>
                    {fields.map((row, index) => {
                        if (row.type !== "option") {
                            return (
                                <div className="form-row">
                                    <div className="form-group col-md-16">
                                        <label htmlFor="inputState">{row.desc}</label>
                                        <input
                                            id="inputState"
                                            className="form-control"
                                            onChange={(e) => this.newQuestionHandle(e, row.field)}
                                            value={this.state.newAsset[row.field]}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">{row.desc}</label>
                                    <select
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.newQuestionHandle(e, row.field)}
                                        value={this.state.newAsset[row.field]}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((innerRow, x) => {
                                            return (
                                                <option key={x} value={innerRow.key}>{innerRow.val}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )

                    })}

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <button
                                type="submit"
                                className={"btn btn-info form-control"}
                            >Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )

    }

    closeNewOption = () => {
        this.setState({
            isNewOwner: false
        })
    }
    renderModalNewOption = () => {


        return (

            <Modal show={this.state.isNewOwner} onHide={this.closeNewOption} animation={false} className={"modal"}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Option
                        [{this.state.selectedAsset !== null && (
                        <span>Survey: <b>{this.state.selectedAsset.survey}</b>;&nbsp;Question:<b>{this.state.selectedAsset.name}</b> </span>
                    )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll" ,marginLeft:0}} className={"pull-left"}>

                    <div className="row">
                        <div className="col-lg-12">
                            {this.renderNewOptionForm()}
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeNewOption}>Close</Button>
                </Modal.Footer>
            </Modal>

        )
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>
                            Survey Question
                            <a
                                className={"btn btn-info pull-right"}
                                onClick={() => this.newQuestionRequest()}
                            >New</a>
                        </h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        {this.selectionBox("Survey", "Surveys", "name", "name", "selectedAsset", this.handleInput, [])}
                    </div>
                </div>

                <div className="row">
                    <div className="col-xs-12">
                        {this.renderQuestionList()}
                        {this.renderQuestionForm()}
                        {this.renderManageQuestionOption()}
                        {this.renderModalNewOption()}
                        {this.renderNewQuestionFOrm()}
                    </div>
                </div>




            </PageContentWallpaper>


        );
    }
}