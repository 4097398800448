import React, {Component} from "react";
import "../../asset/css/asset-survey-step-geo.css";

export default class ComLibAttachDocument extends Component {

    constructor(props) {
        super(props);
        this.state = {
            base64string: "",
            category: "",
            filename: "",
            inputValue: "",
        }
    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    getValue = (key) => {
        if (typeof this.state[key] === "undefined") {
            return "";
        }
        return this.state[key];
    }
    handleInputFile = (e, id) => {
        let inputUpload = document.getElementById(id);
        let myfile = inputUpload.files[0];
        if (!myfile) {
            console.log("No file selected.");
            return;
        }
        let _this = this;
        let reader = new FileReader();
        reader.readAsDataURL(myfile);
        reader.onload = function () {
            let tmp = _this.state;
            tmp.filename = myfile.name;
            tmp.base64string = reader.result;
            //tmp.inputValue = e.target.value;
            _this.setState(tmp);
            console.log("/////-=> ",_this.state);
        };
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
    }
    addFile = (e) => {
        e.preventDefault();
        let state = this.state;
        if (state.category === "") {
            alert("Fail to add file, please select your FileName!")
            return
        }
        if (state.base64string === "") {
            alert("Fail to add file, please choose  your File!")
            return
        }

        this.props.setStepAttachFile(state.category, state.base64string);
        state.category = "";
        state.base64String = "";
        state.inputValue = "";
        this.setState(state);

        let el = window.document.getElementById("fmAdd");
        el.reset();
    }
    removeFile=(e,category)=>{
        e.preventDefault();
        this.props.removeStepAttachFile(category);
    }

    renderCategoryOption = () => {
        let ls = ["Asset Picture", "Electricity Box Photo", "Tenant Picture"];
        //todo please replace this array content with value from setup Asset File

        return ls.map((item, index) => {
            return (
                <option key={"ctOp" + index} value={item}>{item.toLocaleUpperCase()}</option>
            )
        })
    }

    renderListAvailableFile=()=>{

        let ls = [];
        for(let i in this.props.attachs){
            const base64string = this.props.attachs[i];
            let fileType = "none";
            //data:application/pdf;base64,
            const arr1=base64string.split(";base64,");
            if(arr1.length>1){
                const arr2 = arr1[0].split("/");
                if(arr2.length > 1){
                    fileType = arr2[(arr2.length -1)];
                }
            }
            ls.push({
                category:i,
                base64string:base64string,
                fileType:fileType
            });
        }
        if(ls.length ===0){
            return(
                <p className={"alert alert-default"}>Empty List!</p>
            )
        }

        return(
            <table className={"table"}>
                <thead>
                <tr>
                    <th>File Name</th>
                    <th>Type</th>
                    <th style={{width:50}}>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row,index)=>{
                    return(
                        <tr key={"libAttach"+index}>
                            <td>{row.category}</td>
                            <td>{row.fileType}</td>
                            <td style={{width:50}}>
                                <a
                                className={"btn btn-default"}
                                onClick={(e)=>this.removeFile(e,row.category)}
                                >Remove</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )

    }

    render() {
        return (
            <div className={"container"}>
                <div className={"row"}>
                    <form onSubmit={this.addFile} id="fmAdd">

                        <div className="form-group  col-md-12 col-lg-5">
                            <label>Name of your file</label>
                            <select
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInput(e, "category")}
                                value={this.getValue("category")}
                            >
                                <option value={""}>--select--</option>
                                {this.renderCategoryOption()}
                            </select>
                        </div>

                        <div className="form-group  col-md-12 col-lg-5">
                            <label>Choose your file</label>
                            <input
                                id={"myFile"}
                                type="file"
                                className="form-control"
                                required={true}
                                onChange={(e) => this.handleInputFile(e, "myFile")}
                            />
                        </div>

                        <div className="form-group  col-md-12 col-lg-2">
                            <label>&nbsp;</label>
                            <button
                                type="submit"
                                className="form-control"
                            >Submit
                            </button>
                        </div>
                    </form>

                </div>

                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                    {this.renderListAvailableFile()}
                    </div>
                </div>
            </div>
        )
    }


}