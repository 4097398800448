import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";
import ReactHtmlTableToExcel from "react-html-table-to-excel";

export default class ComDatabaseCsvCapture extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Attributes: [],
            structure: {},
        }

    }

    async componentDidMount() {
        await this.fetchAttribute();
    }

    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    fetchAttribute = async () => {
        let _this = this;
        let project = this.props.info.Project;
        let category = this.props.info.Category;

        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },
            {
                Key: "project",
                Val: project,
                Type: "string"
            },
            {
                Key: "assetCategory",
                Val: category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetCaptureAttribute", conds, (data) => {
            console.log("fetchAttribute :> ", conds, " > ", data);
            const keyOne = "position";
            data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
            _this.setState({
                Attributes: data
            });

        });
    }


    renderHorizontal = () => {
        const attributes = this.state.Attributes;
        let ls = [];
        for (let i in attributes) {
            const row = attributes[i];
            const key = row.fieldname;
            if (key === "code" || key === "Code") {
                //todo do nothing
            } else {
                ls.push(key);
            }
        }

        const onlineStyle = {
            borderWidth: 1,
            borderColor: "gray"
        }

        return (
            <div>
                <table className={"table"} id={"tb1"} border={1}>
                    <thead>
                    <tr>
                        {ls.map((item, index) => {
                            return (
                                <th key={"uy" + index}>
                                    {item}
                                </th>
                            )
                        })}
                    </tr>
                    </thead>
                </table>
                <p style={{textAlign: "right"}}>
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="tb1"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Download as XLS"
                    />
                </p>
            </div>
        )
    }
    renderVertical = () => {
        const attributes = this.state.Attributes;
        let ls = [];
        for (let i in attributes) {
            const row = attributes[i];
            const key = row.fieldname;
            const position = parseInt(row.position);
            if (key === "code" || key === "Code") {
                //todo do nothing
            } else {
                ls.push({name: key, position: position-1});
            }
        }



        return (
            <div>
                <table className={"table"} id={"tb2"} border={1}>
                    <thead>
                    <tr>
                        <th>Position</th>
                        <th>Attribute Name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ls.map((row, index) => {
                        return (
                            <tr key={"uy" + index}>
                                <td>
                                    {row.position}

                                </td>
                                <td>
                                    {row.name}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
                <p style={{textAlign: "right"}}>
                    <ReactHtmlTableToExcel
                        id="test-table-xls-button"
                        className="download-table-xls-button"
                        table="tb2"
                        filename="tablexls"
                        sheet="tablexls"
                        buttonText="Download as XLS"
                    />
                </p>
            </div>
        )
    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Capturer CSV Structure
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <p className={"alert alert-info"}>Csv coma delimited format</p>
                            {this.renderHorizontal()}
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <p className={"alert alert-info"}>Detail Structure</p>
                            {this.renderVertical()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )


    }


}
