import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

export default class ComAssetExtractionExport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: "",
            fileName: "",
            survey:"",
            Surveys:[],
        };
    }

    async componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchSurvey();
    }

    fetchSurvey = async () => {
        const user = GetUserToken();
        let _this = this;
        let conds = [{
            Key: "org",
            Val: user.OrgCode,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchSurvey :> ", data);
            _this.setState({
                Surveys: data
            });

        });
    }

    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }

    getStateValue = (key) => {
        return this.state[key]
    }

    setStateValue = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }

    submitReport = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this report?")) {
            return
        }
        let _this = this;
        const user = GetUserToken();

        let hub = {};

        hub.Org = user.OrgCode;
        hub.Project = this.props.info.Project;
        hub.AssetCategory = this.props.info.Category;
        hub.SurveyName = this.state.survey;
        hub.FileName = this.state.fileName;
        hub.Email = this.state.email;

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/extraction/data";

        console.log("submitReport send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("submitReport response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                alert("Thank you report request submitted! an email will be send to you after done");
                _this.setState({
                    email: "",
                    fileName: "",
                    survey:""
                })
            }
        });

    }


    renderSurveyBox=()=>{

        return(
            <div className="row container">
                <div className="form-group col-md-11">
                    <label htmlFor="inputState">Select your Survey </label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => this.setStateValue(e,"survey")}
                        value={this.getStateValue("survey")}
                        required={true}
                        style={styles}
                    >
                        <option value="" selected>Choose...</option>
                        {this.state.Surveys.map((row, index) => {
                            let key = row.name;
                            let desc = row.name.toLocaleUpperCase();
                            return (
                                <option key={index} value={key}>{desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }

    render() {



        return (
            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Export Data From Wizard

                            </h2>
                        </div>
                    </div>
                </div>

                <form onSubmit={this.submitReport}>

                    {this.renderSurveyBox()}

                    <div className="row container">
                        <div className="form-group col-md-11">
                            <label htmlFor="inputState">Email address to send report</label>
                            <input
                                className={"form-control"}
                                onChange={(e) => this.setStateValue(e, "email")}
                                value={this.getStateValue("email")}
                                style={styles}
                                type={"email"}
                            />
                        </div>
                    </div>
                    <div className="row container">
                        <div className="form-group col-md-11">
                            <label htmlFor="inputState">Name of your report</label>
                            <input
                                className={"form-control"}
                                onChange={(e) => this.setStateValue(e, "fileName")}
                                value={this.getStateValue("fileName")}
                                style={styles}
                            />
                        </div>
                    </div>




                    <div className="row container">
                        <div className="form-group col-md-11">
                            <label htmlFor="inputState"></label>
                            <button
                                style={styles}
                                type={"submit"}
                                className={"btn btn-primary pull-right "}
                                style={{backgroundColor: " #00b3ee"}}
                            > Submit<span className={"fa fa-angle-double-right"}>&nbsp;</span>
                            </button>
                        </div>
                    </div>


                </form>


            </PageContentWallpaper>
        )
    }
}

const styles={
    minWidth:"100%",
    maxWidth:"100%"
}
