import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';


export default class ComAssetDatalink extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Questions: [],
            Surveys: [],
            StoreProjects: [],
            DataLinks:[],
            newEntry: {
                Project: "",
                Category: "",
                Survey: "",
                Data: {},
                currentAssetAttribute: "",
                currentQuestion: "",
            }
        }

    }

    async componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchSurvey();
        await this.loadFilterProject();
        await this.fetchQuestion();
        await this.fetchDataLink();
    }

    handleInput = (e, key) => {
        let tmp = this.state;
        tmp[key] = e.target.value;
        this.setState(tmp);
    }
    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: this.state.organisation,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                })
            }
        });
    }
    fetchQuestion = async () => {
        let _this = this;
        let conds = [
            {
                Key: "org",
                Val: this.state.organisation,
                Type: "string"
            },
        ];
        await this.fetchingAll("Question", conds, (data) => {
            console.log("fetchQuestion :> ", data);
            _this.setState({
                Questions: data
            });

        });
    }
    fetchSurvey = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Survey", conds, (data) => {
            console.log("fetchSurvey :> ", data);
            _this.setState({
                Surveys: data
            });

        });
    }
    fetchDataLink = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("AssetDataLink", conds, (data) => {
            console.log("fetchDataLink :> ", data);
            _this.setState({
                DataLinks: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
     addDataLink =async (e) => {
        e.preventDefault();
        let _this = this;
        const user =GetUserToken();
        const state = _this.state;
        let newEntry = state.newEntry;
         let hub={
             Org: user.OrgCode,
             Project: newEntry.Project,
             Survey: newEntry.Survey,
             AssetCategory:newEntry.Category,
             AssetAttribute: newEntry.currentAssetAttribute,
             QuestionName: newEntry.currentQuestion
         }

        newEntry.Data[newEntry.currentAssetAttribute] = newEntry.currentQuestion;
        newEntry.currentAssetAttribute = "";
        newEntry.currentQuestion = "";
        state.newEntry = newEntry;
        _this.setState(state);

         let backend = eConfig.mainBackend;
         let endpoint = "/api/macro-survey/asset/datalink/add";

         console.log("addDataLink send > ", hub);
         await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
             console.log("addDataLink response > ", dataIn);
             if (typeof dataIn.RESULT !== "undefined") {
                 _this.fetchDataLink();
                 alert("Data Link saved!");
             }
         });

        /*
        Project text,
	Survey text,
	AssetCategory text,
	AssetAttribute text,
	QuestionName text,
         */
    }
    removeDatalink =async (e, row) => {
        e.preventDefault();
        const _this = this;
        if(!window.confirm("Are you sure you want to remove this data link? ["+row.assetattribute+" -> "+row.questionname+"]")){
            return
        }

        let hub={
            Org:row.org,
            Project: row.project,
            Survey: row.survey,
            AssetCategory:row.assetcategory,
            AssetAttribute: row.assetattribute,
            QuestionName: row.questionname
        }

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/datalink/remove";

        console.log("removeDatalink send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("removeDatalink response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                _this.fetchDataLink();
                alert("Data Link removed!");
            }
        });
    }

    renderStepProject = () => {

        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newEntry = state.newEntry;
            newEntry.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newEntry.Id === index) {
                    newEntry.Project = row.Project;
                    newEntry.Category = row.Category;
                    newEntry.Headers = row.Data;
                }
                index++;
            }
            state.newEntry = newEntry;
            _this.setState(state);
        }
        const setValueSurvey = (e) => {
            const val = e.target.value;
            const state = _this.state;
            let newEntry = state.newEntry;
            newEntry.Survey = val;
            state.newEntry = newEntry;
            _this.setState(state);

        }
        const getValueProject = () => {
            return _this.state.newEntry.Id
        }
        const getValueSurvey = () => {
            return _this.state.newEntry.Survey
        }


        return (
            <div>


                <form onSubmit={this.saveStepProject}>

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <label htmlFor="inputState">Project & Category</label>
                            <select
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setValueProject(e)}
                                value={getValueProject()}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {this.state.StoreProjects.map((row, index) => {
                                    let key = row.Id;
                                    let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                                    return (
                                        <option key={index} value={index}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <label htmlFor="inputState">Select your Survey </label>
                            <select
                                id="inputState"
                                className="form-control"
                                onChange={(e) => setValueSurvey(e)}
                                value={getValueSurvey()}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {this.state.Surveys.map((row, index) => {
                                    let key = row.name;
                                    let desc = row.name.toLocaleUpperCase();
                                    return (
                                        <option key={index} value={key}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <hr/>

                </form>

            </div>
        )
    }

    renderFilter = () => {
        let _this = this;

        const setValue = (e, key) => {
            const val = e.target.value;
            const state = _this.state;
            let newEntry = state.newEntry;
            newEntry[key] = val;
            state.newEntry = newEntry;
            _this.setState(state);

        }
        const getValue = (key) => {
            return _this.state.newEntry[key];
        }


        let lsAssetAttr = [];
        for (let i in this.state.StoreProjects) {
            const row = this.state.StoreProjects[i];
            if (
                row.Project === this.state.newEntry.Project &&
                row.Category === this.state.newEntry.Category
            ) {
                for (let a in row.Data) {
                    lsAssetAttr.push(a);
                }
            }
        }

        let lsQuetion = [];
        for (let i in this.state.Questions) {
            const row = this.state.Questions[i];
            //console.log("&&&&&---> ",row.survey," > ",this.state.newEntry.Survey," >> ",(row.survey ===this.state.newEntry.Survey));
            if (row.survey === this.state.newEntry.Survey) {
                lsQuetion.push({
                    val: row.name,
                    desc: row.position + ". " + row.name
                });
            }
        }


        return (
            <div className={"row alert alert-info"}>
                <form onSubmit={this.addDataLink}>
                    <div className="form-row">
                        <div className="form-group col-lg-5 col-md-12">
                            <label htmlFor="inputState">Asset Attribute</label>
                            <select
                                className="form-control"
                                onChange={(e) => setValue(e, "currentAssetAttribute")}
                                value={getValue("currentAssetAttribute")}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {lsAssetAttr.map((item, index) => {
                                    let key = item;
                                    let desc = item;
                                    return (
                                        <option key={index} value={key}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-5 col-md-12">
                            <label htmlFor="inputState">Question</label>
                            <select

                                className="form-control"
                                onChange={(e) => setValue(e, "currentQuestion")}
                                value={getValue("currentQuestion")}
                                required={true}
                            >
                                <option value="" selected>Choose...</option>
                                {lsQuetion.map((row, index) => {
                                    let key = row.val;
                                    let desc = row.desc;
                                    return (
                                        <option key={index} value={key}>{desc}</option>
                                    )
                                })}

                            </select>
                        </div>
                    </div>

                    <div className="form-row">
                        <div className="form-group col-lg-2 col-md-12">
                            <label htmlFor="inputState">&nbsp;</label>
                            <button
                                className="form-control"
                                type={"submit"}
                            >Add
                            </button>
                        </div>
                    </div>

                </form>

            </div>
        )
    }


    renderAvailableDataLink = () => {
        let state = this.state.newEntry;
        let _this = this;
        let ls =[]; // this.state.DataLinks;
        for (let i in this.state.DataLinks) {
            const row = this.state.DataLinks[i];
            if(row.project ===state.Project && row.assetcategory===state.Category &&
            row.survey===state.Survey){
                ls.push(row);
            }

        }

        if (ls.length === 0) {
            return
        }

        return (
            <div className="row container">
                <div className="col col-lg-12 col-xs-12">
                    <table className={"table"}>
                        <thead>
                        <tr>
                            <th>Attribute</th>
                            <th>Question</th>
                            <th style={{width:50}}>#</th>
                        </tr>
                        </thead>
                        <tbody>
                        {ls.map((row, index) => {
                            return (
                                <tr>
                                    <td>{row.assetattribute}</td>
                                    <td>{row.questionname}</td>
                                    <td style={{width:50}}>
                                        <a
                                            className={"btn btn-default"}
                                            onClick={(e) => this.removeDatalink(e, row)}
                                        >Remove</a>
                                    </td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            </div>
        )

    }

    render() {

        return (
            <PageContentWallpaper {...this.props} >


                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2 style={{textAlign:"center"}}>
                                Data Link  Survey to Asset


                            </h2>
                        </div>

                    </div>

                    <div className="row container">
                        <div className="col col-lg-12 col-xs-12">

                            {this.renderStepProject()}


                        </div>
                    </div>

                    <div className={"row container alert alert-info"}>
                        <div className="col col-lg-12 col-xs-12">
                        {this.renderFilter()}
                        {this.renderAvailableDataLink()}
                        </div>
                    </div>



                </div>
            </PageContentWallpaper>
        )
    }

}