import React, {Component} from 'react';

import "../../asset/css/global.css"
import connect from "react-redux/es/connect/connect";
import {mapDispatchToProps, mapStateToProps} from "./redux-dispatch-to-props";


 class PageContentWallpaper extends Component {
    constructor(props){
        super(props);
    }
    navigatorLink = (myLink) => {
        window.location.href = "/#" + myLink
    }
    getPageModule =()=>{

        const module=this.props.navigations2.currentModule;
        if(module !==""){
            return module;
        }
        return "---"

    }
    getPageMenu =()=>{
        const module=this.props.navigations2.currentMenu;
        if(module !==""){
            return module;
        }
        return "---"


    }
    render() {
        return (
            <div className="container pageContentBox" style={{position:"relative",width:"100%",minHeight:"80%",height:"80%"}}>
                <div className={"pageInfoSelected"}>
                    <span className="myTitleLogin">{this.getPageModule()}</span>
                    <span>&nbsp;</span>
                    <span> | </span>
                    <span>&nbsp;</span>
                    <span>{this.getPageMenu()}</span>
                    <hr/>
                </div>


                {this.props.children}

                <br/>


            </div>

        );
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PageContentWallpaper)


/*

https://api.easipath.com/bidoffice/get/total/opportunities
    http://41.185.16.250:14001/bidoffice/get/total/opportunities*/
