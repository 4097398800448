import {SAVE_NAVIGATIONS} from "../constant";

const allRole = ["super", "admin", "capture", "enumerator"];
const adminRole = ["super", "admin"];
const superRole = ["super"];
const clientRole = ["client"];

const initial = {
    currentModule: "home",
    currentMenu: "",
    currentSubmenu: "",
    menus: {
        home: [
            {name: "Project", hasMenu: false, link: "home-project", roles: adminRole},
            {name: "Help", hasMenu: false, link: "home-help", roles: allRole},
        ],
        capture: [
            {name: "Survey", hasMenu: false, link: "capture-survey", roles: allRole},
            {name: "Task Scheduler", hasMenu: false, link: "capture-schedule", roles: allRole},
        ],
        database: [
            {name: "Assets", hasMenu: false, link: "database-data-asset", roles: adminRole},
            {name: "Search", hasMenu: false, link: "database-data-search", roles: adminRole},
            {name: "Data Capturers", hasMenu: false, link: "database-data-capture", roles: adminRole},
            {name: "Enumerators", hasMenu: false, link: "database-data-enumerator", roles: adminRole},
            {
                name: "CSV Structure", hasMenu: true, link: "", submenu: [
                    {name: "Assets", link: "database-csv-asset"},
                    {name: "Data Capturers", link: "database-csv-capture"},
                    {name: "Enumerators", link: "database-csv-enumerator"},
                ], roles: adminRole
            },
        ],
        setting: [
            {name: "Company", hasMenu: false, link: "setting-company", roles: adminRole},
            {
                name: "Project", hasMenu: true, link: "", roles: adminRole, submenu: [
                    {name: "Create Project Name", link: "setting-project"},
                    {name: "Set Dashboard View", link: "setting-project-dashboard"},
                ]
            },
            {
                name: "Asset", hasMenu: true, link: "", roles: adminRole, submenu: [
                    {name: "Import Csv", link: "setting-asset-import"},
                    {name: "Set Attribute", link: "setting-asset-attribute"},
                ]
            },
            {
                name: "Survey", hasMenu: true, link: "", roles: adminRole, submenu: [
                    {name: "Create Name of Survey", link: "setting-survey-new"},
                    {name: "Create Questionnaire", link: "setting-survey-question"},
                ]
            },

            {
                name: "Enumerator", hasMenu: true, link: "", roles: adminRole, submenu: [
                    {name: "Form & Import Csv", link: "setting-enumerator-import"},
                    {name: "Set Attribute", link: "setting-enumerator-attribute"},
                ]
            },
            {
                name: "Data Capturer", hasMenu: true, link: "", roles: adminRole, submenu: [
                    {name: "Form & Import Csv", link: "setting-capture-import"},
                    {name: "Set Attribute", link: "setting-capture-attribute"},
                ]
            },
        ],
        reporter: [
            {name: "Report Structure Setup", hasMenu: false, link: "report-structure", roles: adminRole},
            {name: "Export Report", hasMenu: false, link: "report-export", roles: adminRole},
            {name: "Query Wizard", hasMenu: false, link: "report-query", roles: adminRole},
        ],
        dashboard: [
            {
                name: "Project Overview", hasMenu: false, link: "dashboard-overview", roles: adminRole, submenu: []
            },
            {
                name: "Project Performance", hasMenu: false, link: "dashboard-performance", roles: adminRole, submenu: []
            },
        ]
    }
}


const reduceNavigations = (state = initial, action) => {
    let newState = state;
    switch (action.type) {
        case "SAVE_MODULE_MENU":
            const entry = action.payload;
            console.log("Reducer ::(--> ", action);
            newState = initial;
            newState.currentModule = entry.module;
            newState.currentMenu = entry.menu;
            newState.currentSubmenu = entry.submenu;
            return Object.assign({}, newState);
        default:
            return state
    }
}

export default reduceNavigations;