import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';
import '../../asset/css/stepbystepform.css';
import {Button, Modal} from "react-bootstrap";

import imgChoose from '../../asset/img/background.jpg'

export default class ComAssetCaptureAttribute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            StoreProjects: [],
            newEntry: {
                Project: "",
                Category: "",
                Headers:{},
                Total:0,
            },
        }

    }

    async componentDidMount() {
        await this.loadFilterProject();
    }

    loadFilterProject = async () => {
        let _this = this;
        let hub = {
            Org: GetUserToken().OrgCode,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/filter/project";

        console.log("loadFilterProject send > ", hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("loadFilterProject response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;
                _this.setState({
                    StoreProjects: data,
                });
            }
        });
    }
    handleInput = (e, key, val) => {
        e.preventDefault();
        let tmp = this.state;
        tmp[key] = val;
        this.setValue(tmp);
    }
    getValue = (key) => {
        return this.state.newEntry[key]
    }
    setValue = (e, key) => {
        let val = "";
        if (typeof e.target !== "undefined") {
            val = e.target.value;
        }
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }
    setValueBox = (e) => {
        const val = e.target.value;
        const key = e.target.name;

        console.log("setValueBox ^^^--> ", key, " > ", val)
        const state = this.state;
        let newEntry = state.newEntry;
        newEntry[key] = val;
        state.newEntry = newEntry;
        this.setState(state);
    }




    onClickProject=()=>{
        const state = this.state.newEntry;
        if(state.Project ===""){
            alert("Please select your project before proceed!")
        }

        /*
         case "SET-INFO-PROCATHEAD":
            newState.Project = entry.Project;
            newState.Category = entry.Category;
            newState.Headers = entry.Headers;
         */

        console.log("77777---&&--> ",state);
        let headers=[];
        for(let i in state.Headers){
            headers.push(i);
        }

        this.props.dispatch({
            type:"SET-INFO-PROCATHEAD",
            payload:{
                Project:state.Project,
                Category:state.Category,
                Headers:headers,
                Total:state.Total,
            },
        });

        window.location.href = "#home";
    }

    renderProjectBox = () => {
        let _this = this;
        const setValueProject = (e) => {
            const id = e.target.value;
            const state = _this.state;
            let newEntry = state.newEntry;
            newEntry.Id = parseInt(id);
            let index = 0;
            for (let i in state.StoreProjects) {
                const row = state.StoreProjects[i];
                if (newEntry.Id === index) {
                    newEntry.Project = row.Project;
                    newEntry.Category = row.Category;
                    newEntry.Headers = row.Data;
                    newEntry.Total = row.Total;
                }
                index++;
            }
            state.newEntry = newEntry;
            _this.setState(state);


        }
        const getValueProject = () => {
            return _this.state.newEntry.Id
        }



        return (
            <div className="row container">
                <div className="form-group col-md-11">
                    <label htmlFor="inputState">Select your Project</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValueProject(e)}
                        value={getValueProject()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {this.state.StoreProjects.map((row, index) => {
                            let key = row.Id;
                            let desc = "Project: " + row.Project + " -- Category:" + row.Category + " -- Total Data:" + row.Total;
                            return (
                                <option key={index} value={index}>{desc}</option>
                            )
                        })}

                    </select>
                </div>

                <div className="form-group col-md-1">
                    <label htmlFor="inputState">&nbsp;</label>
                    <button
                        type=""
                        className=" btn btn-primary"
                        onClick={(e) => this.onClickProject(e)}
                    >Go!</button>
                </div>

            </div>
        )
    }

    render() {

        const width = window.innerWidth, height=  window.innerHeight;
        const myWidth = (width / 100) * 75;
        return (
            <PageContentWallpaper {...this.props} >


                <div>

                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Home Project
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col col-lg-11 col-xs-10">
                            {this.renderProjectBox()}
                        </div>
                        <div className="col col-lg-1 col-xs-2">

                        </div>
                    </div>

                    {/*<div className="row container">
                        <div className="col-xs-12">
                            <img
                                src={imgChoose}
                                width={myWidth}
                            />
                        </div>
                    </div>*/}

                </div>
            </PageContentWallpaper>
        )


    }
}