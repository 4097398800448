import React, {Component} from "react";

const google = window.google;
 // 'lat': '-33.8809006', 'lng': '18.496540099999947'
var INITIAL_LOCATION = {
    address: '1 peddie road, milnerton, cape town',
    position: {
        latitude: -33.8809006,
        longitude: 18.496540099999947
    }
};

var INITIAL_MAP_ZOOM_LEVEL = 8;

var ATLANTIC_OCEAN = {
    latitude: -33.8809006,
    longitude: 18.496540099999947
};

export default class ComLibGeo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isGeocodingError: false,
            foundAddress: INITIAL_LOCATION.address,
            lat: 0,
            lng: 0
        }
    }


    componentDidMount = () => {
        this.map = new google.maps.Map(this.mapElement, {
            zoom: INITIAL_MAP_ZOOM_LEVEL,
            center: {
                lat: INITIAL_LOCATION.position.latitude,
                lng: INITIAL_LOCATION.position.longitude
            }
        });

        this.marker = new google.maps.Marker({
            map: this.map,
            position: {
                lat: INITIAL_LOCATION.position.latitude,
                lng: INITIAL_LOCATION.position.longitude
            }
        });

        this.geocoder = new google.maps.Geocoder();
    }

    handleFormSubmit = (submitEvent) => {
        submitEvent.preventDefault();
        var address = this.searchInputElement.value;
        this.geocodeAddress(address);
    }
    setSearchInputElementReference = (inputReference) => {
        this.searchInputElement = inputReference;
    }
    setMapElementReference = (mapElementReference) => {
        this.mapElement = mapElementReference;
    }
    geocodeAddress = (address) => {
        this.geocoder.geocode({'address': address}, function handleResults(results, status) {

            if (status === google.maps.GeocoderStatus.OK) {
                const lat = results[0].geometry.location.lat();
                const lng = results[0].geometry.location.lng();
                const address = results[0].formatted_address;
                this.setState({
                    foundAddress: address,
                    isGeocodingError: false,
                    lat: lat,
                    lng: lng
                });

                this.map.setCenter(results[0].geometry.location);
                this.marker.setPosition(results[0].geometry.location);

                return;
            }

            this.setState({
                foundAddress: null,
                isGeocodingError: true
            });

            this.map.setCenter({
                lat: ATLANTIC_OCEAN.latitude,
                lng: ATLANTIC_OCEAN.longitude
            });

            this.marker.setPosition({
                lat: ATLANTIC_OCEAN.latitude,
                lng: ATLANTIC_OCEAN.longitude
            });

        }.bind(this));
    }

    saveGeoToParent = (e) => {
        e.preventDefault();
        this.props.saveGeoToParent({
            lat: this.state.lat,
            lng: this.state.lng,
            address: this.state.foundAddress
        });
        this.props.goToNextStep();
    }


    renderAddres = () => {

        if (this.state.lat === 0) {
            return (
                <p className="bg-info">Please provide your address....</p>
            )
        }
        return (
            <p className="bg-info">
                {this.state.foundAddress}
                <span
                    style={{color: "red"}}> &nbsp;| &nbsp;Lat: {this.state.lat}&nbsp;| &nbsp;Lng: {this.state.lng}</span>
                <button
                    type="button"
                    className="btn btn-success btn-lg pull-right"
                    onClick={(e) => this.saveGeoToParent(e)}
                >
                    Save Location
                </button>
            </p>
        )

    }


    render() {
        return (


            <div className="container">

                <div className="row">
                    <div className="col-sm-12">

                        <form className="form-inline" onSubmit={this.handleFormSubmit}>
                            <div className="row">
                                <div className="col-xs-8 col-sm-10">

                                    <div className="form-group">
                                        <label className="sr-only" htmlFor="address">Address</label>
                                        <input
                                            type="text"
                                            className="form-control input-lg"
                                            id="address"
                                            placeholder="Milnerton , Cape town"
                                            ref={this.setSearchInputElementReference} required
                                        />
                                    </div>

                                </div>
                                <div className="col-xs-4 col-sm-2">

                                    <button type="submit" className="btn btn-default btn-lg">
                                        <span className="glyphicon glyphicon-search" aria-hidden="true"></span>
                                    </button>

                                </div>
                            </div>
                        </form>

                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">

                        {this.state.isGeocodingError ?
                            <p className="bg-danger">Address not found.</p> :

                            this.renderAddres()

                        }

                        <div className="map" ref={this.setMapElementReference}></div>

                    </div>
                </div>
            </div>


        )
    }
}