import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import ProcessSurvey from './com-survey-process';
import ChooseSurvey from './com-survey-choose';

export default class SurveyTakenComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            org: "easipath",
            survey: "",
            username: "biangacila@gmail.com",
            participant: "",
            assetRef: "",
            inProcess:false,
        }

    }

    setParentState=(key,val)=>{

        let tmp = this.state;
        tmp[key] = val;
        console.log("setParentState :> ",tmp);
        this.setState(tmp);
    }
    getParentState=(key)=>{
        if(typeof this.state[key] ==="undefined"){
            return null
        }
        return this.state[key]
    }

    renderProcessSurvey=()=>{
        if(!this.state.inProcess){
            return null;
        }
        return(
            <ProcessSurvey
                org={this.state.org}
                survey={this.state.survey}
                username={this.state.username}
                participant={this.state.participant}
                assetRef={this.state.assetRef}
                setParentState={this.setParentState}
                getParentState={this.getParentState}
            />
        )
    }
    renderChoosenSurvey=()=> {
        if (this.state.inProcess) {
            return null;
        }
        return(
            <ChooseSurvey
                org={this.state.org}
                setParentState={this.setParentState}
                getParentState={this.getParentState}
            />
        )
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>Questionnaire setup process:
                            <small>
                                Survey:{this.state.survey}; &nbsp;&nbsp;
                                Asset:{this.state.assetRef};&nbsp;&nbsp;
                                Participant:{this.state.participant}
                            </small>
                        </h2>
                    </div>

                </div>

                <div className="row">
                    <div className="col-xs-12">

                        {this.state.inProcess?
                            <ProcessSurvey
                                org={this.state.org}
                                survey={this.state.survey}
                                username={this.state.username}
                                participant={this.state.participant}
                                assetRef={this.state.assetRef}
                                setParentState={this.setParentState}
                                getParentState={this.getParentState}
                            />:
                            <ChooseSurvey
                                org={this.state.org}
                                setParentState={this.setParentState}
                                getParentState={this.getParentState}
                            />
                        }

                    </div>
                </div>

            </PageContentWallpaper
            >


        );
    }
}