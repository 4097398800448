
import React from 'react';
import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';
import ArrowDropRight from 'material-ui/svg-icons/navigation-arrow-drop-right';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import {connect} from "react-redux";
import {mapDispatchToProps, mapStateToProps} from "./redux-dispatch-to-props";

const style = {
    display: 'inline-block',
    /*margin: '16px 32px 16px 0',*/
};

class SideMenuNav extends React.Component {

    constructor(props) {
        super(props);

        this._handleClick = this._handleClick.bind(this);
    }

    componentDidMount(){

    }


    _handleClick(menuName) {
        console.log("SideMenuNav _handleClick > ",menuName);
        window.location.href = "#/"+menuName;
    }


    setModuleMenu = (module, menu, submenu,link) => {
        this.props.dispatch({
            type: "SAVE_MODULE_MENU",
            payload: {
                module: module,
                menu: menu,
                submenu
            }
        });
        window.location.href = "#/"+link;
    }

    renderMenu=()=>{
        let _this= this;
        const module=this.props.navigations2.currentModule;
        const menus=this.props.navigations2.menus[module];

        const innerOptions=(rows,menu)=>{
            const arr=[];
            for(let i in rows){
                const row = rows[i];
                arr.push((
                    <MenuItem
                        primaryText={row.name}
                        style={styles.menuItem3}
                        onClick={()=>_this.setModuleMenu(module,menu,row.name,row.link)}
                    />
                ))
            }

            return arr;
        }
        return menus.map((row1,index)=>{
            if(!row1.hasMenu){
                return(
                    <MenuItem
                        primaryText={row1.name}
                        style={styles.menuItem}
                        onClick={()=>_this.setModuleMenu(module,row1.name,"",row1.link)}
                    />

                )
            }

            return(
                <MenuItem
                    style={styles.menuItem}
                    rightIcon={<ArrowDropRight />}
                    primaryText={row1.name}
                    menuItems={innerOptions(row1.submenu,row1.name)}

                />
            )

        });
    }

    render() {
        const module=this.props.navigations2.currentModule;
        const width = window.innerWidth, height=  window.innerHeight;
        const myWidth = (width / 100) * 15;
        const menuBackground ={
            backgroundColor:"#13B5EA",
            color:"white",
            minWidth:myWidth,
            minHeight:150,
            borderRadius:10,
            borderWidth:1,
            borderColor:"yalow",
            marginRight:10
        }

        return (
            <MuiThemeProvider>

                <Paper style={style}>

                    <Menu desktop={true} width={280} style={menuBackground}>
                        <MenuItem
                            primaryText={module.toLocaleUpperCase() + " Menu"}
                            style={styles.menuItem2}
                        />

                        <Divider
                        />
                        {this.renderMenu()}

                    </Menu>
                </Paper>


            </MuiThemeProvider>
        );
    }

}


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(SideMenuNav)

const styles={
    menuBackground:{
        backgroundColor:"#13B5EA",
        color:"white"
    },
    menuItem:{
        color:"white",
        fontSize:12,
        fontWeight:""
    },
    menuItem3:{
        backgroundColor:"#13B5EA",
        color:"white",
        fontSize:12,
        fontWeight:"",
        menuItems:{
            backgroundColor:"#13B5EA",
            color:"white",
        }
    },
    menuItem2:{
        color:"#ffd9cc",
        fontSize:14,
        fontWeight:"bold"
    }
}

/*
<MenuItem primaryText="Contract Dashboard" style={styles.menuItem}  onClick={()=>this._handleClick("dashboard-contract")} />
                        <MenuItem
                            style={styles.menuItem}
                            rightIcon={<ArrowDropRight />}
                            primaryText="Employee Database"
                            menuItems={[
                                <MenuItem primaryText="View Employees" style={styles.menuItem} onClick={()=>this._handleClick("employee")}/>,
                                <MenuItem primaryText="Import CSV" style={styles.menuItem} onClick={()=>this._handleClick("employee-csv")} />,
                            ]}
                        />
                        <Divider />
                        <MenuItem
                            style={styles.menuItem}
                            rightIcon={<ArrowDropRight />}
                            primaryText="Employee T & A"
                            menuItems={[
                                <MenuItem primaryText="Employees Clocking" style={styles.menuItem} onClick={()=>this._handleClick("clocking")}/>,
                                <MenuItem primaryText="Employees Clocking CSV"  style={styles.menuItem}onClick={()=>this._handleClick("clocking-csv")}/>,
                                <MenuItem primaryText="Clocking Report" style={styles.menuItem}  onClick={()=>this._handleClick("clocking-report")} />,
                                <MenuItem primaryText="Qa Clocking" style={styles.menuItem} onClick={()=>this._handleClick("qa-clock")} />,
                            ]}
                        />

                        <MenuItem
                            style={styles.menuItem}
                            rightIcon={<ArrowDropRight />}
                            primaryText="Contract Reports"
                            menuItems={[
                                <MenuItem
                                    rightIcon={<ArrowDropRight />}
                                    primaryText="Payroll Payment with Submenu"
                                    menuItems={[
                                        <MenuItem primaryText="PayAcc Sys" style={styles.menuItem} onClick={()=>this._handleClick("contract-report-payroll-payment")}/>,
                                        <MenuItem primaryText="Procard"  style={styles.menuItem} onClick={()=>this._handleClick("contract-report-payroll-payment")} />,
                                    ]}
                                />,
                                <MenuItem primaryText="Payroll Payment" style={styles.menuItem} onClick={()=>this._handleClick("contract-report-payroll-payment")}/>,
                                <MenuItem primaryText="Payrool Invoice" style={styles.menuItem} onClick={()=>this._handleClick("contract-report-payroll-invoice")} />,
                                <MenuItem primaryText="Payslip" style={styles.menuItem} onClick={()=>this._handleClick("payslip")} />,
                            ]}
                        />

 */

