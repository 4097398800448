import React, {Component} from "react";

export default class ComLibInfo extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    chooseEnumerator=(e,row)=>{
        this.props.setEnumerator(row);
    }

    renderEnumerator = () => {

        let ls = this.props.Enumerators;
        if (ls.length === 0) {
            return (
                <p className={"alert alert-danger"}>Empty list! please select your project Or your selected project  doesn't have data</p>
            )
        }

        let curr ={
            name:"",surname:"",email:""
        }
        if(this.props.CurrentEnumerator !==null){
            curr = this.props.CurrentEnumerator;
        }


        return (
            <div className={"container"}>
                <p className={"alert alert-info"}>
                    Current Enumerator: <span className={"pull-right"}>{curr.name+" "+curr.surname+" -- "+curr.email}</span>
                </p>
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Email</th>
                    <th>Identity</th>
                    <th>Phone</th>
                    <th>Name</th>
                    <th>Surname</th>
                    <th style={{width: 50}}>#</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={"infoKeyT" + index}>
                            <td>{row.email}</td>
                            <td>{row.identity}</td>
                            <td>{row.phone}</td>
                            <td>{row.name}</td>
                            <td>{row.surname}</td>
                            <td style={{width: 50}}>
                                <a className={"btn btn-default"}
                                   onClick={(e) => this.chooseEnumerator(e, row)}
                                >Choose</a>
                            </td>
                        </tr>
                    )

                })}
                </tbody>
            </table>
            </div>
        )

    }

    render(){
        return this.renderEnumerator()
    }
}