import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {Button, Modal} from 'react-bootstrap';
import eConfig from "../../config";
import {GetUserToken, PostToBackend} from "../../api/services";

export default class SettingClientComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            organisation: "",
            Assets: [],
            Requirements: [],
            currentAsset: null,
            newAsset: {
                Ref: "",
                Lng: 0,
                Lat: 0,
                Address: "",
                Owner: [],
            },
            newOwner: {
                QuestionCode: "",
                Name: "",
                Score: 0,
                Correct: "",
                Position: "",
                Orientation: "",
                NextQuestion: "",
            },
            isList: true,
            selectedAsset: null,
            isManageOwner: false,
            isNewOwner: false,
            isNewAsset: false,
        }

    }

    componentWillMount() {
        const user = GetUserToken();
        this.setState({organisation: user.OrgCode});
    }

    async componentDidMount() {
        await this.fetchAssets();
        await this.fetchOwner();

    }

    fetchOwner = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("AssetRequirement", conds, (data) => {
            console.log("fetchOwner :> ", data);
            /*let ls = {};
            for (let i in data) {
                let row = data[i];
                ls[row.username] = row;
            }*/
            _this.setState({
                Requirements: data
            });

        });
    }
    fetchAssets = async () => {
        let _this = this;
        let conds = [{
            Key: "org",
            Val: this.state.organisation,
            Type: "string"
        }];
        await this.fetchingAll("Asset", conds, (data) => {
            console.log("fetchAssets :> ", data);
            _this.setState({
                Assets: data
            });

        });
    }
    fetchingAll = async (table, conds, callback) => {

        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            //console.log("fetchingAll response > ", table, dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    selectionBox = (label, sourceList, sourceField, sourceLabel, stateKey, handleFunc, conds) => {
        const state = this.state;

        let ls = [];
        let data = [];
        if (typeof this.state[sourceList] !== "undefined") {
            data = this.state[sourceList];
        }

        for (let i in data) {
            const row = data[i];
            const field = row[sourceField];
            const desc = row[sourceLabel];

            let boo = true;
            for (let a in conds) {
                const checkEntyKey = conds[a].entry;
                const checkStateKey = conds[a].state;
                const checkEntryVal = row[checkEntyKey];
                const checkStateVal = state[checkStateKey];
                if (checkEntryVal !== checkStateVal) {
                    boo = false;
                }

            }

            if (boo) {
                ls.push({
                    field: field,
                    desc: desc
                })
            }
        }

        const _this = this;
        const setValue = (e) => {
            let tmp = _this.state;
            tmp[stateKey] = e.target.value;
            _this.setState(tmp)
        }
        const getValue = () => {
            let tmp = _this.state;
            return tmp[stateKey];
        }

        return (
            <div className="form-row">
                <div className="form-group col-md-16">
                    <label htmlFor="inputState">{label}</label>
                    <select
                        id="inputState"
                        className="form-control"
                        onChange={(e) => setValue(e)}
                        value={getValue()}
                        required={true}
                    >
                        <option value="" selected>Choose...</option>
                        {ls.map((row, index) => {
                            return (
                                <option key={index} value={row.field}>{row.desc}</option>
                            )
                        })}

                    </select>
                </div>
            </div>
        )
    }
    manageOwner = (row) => {
        this.setState({
            selectedAsset: row,
            isManageOwner: true,
            isList: false,
        })
    }
    closeManageOwner = () => {
        this.setState({
            selectedAsset: null,
            isManageOwner: false,
            isList: true,
        })
    }
    newAssetRequest = () => {
        if (this.state.selectedAsset === "") {
            return alert("Please select your survey!")
        }
        let tmp = this.state;
        tmp.newAsset = {
            Survey: this.state.selectedAsset,
            Code: "",
            Name: "",
            Position: 0,
            Category: "",
            TimelineMeasure: "",
            TimelineValue: 0,
            Timeline: "",
            NextQuestion: "",
            BackQuestion: "",
            Score: 0,
        };
        tmp.isNewAsset = true;
        tmp.isList = false;
        this.setState(tmp);
    }
    cancelNewAsset = () => {
        let tmp = this.state;
        tmp.newAsset = {
            Ref: "",
            Lng: 0,
            Lat: 0,
            Address: "",
            Owner: [],
        };
        tmp.isNewAsset = false;
        tmp.isList = true;
        this.setState(tmp);
    }
    newOwnerRequest = () => {
        let newOption = this.state.newOwner;
        newOption.QuestionCode = this.state.selectedAsset.code;
        this.setState({isNewOwner: true, newOwner: newOption});
    }
    newOwnerHandle = (e, key, mType) => {
        let tmp = this.state;
        tmp.newOwner[key] = e.target.value;
        this.setState(tmp);
    }
    newAssetHandle = (e, key, mType) => {
        let tmp = this.state;
        tmp.newAsset[key] = e.target.value;
        this.setState(tmp);
    }
    removeAsset = async (row) => {
        if (!window.confirm("Are you sure that you want to delete this asset ? " + row.ref)) {
            return
        }
        const hub = {
            Org: row.org,
            Ref: row.ref,
        }

        let _this = this;
        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/delete";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitSearchDashboard response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchAssets();
            }
        });

    }

    removeOwner = async (name) => {
        if (!window.confirm("Are you sure that you want to delete this owner? " + name)) {
            return
        }
        let _this = this;
        let owners = [];
        for (let i in this.state.selectedAsset.owner) {
            const row = this.state.selectedAsset.owner[i];
            if (row.idnumber !== name) {
                owners.push(row);
            }
        }
        let hub = this.state.selectedAsset;
        hub.owner = owners;
        console.log("Submit removeOwner > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit removeOwner response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {

                await _this.fetchOwner();
                await _this.afterAddOption();
            }
        });
    }
    submitNewOwner = async (e) => {
        e.preventDefault();
        let  newOption = this.state.newOwner;
        newOption.Org = this.state.organisation;
        newOption.Asset = this.state.selectedAsset.ref;

        let _this = this;
        let hub = this.state.selectedAsset;
        hub.owner.push(newOption);
        console.log("Submit submitNewOwner > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitNewOwner response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchAssets();
                await _this.afterAddOption();
            }
        });
    }
    submitNewAsset = async (e) => {
        e.preventDefault();
        if (!window.confirm("Are you sure that you want to submit this question?")) {
            return
        }
        let _this = this;
        let hub = this.state.newAsset;
        hub.org = this.state.organisation;
        hub.Lng = parseFloat(hub.Lng);
        hub.Lat = parseFloat(hub.Lat);


        console.log("Submit submitNewAsset > ", hub);

        let backend = eConfig.mainBackend;
        let endpoint = "/api/macro-survey/asset/add";

        await PostToBackend(hub, backend, endpoint, async function (dataIn, err) {
            console.log("Submit submitNewAsset response > ", dataIn);
            if (typeof dataIn.RESULT !== "undefined") {
                await _this.fetchAssets();
                await _this.cancelNewAsset();
            }
        });
    }
    afterAddOption = async () => {
        let tmp = this.state;
        tmp.isNewOwner = false;
        tmp.newOwner = {
            Name: "",
            Score: 0,
            Correct: "",
            Position: "",
            Orientation: "",
            NextQuestion: ""
        }
        this.setState(tmp);

        let selectAsset = null;
        const lsQuestions = this.state.Assets;
        const actual = this.state.selectedAsset;
        for (let i in lsQuestions) {
            const row = lsQuestions[i];
            if (row.ref === actual.ref && row.org === actual.org) {
                selectAsset = row;
            }
        }

        tmp.selectedAsset = selectAsset;
        this.setState(tmp);
        //this.closeNewOption();
    }
    renderAssetList = () => {
        if (!this.state.isList) {
            return null
        }

        let ls = [];
        for (let i in this.state.Assets) {
            const row = this.state.Assets[i];
            ls.push(row);
        }


        if (ls.length === 0) {
            return (
                <p className={"alert alert-dander"}>No question available -- please select your survey</p>
            )
        }

        /*
        Ref text,
	Lng float,
	Lat float,
	Address text,
	Owner list<frozen <Owner>>,
         */

        return (
            <table className={"table"}>
                <thead>
                <tr>
                    <th>Ref</th>
                    <th>Address</th>
                    <th>Lng & Lat</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {ls.map((row, index) => {
                    return (
                        <tr key={index}>
                            <td>{row.ref}</td>
                            <td>{row.address}</td>
                            <td>{row.lng + "," + row.lat}</td>
                            <td>
                                <a
                                    className={"btn btn-default pull-right"}
                                    onClick={() => this.manageOwner(row)}
                                >Owners</a>
                            </td>

                            <td>
                                <a
                                    className={" pull-right"}
                                    style={{color: "blue", fontSize: 18, cursor: "pointer"}}
                                    onClick={() => this.removeAsset(row)}
                                >Remove</a>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        )
    }
    renderQuestionForm = () => {
        if (this.state.isList) {
            return null
        }
    }

    renderManageQuestionOption = () => {
        if (!this.state.isManageOwner) {
            return null;
        }
        if (this.state.selectedAsset === null) {
            return null;
        }

        return (
            <div className={""}>
                <hr/>
                <p className={"alert alert-info"}>
                    Manage question Options
                    <a className={"btn btn-danger pull-right"}
                       onClick={() => this.closeManageOwner()}
                    >Cancel</a>
                </p>
                <p>
                    Asset: <b>{this.state.selectedAsset.ref}</b> ; &nbsp;&nbsp;
                </p>

                <table className={"table"}>
                    <thead>
                    <tr>
                        <th>Id No.</th>
                        <th>Name</th>
                        <th>Surname</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Passport No.</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.selectedAsset.owner.map((row, index) => {
                        console.log(("):(--> ",row));
                        return (
                            <tr key={index}>
                                <td>{row.idnumber}</td>
                                <td>{row.name}</td>
                                <td>{row.surname}</td>
                                <td>{row.phone}</td>
                                <td>{row.email}</td>
                                <td>{row.passportnumber}</td>
                                <th>
                                    <a
                                        className={"btn btn-default pull-right"}
                                        onClick={() => this.removeOwner(row.idnumber)}
                                    >Remove</a>
                                </th>
                            </tr>
                        )
                    })}
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={6}>&nbsp;</td>
                        <td>
                            <a
                                className={"btn btn-info pull-right"}
                                onClick={() => this.newOwnerRequest()}
                            >Add</a>
                        </td>
                    </tr>

                    </tfoot>
                </table>
            </div>
        )
    }
    renderNewOptionForm = () => {

        /*
Org string
Asset string
Name string
Surname string
IdNumber string
PassportNumber string
Phone string
Email string
 */

        let fields = [
            {field: "Name", desc: "Name", type: "text", options: null, required: true},
            {field: "Surname", desc: "Surname", type: "text", options: null, required: true},
            {field: "IdNumber", desc: "Id Number", type: "text", options: null, required: true},
            {field: "PassportNumber", desc: "Passport Number", type: "text", options: null, required: false},
            {field: "Phone", desc: "Phone", type: "text", options: null, required: false},
            {field: "Email", desc: "Email", type: "text", options: null, required: false},
        ]

        return (
            <div className={"container"}>
                <form onSubmit={this.submitNewOwner}>
                    {fields.map((row, index) => {
                        if (row.type !== "option") {
                            return (
                                <div className="form-row">
                                    <div className="form-group col-md-16">
                                        <label htmlFor="inputState">{row.desc}</label>
                                        <input
                                            id="inputState"
                                            className="form-control"
                                            onChange={(e) => this.newOwnerHandle(e, row.field)}
                                            value={this.state.newOwner[row.field]}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">{row.desc}</label>
                                    <select
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.newOwnerHandle(e, row.field)}
                                        value={this.state.newOwner[row.field]}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((innerRow, x) => {
                                            return (
                                                <option key={x} value={innerRow.key}>{innerRow.val}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )

                    })}

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <button
                                type="submit"
                                className={"btn btn-info form-control"}
                            >Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
    renderNewAssetForm = () => {
        if (!this.state.isNewAsset) {
            return
        }
        /*
        Ref: "",
            Lng: 0,
            Lat: 0,
            Address: "",
            Owner: [],
         */
        let fields = [
            {field: "Ref", desc: "Unique Asset Identity", type: "text", options: null, required: true},
            {field: "Lng", desc: "Geo coordinate Longitude", type: "text", options: null, required: false},
            {field: "Lat", desc: "Geo coordinate Latitude", type: "text", options: null, required: false},
            {field: "Address", desc: "Complete Address", type: "text", options: null, required: true},
        ];

        return (
            <div className={"container"}>
                <p className="alert alert-info">New Question
                    <a onClick={() => this.cancelNewAsset()}
                       className={"btn btn-danger pull-right"}>Cancel</a>
                </p>
                <form onSubmit={this.submitNewAsset}>
                    {fields.map((row, index) => {
                        if (row.type !== "option") {
                            return (
                                <div className="form-row">
                                    <div className="form-group col-md-16">
                                        <label htmlFor="inputState">{row.desc}</label>
                                        <input
                                            id="inputState"
                                            className="form-control"
                                            onChange={(e) => this.newAssetHandle(e, row.field)}
                                            value={this.state.newAsset[row.field]}
                                            required={row.required}
                                        />
                                    </div>
                                </div>
                            )
                        }
                        return (
                            <div className="form-row">
                                <div className="form-group col-md-16">
                                    <label htmlFor="inputState">{row.desc}</label>
                                    <select
                                        id="inputState"
                                        className="form-control"
                                        onChange={(e) => this.newAssetHandle(e, row.field)}
                                        value={this.state.newAsset[row.field]}
                                        required={row.required}
                                    >
                                        <option value="" selected>Choose...</option>
                                        {row.options.map((innerRow, x) => {
                                            return (
                                                <option key={x} value={innerRow.key}>{innerRow.val}</option>
                                            )
                                        })}

                                    </select>
                                </div>
                            </div>
                        )

                    })}

                    <div className="form-row">
                        <div className="form-group col-md-16">
                            <button
                                type="submit"
                                className={"btn btn-info form-control"}
                            >Save
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        )

    }

    closeNewOption = () => {
        this.setState({
            isNewOwner: false
        })
    }
    renderModalNewOption = () => {
        return (
            <Modal show={this.state.isNewOwner} onHide={this.closeNewOption}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        New Option
                        [{this.state.selectedAsset !== null && (
                        <span>Survey: <b>{this.state.selectedAsset.survey}</b>;&nbsp;Question:<b>{this.state.selectedAsset.name}</b> </span>
                    )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height: "75%", overflow: "scroll"}}>

                    <div className="row">
                        <div className="col-lg-12">
                            {this.renderNewOptionForm()}
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.closeNewOption}>Close</Button>
                </Modal.Footer>
            </Modal>
        )
    }

    render() {
        return (


            <PageContentWallpaper {...this.props} >

                <div className="row">
                    <div className="col-xs-12">
                        <h2>
                            Asset
                            <a
                                className={"btn btn-info pull-right"}
                                onClick={() => this.newAssetRequest()}
                            >New</a>
                        </h2>
                    </div>
                </div>


                <div className="row">
                    <div className="col-xs-12">
                        {this.renderAssetList()}
                        {this.renderQuestionForm()}
                        {this.renderManageQuestionOption()}
                        {this.renderModalNewOption()}
                        {this.renderNewAssetForm()}
                    </div>
                </div>


            </PageContentWallpaper>


        );
    }
}