
import React from "react";

const Footer=(props)=>{

    return(
        <div className={"myFooterBox"}>
            <p>Powered by BizMinder |
                <span className={"footerEmailLink"}>Email: admin@bizminder.co.za</span> |
                <span className={"footerPhoneLink"}>Phone: + 27 21 830 5324</span> |
            </p>
        </div>
    )
}

export default Footer;