import React, {Component} from 'react';
import {Router, Route, Switch} from 'react-router';
import createHistory from 'history/createHashHistory';

import {Provider} from 'react-redux';
import { createStore,applyMiddleware } from 'redux';
import logger from 'redux-logger'
import rootReducer from './reducers';

import loginScreen from './container/con-login';
import registerScreen from './container/con-register';
import pageNotFindScreen from './container/con-401';
import homeScreen from './container/con-home';
import RequireProject from './api/project-select';
import ScreenSettingSurveyNew from './container/con-setting-survey-new';
import ScreenSettingSurveyQuestion from './container/con-setting-survey-question';
import ScreenSettingAssetRequirement from './container/con-setting-asset-requirement';
import ScreenSettingAsset from './container/con-setting-asset';
import ScreenSettingAssetAttribute from './container/con-setting-asset-attribute';

import ScreenAssetManage from './container/con-asset-manage';
import ScreenAssetImport from './container/con-asset-import-csv';
import ScreenAssetSurvey from './container/con-asset-survey';
import ScreenAssetDatalink from './container/con-asset-datalink';
import ScreenAssetEnumerator from "./container/con-asset-enumerator";
import ScreenAssetEnumeratorAttribute from "./container/con-asset-enumerator-attribute";
import ScreenAssetHomeProject from "./container/con-asset-home-project";

import ScreenAssetCapture from "./container/con-asset-capture";
import ScreenAssetCaptureAttribute from "./container/con-asset-capture-attribute";

import ScreenAssetExtractionWizard from "./container/con-asset-extraction-wizard";
import ScreenAssetExtractionExport  from "./container/con-asset-extraction-export";

import ScreenSurveyTaken from './container/con-survey-taken';
import ScreenSettingProject from './container/con-setting-project';
import ScreenSettingProjectDashboard from './container/con-setting-project-dashboard';


import ScreenUtilAssetCategory from "./container/con-util-asset-category";

import ScreenDatabaseCsvEnumerator from "./container/con-database-csv-enumerator";
import ScreenDatabaseCsvCapture from "./container/con-database-csv-capture";
import ScreenDatabaseCsvAsset from "./container/con-database-csv-asset";
import ScreenDatabaseDataAsset from "./container/con-database-data-asset";
import ScreenDatabaseDataEnumerator from "./container/con-database-data-enumerator";
import ScreenDatabaseDataCapture from "./container/con-database-data-capture";
import ScreenDatabaseDataSearch from "./container/con-database-data-search";

import ScreenDashboardOverview from "./container/con-dashboard-overview";


const history = createHistory();
const store = createStore(
    rootReducer,
    applyMiddleware(logger)
);

class App extends Component {
    render() {
        return (
            <Provider store={store}>
            <Router history={history}>
                <Switch>

                    <Route exact path="/" component={loginScreen}/>
                    <Route path="/login" component={loginScreen}/>
                    <Route path="/register" component={registerScreen}/>
                    <Route path="/home" component={homeScreen}/>

                    <Route path="/capture-survey" component={RequireProject(ScreenAssetSurvey)}/>
                    <Route path="/setting-project" component={(ScreenSettingProject)}/>
                    <Route path="/setting-project-dashboard" component={RequireProject(ScreenSettingProjectDashboard)}/>
                    <Route path="/setting-survey-new" component={(ScreenSettingSurveyNew)}/>
                    <Route path="/setting-survey-new" component={(ScreenSettingSurveyNew)}/>
                    <Route path="/setting-survey-question" component={(ScreenSettingSurveyQuestion)}/>
                    <Route path="/setting-asset-import" component={ScreenAssetImport}/>
                    <Route path="/setting-enumerator-import" component={RequireProject(ScreenAssetEnumerator)}/>
                    <Route path="/setting-enumerator-attribute" component={RequireProject(ScreenAssetEnumeratorAttribute)}/>
                    <Route path="/setting-capture-import" component={RequireProject(ScreenAssetCapture)}/>
                    <Route path="/setting-capture-attribute" component={RequireProject(ScreenAssetCaptureAttribute)}/>
                    <Route path="/home-project" component={ScreenAssetHomeProject}/>
                    <Route path="/database-csv-enumerator" component={RequireProject(ScreenDatabaseCsvEnumerator)}/>
                    <Route path="/database-csv-capture" component={RequireProject(ScreenDatabaseCsvCapture)}/>
                    <Route path="/database-csv-asset" component={RequireProject(ScreenDatabaseCsvAsset)}/>
                    <Route path="/database-data-asset" component={RequireProject(ScreenDatabaseDataAsset)}/>
                    <Route path="/database-data-enumerator" component={RequireProject(ScreenDatabaseDataEnumerator)}/>
                    <Route path="/database-data-capture" component={RequireProject(ScreenDatabaseDataCapture)}/>
                    <Route path="/database-data-search" component={RequireProject(ScreenDatabaseDataSearch)}/>
                    <Route path="/dashboard-overview" component={RequireProject(ScreenDashboardOverview)}/>




                    <Route path="/report-structure" component={RequireProject(ScreenAssetExtractionWizard)}/>
                    <Route path="/report-export" component={RequireProject(ScreenAssetExtractionExport)}/>





                    <Route path="/setting-asset-requirement" component={ScreenSettingAssetRequirement}/>
                    <Route path="/setting-asset" component={ScreenSettingAsset}/>
                    <Route path="/setting-asset-attribute" component={ScreenSettingAssetAttribute}/>

                    <Route path="/asset-manage" component={ScreenAssetManage}/>
                    <Route path="/asset-import" component={ScreenAssetImport}/>
                    <Route path="/asset-survey" component={ScreenAssetSurvey}/>
                    <Route path="/asset-datalink" component={ScreenAssetDatalink}/>
                    <Route path="/asset-enumerator" component={ScreenAssetEnumerator}/>
                    <Route path="/asset-enumerator-attribute" component={ScreenAssetEnumeratorAttribute}/>
                    <Route path="/asset-data-extraction" component={ScreenAssetExtractionWizard}/>
                    <Route path="/survey-taken" component={ScreenSurveyTaken}/>
                    <Route path="/util-asset-category" component={ScreenUtilAssetCategory}/>




                    <Route component={pageNotFindScreen}/>
                </Switch>
            </Router>
            </Provider>

        );
    }
}

export default App;

/*
<div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p>
            Edit <code>src/App.js</code> and save to reload.
          </p>
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </header>
 */