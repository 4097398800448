import React from 'react';
import {PostRequestHTTP, GetUserToken} from "../../api/services";
import Button from '@material-ui/core/Button';
import ModalBootsrap from './modal-bootstrap';
import AddingModelFilter from './adding-model-filter';
import AddingModelMap from './adding-model-map';
import {connect} from 'react-redux'
import addingModel from "../../reducers/reducer/reducer-adding-model";
import {mapDispatchToProps, mapStateToProps} from "./redux-dispatch-to-props";


class AddingModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            OrgCode: "",
            List: [],
            openclose: false,
            dataView: {},
            editMode: false,
            EntryData: {},
            isList: true,
            CurrentEditData: null,
            filterField: "",
        }
        this.fetchModelList = this.fetchModelList.bind(this);
        this.SubmitNewEntry = this.SubmitNewEntry.bind(this);
        this.DeleteModel = this.DeleteModel.bind(this);
        this.modelMap = React.createRef();
    }

    componentWillReceiveProps(nextProps) {
        let tmp = this.state;
        tmp.EntryData = nextProps.EntryData;
        this.setState(tmp);
        this.loadSelectedDate();
    }

    async componentDidMount() {

        this.setState({...this.props});
        console.log("componentDidMount > ", this.props, " > ", this.state.EntryData);
        const user = GetUserToken();
        let tmp = this.state;
        tmp.OrgCode = user.OrgCode;

        this.setState(tmp);
        await this.fetchModelList();
    }

    sendDataToModelMap = (data) => {
        //this.modelMap.current.receivingData(data);


    }

    async fetchModelList() {
        const _this = this;
        let endpoint = this.props.Crum;

        let data = this.state;
        let conds = [
            {DataType: "string", key: "org", val: this.state.OrgCode},
        ]
        const hub = {
            [this.props.PrimaryKey]: this.state.OrgCode,
            ServiceName: "select",
            TableName: this.props.ModelName,
            Data: data,
            ParamUpdate: [],
            ParamConditions: conds
        }


        console.log("fetchModelList > ", hub, endpoint);

        await PostRequestHTTP(hub, endpoint, function (data) {

            if (data === null) {
                return
            }
            const res = data.RESULT;
            console.log("submitForm response > ", data);

            //TODO sort asc one object
            //https://flaviocopes.com/how-to-sort-array-of-objects-by-property-javascript/
            if (typeof _this.props.SortAsc !== "undefined") {
                const o = _this.props.SortAsc;
                if (o.two === "") {//only one key supply
                    const keyOne = o.one;
                    res.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
                } else {// two properties supply
                    const keyOne = o.one;
                    const keyTwo = o.two;
                    res.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : (a[keyOne] === b[keyOne]) ? ((a[keyTwo] > b[keyTwo]) ? 1 : -1) : -1)
                }
            }

            //TODO sort Desc one object
            if (typeof _this.props.SortDesc !== "undefined") {
                const o = _this.props.SortDesc;
                if (o.two === "") {//only one key supply
                    const keyOne = o.one;
                    res.sort((a, b) => (a[keyOne] < b[keyOne]) ? 1 : -1)
                } else {// two properties supply
                    const keyOne = o.one;
                    const keyTwo = o.two;
                    res.sort((a, b) => (a[keyOne] < b[keyOne]) ? 1 : (a[keyOne] === b[keyOne]) ? ((a[keyTwo] < b[keyTwo]) ? 1 : -1) : -1)
                }
            }

            //TODO sort two properties


            _this.setState({List: res})

        });
    }

    async SubmitNewEntry(e) {
        e.preventDefault();
        const _this = this;
        let endpoint = this.props.Crum;

        const user = GetUserToken();

        let data = this.state.EntryData;
        data[this.props.PrimaryKey] = user.OrgCode;


        const hub = {
            ServiceName: "insert",
            TableName: this.props.ModelName,
            Data: data,

        }

        console.log("CombineKey ALL: ", hub.Data);

        if (typeof this.props.CombineKey !== "undefined") {
            for (let i in this.props.CombineKey) {
                const row = this.props.CombineKey[i];
                const key = row.key;
                const separator = row.separator;
                let value = "";
                let index = 0;
                for (let e in row.combineWith) {
                    const item = row.combineWith[e];
                    if (index === 0) {
                        value = hub.Data[item];
                    } else {
                        value = value + separator + hub.Data[item]
                    }
                    index++;

                }
                hub.Data[key] = value

            }
        }


        await PostRequestHTTP(hub, endpoint, function (data) {
            const res = data;
            alert("Sucess! Entry add");
            _this.setState({EntryData: {}, isList: true});
            _this.fetchModelList();

        });

        if (typeof this.props.AdditionalEndpoint !== "undefined") {
            const lsEndpoint = this.props.AdditionalEndpoint;
            await PostRequestHTTP(hub.Data, lsEndpoint, function (data) {
                const res = data;
                console.log("AdditionalEndpoint Sucess!> ", data);

            });
        }
    }

    async DeleteModel(inObject) {
        const text = window.confirm("Are you sure that you want to delete this ?");
        if (!text) {
            return;
        }
        let paramCondition = [];
        for (let i in this.props.ListDeleteKey) {
            const row = this.props.ListDeleteKey[i];
            const val = inObject[row.field];
            paramCondition.push({
                Key: row.field,
                Val: val,
                DataType: row.type
            });
        }

        const _this = this;
        let endpoint = this.props.Crum;

        let data = this.state;
        const hub = {
            [this.props.PrimaryKey]: this.state.OrgCode,
            ServiceName: "delete",
            TableName: this.props.ModelName,
            Data: data,
            ParamUpdate: [],
            ParamConditions: paramCondition
        }

        console.log("DeleteModel send > ", hub);
        await PostRequestHTTP(hub, endpoint, function (data) {
            const res = data.RESULT;

            _this.fetchModelList();

        });

    }

    getModelName = () => {
        if (typeof this.state.ModelName === "undefined") {
            return "..."
        }

        return this.state.ModelName;
    }
    handleInput = (e, key, parseData) => {
        console.log("HandleInput > ", key, " > ", e.target.value, parseData);
        let tmp = this.state;
        let myVal;

        if (parseData !== undefined) {
            if (parseData === "float") {
                myVal = parseFloat(e.target.value);
            } else if (parseData === "int") {
                myVal = parseInt(e.target.value);
            } else {
                myVal = e.target.value
            }
        } else {
            myVal = e.target.value
        }
        tmp.EntryData[key] = myVal;
        this.setState(tmp);

    }

    handleInputInner = (key, val) => {
        console.log("handleRequired  inner > ", key, val, this.state);
        let tmp = this.state;
        if (typeof tmp.EntryData === "undefined") {
            tmp.EntryData = {}
        }
        tmp.EntryData[key] = val;
        this.setState(tmp);
    }
    loadSelectedDate = () => {
        let tmp = this.state;
        if (this.props.addingModel.Current === null) {
            return
        }


        const current = this.props.addingModel.Current;
        //todo let make sure that our target object is defined
        if (typeof tmp.EntryData === "undefined") {
            tmp.EntryData = {}
        }

        //todo let put our data from the model
        for (let i in this.state.MyModel) {
            const row = this.state.MyModel[i];
            if (row.type !== "map") {
                const key = row.field;
                let val = "";
                if (typeof current[key] !== "undefined") {
                    val = current[key];
                }
                tmp.EntryData[key] = val
            }
        }

        this.setState(tmp)

    }
    getValue = (key) => {
        if (typeof this.state.EntryData === "undefined") {
            return ""
        }
        if (typeof this.state.EntryData[key] === "undefined") {
            return ""
        }
        return this.state.EntryData[key];
    }
    renderFormInput = () => {

        if (typeof this.state.MyModel === "undefined") {
            return (<div/>)
        }


        return (
            <form onSubmit={this.SubmitNewEntry}>
                {this.state.MyModel.map((row, index) => {

                    if (typeof row.filter !== "undefined") {
                        if (row.filter) {
                            return (
                                <AddingModelFilter
                                    Crum={this.props.Crum}
                                    row={row} handleInput={this.handleInput}
                                    getValue={this.getValue} field={row.field}
                                    state={this.state}
                                />
                            )
                        }
                    }
                    if (row.type === "map") {
                        return (
                            <AddingModelMap

                                ref={instance => {
                                    this.modelMap = instance;
                                }}
                                dispatch={this.props.dispatch}
                                addingInfo={this.props.addingInfo}
                                mapInfo={row.staticMap}
                                mapInfoDb={row.staticMapDb || null}
                                handleInputInner={this.handleInputInner}
                                field={row.field}
                                addingModel={{Current: this.state.CurrentEditData}}
                                state={this.state}
                                MyParent={this}
                            />
                        )
                    }
                    if (row.type === "option") {
                        return (
                            <div className="form-group" key={index}>
                                <label>{row.desc}</label>

                                <select
                                    type={row.type}
                                    className="form-control"
                                    id={row.field}
                                    aria-describedby={row.field + "Help"}
                                    placeholder={row.placeholder}
                                    required={row.required}
                                    onChange={(e) => this.handleInput(e, row.field, row.type)}
                                    value={this.getValue(row.field)}
                                >
                                    <option value={""}> -- Select --</option>
                                    {row.options.map((item, iKey) => {
                                        return (
                                            <option value={item.val} key={iKey}> {item.desc}</option>
                                        )
                                    })}

                                </select>

                            </div>
                        )
                    }


                    return (
                        <div className="form-group" key={index}>
                            <label>{row.desc}</label>
                            <input
                                type={row.type}
                                className="form-control"
                                id={row.field}
                                aria-describedby={row.field + "Help"}
                                placeholder={row.placeholder}
                                required={row.required}
                                onChange={(e) => this.handleInput(e, row.field, row.type)}
                                value={this.getValue(row.field)}
                            />

                        </div>
                    )
                })}
                <Button
                    variant="contained"
                    color="secondary"
                    className="pull-right"
                    type="submit"
                >
                    Submit
                </Button>
            </form>
        )
    }
    viewSelectedModel = (row) => {
        delete row.status;
        delete row.date;
        delete row.time;
        delete row.orgdatetime;
        delete row.id;
        delete row.org;


        this.setState({openclose: true, dataView: row});
    }
    editSelectedModel = (row) => {
        console.log("EDIT ASSET --> ", row);
        this.props.dispatch({
            type: "ADDING-MODEL-CURRENT",
            payload: row,
        });

        let tmp = this.state;
        tmp.isList = false;
        tmp.editMode = true;
        tmp.EntryData = row;
        tmp.CurrentEditData = row;
        this.setState(tmp);
        this.sendDataToModelMap(row);
    }
    closeModal = () => {
        this.setState({openclose: false, dataView: {}});
    }
    open = () => {
        this.setState({openclose: true, dataView: {}});
    }

    filterMe = (me) => {
        if (this.state.filterField === "") {
            return true
        }

        let myString = JSON.stringify(me);
        let searchString = myString.toLowerCase();
        let searchKey = this.state.filterField.toString().toLowerCase();
        let index = searchString.indexOf(searchKey);
        let resultIndex = parseFloat(index);
        if (resultIndex === -1) {
            return false
        }
        return true

    }

    renderListWithCode = () => {
        return (
            <div>
                <div className="form-group row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Filter</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="inputPassword"
                            placeholder="Search..."
                            value={this.state.filterField}
                            onChange={(e) => this.setState({filterField: e.target.value})}
                        />
                    </div>
                </div>

                <table className={"table"} style={styles.tableList}>
                    <thead>
                    <tr>
                        <th>

                        </th>
                    </tr>
                    <tr>
                        {this.props.ListDisplayKeyWithCode.map((item, index) => {

                            //console.log("F!!! --> ", item);

                            return (
                                <th key={index + "#" + item.key}>{item.display}</th>
                            )
                        })}
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.state.List.map((row, index) => {
                        if (!this.filterMe(row)) {
                            return null
                        }
                        return (
                            <tr key={index}>
                                {this.props.ListDisplayKeyWithCode.map((item, index) => {
                                    const myItem = item.key.toLocaleLowerCase();
                                    return (
                                        <td key={index + "#" + item.key}>{row[myItem]}</td>
                                    )
                                })}
                                <td>
                                    <a onClick={() => {
                                        alert("List view");
                                    }} className="glyphicon glyphicon-list clickMe"
                                       style={{fontSize: 24, color: "blue", fontWeight: "bold"}}
                                       onClick={() => this.viewSelectedModel(row)}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                    <a className="glyphicon glyphicon-pencil clickMe"
                                       style={{fontSize: 24, color: "black", fontWeight: "bold"}}
                                       onClick={() => {
                                           this.editSelectedModel(row)
                                       }}
                                    />
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <a onClick={() => {
                                        alert("List view");
                                    }} className="glyphicon glyphicon-minus-sign clickMe"
                                       style={{fontSize: 24, color: "red", fontWeight: "bold"}}
                                       onClick={() => this.DeleteModel(row)}
                                    />

                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
    renderList = () => {

        if (this.state.List === null) {
            return (
                <p>Empty List ...</p>
            )
        }


        if (this.state.List.length === 0) {
            return (
                <p>Empty List ...</p>
            )
        }


        if (typeof this.props.ListDisplayKeyWithCode !== "undefined") {
            return this.renderListWithCode();
        }

        console.log("F! --> ", this.state.List.length, " > ", this.props.ListDisplayKey.length);

        return (
            <div>

                <div className={"row"}>
                    <div className="col-sm-12">
                        <button
                            onClick={() => this.setState({isList: false})}
                            className={"pull-right btn btn-primary"}
                        >Create New</button>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputPassword" className="col-sm-2 col-form-label">Filter</label>
                    <div className="col-sm-10">
                        <input
                            type="text"
                            className="form-control"
                            id="inputPassword"
                            placeholder="Search..."
                            value={this.state.filterField}
                            onChange={(e) => this.setState({filterField: e.target.value})}
                        />



                    </div>

                </div>
                <div className={"row"}>
                    <div className={"col col-lg-12"}>
                    <div className="alert alert-warning">
                        Available {this.getModelName()} List

                    </div>
                    </div>

                </div>

                <div className={"row"}>
                    <div className={"col col-lg-12"}>

                        <table className={"table"} style={styles.tableList}>
                            <thead>
                            <tr>
                                <th>

                                </th>
                            </tr>
                            <tr>
                                {this.props.ListDisplayKey.map((item, index) => {

                                    //console.log("F!!! --> ", item);

                                    return (
                                        <th key={index + "#" + item}>{item}</th>
                                    )
                                })}
                                <th>&nbsp;</th>
                            </tr>
                            </thead>
                            <tbody>
                            {this.state.List.map((row, index) => {
                                if (!this.filterMe(row)) {
                                    return null
                                }
                                return (
                                    <tr key={index}>
                                        {this.props.ListDisplayKey.map((item, index) => {
                                            const myItem = item.toLocaleLowerCase();
                                            return (
                                                <td key={index + "#" + item}>{row[myItem]}</td>
                                            )
                                        })}
                                        <td>
                                            <a onClick={() => {
                                                alert("List view");
                                            }} className="glyphicon glyphicon-list clickMe"
                                               style={{fontSize: 24, color: "blue", fontWeight: "bold"}}
                                               onClick={() => this.viewSelectedModel(row)}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                                            <a className="glyphicon glyphicon-pencil clickMe"
                                               style={{fontSize: 24, color: "black", fontWeight: "bold"}}
                                               onClick={() => {
                                                   this.editSelectedModel(row)
                                               }}
                                            />
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <a onClick={() => {
                                                alert("List view");
                                            }} className="glyphicon glyphicon-minus-sign clickMe"
                                               style={{fontSize: 24, color: "red", fontWeight: "bold"}}
                                               onClick={() => this.DeleteModel(row)}
                                            />

                                        </td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }

    canDisplayList = () => {

        /*if(typeof this.props.displayList !=="undefined"){
            if(!this.props.displayList){
                return null;
            }
        }*/

        if (this.state.isList) {
            return (
                <div className="col col-lg-12">

                    {this.renderList()}
                </div>
            )
        }


        return (
            <div className={"col col-lg-12"}>

                <p className="alert alert-warning">New {this.getModelName()}
                    <button
                        onClick={() => this.setState({isList: true})}
                        className={"pull-right btn btn-danger"}
                    >Cancel
                    </button>
                </p>

                {this.renderFormInput()}

            </div>
        )

    }

    render() {

        return (
            <div className="container">


                <div className="row">
                    {this.canDisplayList()}

                </div>

                <ModalBootsrap
                    openclose={this.state.openclose}
                    close={this.closeModal}
                    open={this.open}
                    data={this.state.dataView}
                />


            </div>
        )
    }
}

/*const mapDispatchToProps = dispatch => ({
    dispatch: dispatch
})

const mapStateToProps = state => ({
    loginInfo: state.loginInfo,
    addingModel:state.addingModel
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddingModel)*/


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AddingModel)


//export default AddingModel;


const styles = {
    newEntryTile: {
        color: "white",
        fontSize: 18,
        fontweight: "bold",
        textAlign: "center",
        backgroundColor: "gray"
    },
    tableList: {}
}