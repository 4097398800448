import React, {Component} from 'react';
import '../../asset/css/login.css';
import PageContentWallpaper from '../common/ef-pageContent';
import {GetUserToken, PostToBackend} from "../../api/services";
import eConfig from '../../config';

export default class ComDatabaseDataAsset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            filters: [],
            Attributes: [],
            searchValue: "",
        }
    }

    async componentDidMount() {
        await this.fetchAssetAttributes();
        await this.fetchData();
    }

    fetchingAll = async (table, conds, callback) => {
        let hub = {
            Fields: [],
            DbName: "survey",
            TableName: table,
            Conditions: conds,
        }
        let backend = eConfig.mainBackend;
        let endpoint = "/api/global-service/list";

        console.log("fetchingAll send > ", table, hub);
        await PostToBackend(hub, backend, endpoint, function (dataIn, err) {
            console.log("fetchingAll response > ", table, dataIn);
            if (dataIn === null) {
                console.log("fetchingAll response is null :> ", dataIn, hub);
                dataIn = [];
            }
            if (typeof dataIn.RESULT !== "undefined") {
                const data = dataIn.RESULT;

                callback(data);
            } else {
                callback([]);
            }
        });
    }
    fetchAssetAttributes = async () => {
        let _this = this;
        let project = this.props.info.Project;
        let category = this.props.info.Category;

        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },
            {
                Key: "project",
                Val: project,
                Type: "string"
            },
            {
                Key: "AssetCategory",
                Val: category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetCaptureAttribute", conds, (data) => {
            console.log("fetchAssetAttributes :> ", conds, " > ", data);

            _this.setState({
                Attributes: data
            });

        });
    }
    fetchData = async () => {
        let _this = this;
        let project = this.props.info.Project;
        let category = this.props.info.Category;

        let conds = [
            {
                Key: "org",
                Val: GetUserToken().OrgCode,
                Type: "string"
            },
            {
                Key: "project",
                Val: project,
                Type: "string"
            },
            {
                Key: "AssetCategory",
                Val: category,
                Type: "string"
            },
        ];
        await this.fetchingAll("AssetCapture", conds, (data) => {
            console.log("fetchData response :> ", conds, " > ", data);
            let ls = [];
            for (let i in data) {
                const row = data[i];
                let o = {};
                o.code = row.code;
                for (let item in row.data) {
                    const innerVal = row.data[item];
                    o[item.toLowerCase()] = innerVal;
                }
                ls.push(o)
            }
            _this.setState({
                Data: ls
            });

        });
    }

    handleChangeInput = (e) => {
        let tmp = this.state;
        tmp.searchValue = e.target.value;
        this.setState(tmp);
    }
    filterMe = (me) => {
        const keySearch = this.state.searchValue;
        let myString = JSON.stringify(me);
        let searchString = myString.toLowerCase();
        let searchKey = keySearch.toString().toLowerCase();
        let index = searchString.indexOf(searchKey);
        let resultIndex = parseFloat(index);
        if (resultIndex === -1) {
            return false
        }
        return true
    }

    renderData = () => {
        let ls = this.state.Data;
        let headers = [];
        if (ls.length === 0) {
            return null
        }
        for (let i in ls[0]) {
            const row = ls[0][i];
            headers.push(i.toLowerCase());
        }

        let output = ls;
        if(this.state.searchValue !==""){
            output = [];
            for(let i in ls){
                const row = ls[i];
                if(this.filterMe(row)){
                    output.push(row)
                }
            }
        }


        return (
            <div>
                <div className="row">
                    <div className="col-sm-10 col-lg-12">

                        <form className="form-inline">
                            <div className="form-group mb-2 col-lg-3">
                                <label htmlFor="staticEmail2 pull-right">Search Filter</label>
                            </div>
                            <div className="form-group mx-sm-3 mb-2 col-lg-6">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="inputPassword2"
                                    placeholder="search for ..."
                                    value={this.state.searchValue}
                                    onChange={(e) => this.handleChangeInput(e)}
                                />
                            </div>
                            <div className="form-group mx-sm-3 mb-2 col-lg-2">
                                <button
                                    type="submit"
                                    className="btn btn-primary mb-2 pull-left"
                                >
                                    Search
                                </button>
                            </div>
                            <hr/>
                        </form>

                    </div>

                </div>

                <div className=" row">
                    <div className="col-sm-10 col-lg-12">
                        <table className={"table"}>
                            <thead>
                            <tr>
                                {headers.map((item, index1) => {
                                    return (
                                        <th key={"thh" + index1}>{item}</th>
                                    )
                                })}
                            </tr>
                            </thead>
                            <tbody>
                            {output.map((row, index2) => {
                                return (
                                    <tr>
                                        {headers.map((item) => {
                                            let val = "";
                                            if (typeof row[item] !== "undefined") {
                                                val = row[item];
                                            }
                                            return (
                                                <td>{val}</td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )

    }

    render() {


        return (
            <PageContentWallpaper {...this.props} >
                <div>
                    <div className="row container">
                        <div className="col-xs-12">
                            <h2>
                                Data Capture Database
                                <span className={"pull-right"}
                                      style={{color: "red"}}
                                >Total Records: <strong>{this.state.Data.length}</strong></span>
                            </h2>
                        </div>
                    </div>

                    <div className="row container">
                        <div className="col-xs-12">
                            {this.renderData()}
                        </div>
                    </div>
                </div>
            </PageContentWallpaper>
        )
    }


}