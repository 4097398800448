import React from 'react';
import {
    SaveLoginInfo,
    SetNavigateModule,
    SetNavigateMenu
} from "../../reducers/action/action-global";
import navigations2 from "../../reducers/reducer/reducer-navigation2";
import addingModel from "../../reducers/reducer/reducer-adding-model";
import info from "../../reducers/reducer/reducer-selection-info";




const mapDispatchToProps = (dispatch) => {
    return {
        dispatch: dispatch,
        SaveLoginInfo: (data) => dispatch(SaveLoginInfo(data)),
        SetNavigateModule:(data)=>dispatch(SetNavigateModule(data)),
        SetNavigateMenu:(data)=>dispatch(SetNavigateMenu(data)),
    }
}


const mapStateToProps = state => ({
    login: state.login,
    menus: state.menus,
    navigations:state.navigations,
    addingModel:state.addingModel,
    navigations2:state.navigations2,
    info:state.info,
});
export { mapDispatchToProps,mapStateToProps};